import React, { useRef } from 'react';
import styled from 'styled-components';
import { useClickOutside } from '@/hooks';
import { ic_close_circle_grey } from '@/assets/components';

const Container = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({zIndex}) => zIndex};
  flex-direction: column;


  .modalInner {
    width: ${({width}) => `${width}px`};
    min-height: ${({height}) => `${height}px`};
    box-shadow: -0.5px 7.5px 19px 0 rgba(0, 0, 0, 0.28);

    max-width: 90vw;
    max-height: 80vh;
    
    overflow-y: auto;


    position: relative;
    background-color: #fff;
    border-radius: 20px;
    padding: 16px 24px;
  }

  .closeBtn {
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

function Modal({ children, onClose, isOpen, width=900, height=700, zIndex=9999999991, ...rest }) {
  const ref = useRef();

  useClickOutside(ref, (e) => {
    onClose();
    e.preventDefault();
    e.stopPropagation();
  });

  return (
    <>
      {isOpen && (
        <Container width={width} height={height} zIndex={zIndex}>
          <div className='modalInner' ref={ref} {...rest}>
            <>{children}</>
          </div>
        </Container>
      )}
    </>
  );
}

export default Modal;
