import Loading from '@/components/Loading';
import { useHideLayout } from '@/hooks';
import axiosInstance from '@/lib/axios';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 99vh;
`;


export default function Page () {
  const hasFetched = useRef(false); // 요청 실행 여부를 추적하는 useRef
  
  useHideLayout('header', true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userId');
    const email = params.get('email');


    if (userId && email) {
      // code를 백엔드로 전달하여 인증 처리
      console.log('Received code:', userId);
      console.log('Received state:', email);

      // Oauth 부분 Back와 연동
      // /oauth 로 통신하면 될 것 같음.
      if (!hasFetched.current) {
        // 토큰을 요청하는 함수 호출
        fetchUserInfo(email, userId);
        hasFetched.current = true; // 요청이 실행되었음을 표시
      }
    } else {
      console.error('No code or state found');
    }
  }, []);

  const fetchUserInfo = async (email, userId) => {
    try {
      const response = await axiosInstance.post('/userauth/oauth', {
        email: email,
        socialId: userId,
        loginType: "APPLE",
        socialToken: "socialToken"
      });

      // response status 확인
      if(response.status === 201) {
        const result = await userSocialSignin(response.data.accessToken)
        if(result) window.location.href = '/';
        else alert('소셜로그인에 실패했습니다.');
      }
      else if(response.status === 203) {
        localStorage.setItem('user_data', JSON.stringify({ email: email }))
        localStorage.setItem('user_access_token', response.data.accessToken)

        window.location.href = '/social/info';
      }
    } catch (error) {

      if (error.response) {
        // 서버가 응답을 반환한 경우 (에러 응답)
        const statusCode = error.response.status;

        if (statusCode === 409) alert('소셜 계정이 사용중인 이메일입니다.'); 
      }
      else alert('로그인에 실패했습니다.');
      
      console.error('Error fetching user info:', error);
      window.location.href = '/login';
    }
  };

  return (
    <Container className='flex-center'>
      <Loading/>
    </Container>
  )
}