import { axiosAuth } from "@/lib/axios"

export const fetchCreateLike = async (novel_id, novel_uuid) => {
  try {
    await axiosAuth.post('/like', {
      novel: novel_id,
      novel_uuid
    })
  } catch (err) {
  }
}


export const fetchDeleteLike = async (novel_id, novel_uuid) => {
  try {
    await axiosAuth.post('/like/delete', {
      novel: novel_id,
      novel_uuid
    })
  } catch (err) {
  }
}


export const fetchHasCheckLike = async (novel_uuid) => {
  try {
    const { data } = await axiosAuth.get(`/like/check/${novel_uuid}`)
    return data;
  } catch (err) {
  }
}