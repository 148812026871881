import { fetchNewStalierEpisodeData, fetchStalierEpisodeWriterDetailData, fetchStalierWriterDetailData, fetchUpdateStalierEpisodeData } from '@/api/stalier';
import { Button, LabelInput, Textarea } from '@/components';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 80px;

`


export default function Page () {
  useHideLayout('header', true);

  const { uid } = useParams();
  const location = useLocation();
  const { episode_uuid } = location.state;

  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const [isLoading, setIsLoading] = useState(false);

  const [detail, setDetail] = useState(null);

  const navigate = useNavigate();

  useEffect(() => { 
    const getStailerOwnData = async () => {
      const result = await fetchStalierEpisodeWriterDetailData(episode_uuid);
      
      console.log(result);
      setDetail(result);

      setTitle(result.title);
      setContent(result.content);
    } 

    if(episode_uuid) getStailerOwnData();
  }, []);



  async function submit() {
    if(detail) {
      try {
        await fetchUpdateStalierEpisodeData({
          title,
          content,
          stalier_episode_uuid: episode_uuid
        });
  
        alert('회차가 수정되었습니다.');
        navigate(-1);
      } catch {
        alert('회차 수정에 실패했습니다.')
      }
    }
    else alert('작품 상세정보 불러오기에 실패했습니다.')
  }

  return (
    <Container>
      <TitleHeader title="나의 스텔리에"/>
  
      <div className='px-20 w-full'>
        <LabelInput required label={"제목"} value={title} onChange={e => setTitle(e.target.value)}/>

        <div className='bold font-18 mt-32 mb-10'>
          내용 <span className='c-red'>*</span>
        </div>
        <Textarea value={content} onChange={e => setContent(e.target.value)}/>
        
        <div className='mt-48'/>
        <Button width={"100%"} fontSize={20} isLoading={isLoading} onClick={submit}>수정하기</Button>
      </div>


    </Container>
  )
}