import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput } from '..'
import { getTheme } from '@/styles/theme'
import { useSignup } from '@/stores/signup';
import { useEffect, useRef, useState } from 'react';

import { auth } from '@/api/firebase';
import { RecaptchaVerifier } from "firebase/auth";
import { signInWithPhoneNumber } from "firebase/auth";
import { fetchPhoneDuplicateCheck } from '@/api/user';
import LoadingCover from '../Loading/cover';
import { validatePhoneNumber } from '@/utils/validate';

const Container = styled.div`

`

const TIMER_INIT = 180

export default function Step2() {
  const { signupData, handleChange, incrementStep } = useSignup();
  const [hasSendMessage, setHasSendMessage] = useState(false);
  const [validateNumber, setValidateNumber] = useState('');
  const [loading, setLoading] = useState(false);

  const [timer, setTimer] = useState(TIMER_INIT); // 3분 타이머 (초 단위)

  const recaptchaContainerRef = useRef(null);

  const getPhoneNumberFromUserInput = (num) => {
    if (num.startsWith('0')) {
      num = num.substring(1); // 첫 번째 '0' 제거
    }
    return `+82${num}`; // 국제 전화번호 형식으로 변환
  };

  // 인증하기
  function submit() {
    if (validateNumber !== '') {
      setLoading(true);
      window.confirmationResult
        .confirm(validateNumber)
        .then(async (res) => {
          alert("인증되었습니다. ");
          setLoading(false);
          handleChange('phone_confirm', true)
          incrementStep();
        })
        .catch((err) => {
          alert("인증에 실패했습니다.");
          setLoading(false);
        });
    }
    else alert('인증번호 입력 필요');
  }


  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      resetRecaptcha();
    }
  }


  async function sendMessage() {
    if (signupData.phone !== '') {
      if(!validatePhoneNumber(signupData.phone)) return alert('전화번호 형식이 올바르지 않습니다.')


      const duplicate_check = await phoneCheckSubmit();
      if(!duplicate_check) return;
      else {
        setLoading(true);
  
        auth.languageCode = 'ko'; // 한국어로 설정
        
        const phoneNumber = getPhoneNumberFromUserInput(signupData.phone); // 위에서 받아온 번호
  
        onCaptchVerify();
  
        const appVerifier = window.recaptchaVerifier;
  
        signInWithPhoneNumber(auth, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setLoading(false);
            alert("인증번호가 발송되었습니다!");
            setHasSendMessage(true);
          })
          .catch((error) => {
            alert("인증번호 발송에 실패했습니다.")
            console.log(error);
            setLoading(false);
          });
      }
    } else {
      alert('전화번호 입력 필요');
    }
  }

  
  // 리캡차 초기화 함수
  function resetRecaptcha() {
    // 기존 리캡차 요소 제거
    if (window.recaptchaVerifier) {
      window.recaptchaVerifier.clear(); // 리캡차 인스턴스 클리어
      window.recaptchaVerifier = null; // 인스턴스 초기화
    }




    if (recaptchaContainerRef.current) {
      recaptchaContainerRef.current.innerHTML = '';
      const newRecaptchaContainer = document.createElement('div');
      newRecaptchaContainer.id = 'recaptcha-container';
      recaptchaContainerRef.current.appendChild(newRecaptchaContainer);
      // 새로운 리캡차 인스턴스 생성
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            // sendMessage();
          },
          "expired-callback": () => {},
        }
      );
    }
  }

  // 타이머 관리
  useEffect(() => {
    if (hasSendMessage && timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(countdown);
    }

    if (hasSendMessage &&timer === 0) {
      // 타이머가 만료되었을 때
      alert('인증 시간이 초과되었습니다. 다시 시도해주세요.');
      resetRecaptcha(); // 리캡차 초기화
      setHasSendMessage(false); // 인증 상태 초기화
      setValidateNumber(''); // 인증번호 초기화
      setTimer(TIMER_INIT);
    }
  }, [hasSendMessage, timer]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };



  async function phoneCheckSubmit() {
    const result = await fetchPhoneDuplicateCheck(signupData.phone);
    if(!result) alert('사용중인 전화번호 입니다.');
    return result;
  }

  return (
    <Container>
      {loading && <LoadingCover/>}

      <div className='black font-30 c-primary100'>회원가입</div>
      <div className='bold font-24 mt-4 mb-72'>스텔라가 처음이신가요?</div>

      <LabelInput 
        placeholder={"전화번호 ( - 없이 )"} 
        readOnly={signupData.phone_confirm} 
        value={signupData.phone} 
        onChange={e => {
          const { value } = e.target;
          if (/^\d{0,11}$/.test(value)) { // 숫자만 허용, 최대 4자리
            setHasSendMessage(false);
            handleChange('phone', e.target.value);
            resetRecaptcha(); // 전화번호가 변경될 때 리캡차 초기화
          }
        }} 
        label={"전화번호"}
      />
      
      <div className='mt-24'/>
      
      {!signupData.phone_confirm ? <>
        {hasSendMessage && <LabelInput value={validateNumber} onChange={e => setValidateNumber(e.target.value)} label={"인증번호"}/>}
        {/* [TODO] 남은 시간 표기 */}
        {hasSendMessage && <div className='timer w-full text-right c-grey100 font-14 medium'>남은 시간: {formatTime(timer)}</div>} {/* 남은 시간 표기 */}
        
        <div className='mt-48'/>

        <div ref={recaptchaContainerRef}>
          <div id='recaptcha-container'></div>
        </div>
        {!hasSendMessage && <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={sendMessage}>인증번호 보내기</Button>}
        {hasSendMessage && <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={submit}>인증하기</Button>}
      </>
      :
        <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={incrementStep}>다음으로</Button>
      }
      {/* <div onClick={incrementStep}>임시</div> */}
    </Container>
  )
}