import { ic_home } from "@/assets";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  z-index: 99;
  top: 0;
  background-color: #fff;
  height: 60px;
  position: fixed;
  border-bottom: 1px solid #e7e6e7;

  .ic_home {
    width: 24px;
  }
`

export default function StalierEpisodeHeader ({stalier_uuid, stalier_title, episode_title}) {
  return (
    <Container className='w-full flex-center'>
      <div className='w-full h-full flex-center'>
        <div className='w-full px-14 flex align-center'>
          <Link to={`/stalier/detail/${stalier_uuid}`} className='mr-8'>
            <img className='ic_home' src={ic_home}/>
          </Link>

          <div className='ellipsis-1'>
            <span className='bold font-14'>{stalier_title} - </span>
            <span className='medium font-12'>{episode_title}</span>
          </div>
        </div>
      </div>
    </Container>
  )
}