import axios, { axiosAuth } from "@/lib/axios";
import { generateRandomString } from "@/utils";

export const fetchSignupUser = async (signupData) => {
  try {
    let signup_data = {...signupData};
    
    let rndString = generateRandomString(9);


    signup_data.nickname = "유저_" + rndString;

    // 데이터 형 변환
    signup_data.agreedToPrivacyPolicy = [signupData.agreedToPrivacyPolicy];
    signup_data.agreedToServicePolicy = [signupData.agreedToServicePolicy];
    

    await axios.post('/userauth/register', signup_data);
    alert('가입 성공');
    return window.location.replace('/signupcomplete');

  } catch (e) {
    console.log(e);
    return alert('가입 실패');
  }
}



export const fetchUpdateSignupUser = async (signupData) => {
  try {
    let signup_data = {...signupData};
    
    let rndString = generateRandomString(9);


    signup_data.nickname = "유저_" + rndString;


    // 데이터 형 변환
    signup_data.agreedToPrivacyPolicy = [signupData.agreedToPrivacyPolicy];
    signup_data.agreedToServicePolicy = [signupData.agreedToServicePolicy];

    await axiosAuth.post('/userauth/oauth/update', signup_data);
    alert('가입 성공');
    return window.location.replace('/signupcomplete');

  } catch (e) {
    console.log(e);
    return alert('가입 실패');
  }
}


 
export const updateUserNickname = async (nickname) => {
  try {
    await axiosAuth.put('/user/profile/nickname', { name: nickname });
    alert('닉네임 변경 성공');
  } catch (e) {
    console.log(e);
    return alert('중복된 닉네임입니다.');
  }
}




export const updateUserProfileImage = async (profileImage) => {
  try {
    await axiosAuth.put('/user/profile/profileImage', { profileImage });
    alert('프로필 이미지 변경 성공');
  } catch (e) {
    console.log(e);
    return alert('프로필 이미지 변경 실패');
  }
}


// 패스워드 변경
export const updateUserPassword = async (currentPassword, password) => {
  try {
    await axiosAuth.put('/user/password', { currentPassword, password });
    
    alert('비밀번호 변경 성공');
  } catch (e) {
    console.log(e);
    return alert('비밀번호 변경 실패');
  }
}


export const fetchEmailDuplicateCheck = async (email) => {
  const { data } = await axios.post('/user/duplicate/check', { email });
  return data;
}

export const fetchPhoneDuplicateCheck = async (phone) => {
  const { data } = await axios.post('/user/duplicate/check/phone', { phone });
  return data;
}


export const fetchFakeRefresh = async (refreshToken) => {
  const { data } = await axios.post('/userauth/fake/refresh', { refreshToken });
  return data;
}

export const fetchUserLogout = async () => {
  const { data } = await axios.post('/userauth/logout', { }, {
    withCredentials: true
  });
  return data;
}