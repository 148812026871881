export const OPENSOURCE_WEB = [
  {
    "name": "@ampproject/remapping",
    "license": "Apache-2.0",
    "uses": "변환을 통해 순차적인 소스맵을 다시 매핑하여 원본 소스 코드로 연결",
    "copyright": "Copyright 2019 The AMP HTML Authors"
  },
  {
    "name": "@babel/code-frame",
    "license": "MIT",
    "uses": "오류를 생성하고 특정 코드 프레임을 하이라이트하여 디버깅을 용이하게 함",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/compat-data",
    "license": "MIT",
    "uses": "Babel을 위한 브라우저 호환성 데이터",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/core",
    "license": "MIT",
    "uses": "JavaScript 소스 코드를 추상 구문 트리(AST)로 변환하여 조작 및 코드 생성에 사용",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/generator",
    "license": "MIT",
    "uses": "추상 구문 트리(AST)를 JavaScript 코드의 문자열 표현으로 변환",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-annotate-as-pure",
    "license": "MIT",
    "uses": "Babel 변환에서 함수 최적화를 위해 순수 함수로 표시",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-compilation-targets",
    "license": "MIT",
    "uses": "브라우저 지원 데이터를 기반으로 Babel 컴파일 대상 결정 지원",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-environment-visitor",
    "license": "MIT",
    "uses": "Babel 환경 방문자 처리에 도움을 주는 도우미",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-function-name",
    "license": "MIT",
    "uses": "Babel 변환 중 함수 이름을 추론하는 데 도움",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-hoist-variables",
    "license": "MIT",
    "uses": "Babel 변환을 위한 변수 범위 최적화를 위해 변수 끌어올리기",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-module-imports",
    "license": "MIT",
    "uses": "Babel 변환에서 모듈을 가져오도록 삽입하는 도우미",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-module-transforms",
    "license": "MIT",
    "uses": "Babel 변환 중 ES 모듈을 변환하는 데 도움을 주는 도우미",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-plugin-utils",
    "license": "MIT",
    "uses": "Babel 플러그인을 관리하기 위한 유틸리티",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-simple-access",
    "license": "MIT",
    "uses": "Babel 변환 중 객체 속성에 대한 접근을 간소화",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-split-export-declaration",
    "license": "MIT",
    "uses": "Babel 변환에서 export 선언을 분리하는 도우미",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-string-parser",
    "license": "MIT",
    "uses": "Babel 변환에서 문자열을 파싱",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-validator-identifier",
    "license": "MIT",
    "uses": "Babel 변환 중 식별자 이름을 검증",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helper-validator-option",
    "license": "MIT",
    "uses": "Babel 플러그인 및 프리셋에 전달된 옵션을 검증",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/helpers",
    "license": "MIT",
    "uses": "Babel 변환에서 사용되는 도우미 함수 모음",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/highlight",
    "license": "MIT",
    "uses": "Babel의 코드 프레임 오류에 대한 구문 하이라이팅",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/parser",
    "license": "MIT",
    "uses": "JavaScript 코드를 추상 구문 트리(AST)로 변환하는 Babel의 파서",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/plugin-transform-react-jsx-self",
    "license": "MIT",
    "uses": "JSX 요소에 __self 속성을 추가하는 Babel 플러그인",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/plugin-transform-react-jsx-source",
    "license": "MIT",
    "uses": "JSX 요소에 __source 속성을 추가하는 Babel 플러그인",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/template",
    "license": "MIT",
    "uses": "Babel용 추상 구문 트리(AST)를 생성하는 간단한 방법",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/traverse",
    "license": "MIT",
    "uses": "Babel 변환을 위해 추상 구문 트리(AST)를 순회하고 관리",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@babel/types",
    "license": "MIT",
    "uses": "Babel 변환에서 AST 노드를 생성하고 검증하는 유틸리티",
    "copyright": "Copyright Babel Project"
  },
  {
    "name": "@emotion/is-prop-valid",
    "license": "MIT",
    "uses": "React 컴포넌트에서 허용되는 HTML 속성을 검증하는 데 도움",
    "copyright": "Copyright Emotion"
  },
  {
    "name": "@emotion/memoize",
    "license": "MIT",
    "uses": "결과를 캐시하여 성능을 최적화하기 위해 함수를 메모이제이션",
    "copyright": "Copyright Emotion"
  },
  {
    "name": "@emotion/stylis",
    "license": "MIT",
    "uses": "Emotion을 위한 빠르고 가벼운 CSS 전처리기",
    "copyright": "Copyright Emotion"
  },
  {
    "name": "@emotion/unitless",
    "license": "MIT",
    "uses": "Emotion에서 사용할 수 있는 단위가 없는 CSS 속성 제공",
    "copyright": "Copyright Emotion"
  },
  {
    "name": "@esbuild/android-arm64",
    "license": "MIT",
    "uses": "Android ARM64 아키텍처를 위한 Esbuild 바이너리로, 빠른 JavaScript 번들러 및 압축 도구",
    "copyright": "Copyright Evan Wallace"
  },
  {
    "name": "@esbuild/darwin-x64",
    "license": "MIT",
    "uses": "macOS x64 아키텍처를 위한 Esbuild 바이너리로, 빠른 JavaScript 번들러 및 압축 도구",
    "copyright": "Copyright Evan Wallace"
  },
  {
    "name": "@firebase/app",
    "license": "Apache-2.0",
    "uses": "Firebase 앱을 초기화하고 서비스를 관리하기 위한 Firebase 핵심 라이브러리",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "@grpc/grpc-js",
    "license": "Apache-2.0",
    "uses": "클라이언트-서버 통신을 위한 현대적인 RPC 프레임워크인 gRPC의 JavaScript 구현",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "@protobufjs/eventemitter",
    "license": "BSD-3-Clause",
    "uses": "Protobuf.js용 이벤트 발행자 구현",
    "copyright": "Copyright 2016-2022 Daniel Wirtz"
  },
  {
    "name": "@react-oauth/google",
    "license": "MIT",
    "uses": "애플리케이션에 Google OAuth를 통합하기 위한 React 훅",
    "copyright": "Copyright React OAuth Contributors"
  },
  {
    "name": "@vitejs/plugin-react",
    "license": "MIT",
    "uses": "개발 중 React JSX와 빠른 새로고침을 지원하는 Vite 플러그인",
    "copyright": "Copyright Vite Contributors"
  },
  {
    "name": "chalk",
    "license": "MIT",
    "uses": "터미널에서 텍스트에 색상과 스타일을 추가하는 유틸리티",
    "copyright": "Copyright Chalk"
  },
  {
    "name": "core-js",
    "license": "MIT",
    "uses": "Promise, Symbol 등과 같은 ECMAScript 기능에 대한 폴리필 제공",
    "copyright": "Copyright Denis Pushkarev"
  },
  {
    "name": "dayjs",
    "license": "MIT",
    "uses": "날짜와 시간을 조작하기 위한 가벼운 JavaScript 라이브러리",
    "copyright": "Copyright Day.js"
  },
  {
    "name": "dotenv",
    "license": "BSD-2-Clause",
    "uses": ".env 파일에서 환경 변수를 로드하여 `process.env`에 저장",
    "copyright": "Copyright Dotenv Project"
  },
  {
    "name": "firebase",
    "license": "Apache-2.0",
    "uses": "Firebase 서비스와 상호작용하기 위한 공식 Firebase JavaScript 라이브러리",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "d@^1.0.1",
    "license": "MIT",
    "uses": "객체의 속성 설명자를 제공",
    "copyright": "Copyright d contributors"
  },
  {
    "name": "date-fns@^3.6.0",
    "license": "MIT",
    "uses": "날짜를 조작하고 포맷하는 현대적인 자바스크립트 날짜 유틸리티 라이브러리",
    "copyright": "Copyright date-fns contributors"
  },
  {
    "name": "debug@^4.3.4",
    "license": "MIT",
    "uses": "더 나은 로깅과 성능 분석을 위한 자바스크립트 디버깅 유틸리티",
    "copyright": "Copyright debug contributors"
  },
  {
    "name": "define-data-property@^1.1.1",
    "license": "MIT",
    "uses": "객체에 비열거형 데이터 속성을 정의하여 객체 속성을 쉽게 생성할 수 있게 함",
    "copyright": "Copyright define-data-property contributors"
  },
  {
    "name": "delayed-stream@~1.0.0",
    "license": "MIT",
    "uses": "읽기 가능한 스트림에서 이벤트를 버퍼링하고 소비될 때까지 보류하는 기능, 비동기 작업에 유용",
    "copyright": "Copyright delayed-stream contributors"
  },
  {
    "name": "draft-js@^0.11.7",
    "license": "MIT",
    "uses": "React 애플리케이션용 리치 텍스트 에디터 프레임워크로 구조화된 콘텐츠와 리치 텍스트 편집 기능 제공",
    "copyright": "Copyright draft-js contributors"
  },
  {
    "name": "draftjs-utils@^0.10.2",
    "license": "MIT",
    "uses": "Draft.js를 위한 유틸리티 함수 제공, 텍스트 변환 및 상태 관리 지원",
    "copyright": "Copyright draftjs-utils contributors"
  },
  {
    "name": "electron-to-chromium@^1.4.284",
    "license": "ISC",
    "uses": "Electron 버전을 해당하는 Chromium 버전으로 매핑하여 호환성을 보장하고 Electron 앱에서 브라우저 기능 관리에 유용",
    "copyright": "Copyright electron-to-chromium contributors"
  },
  {
    "name": "emoji-regex@^8.0.0",
    "license": "MIT",
    "uses": "문자열에서 이모지를 매칭하기 위한 정규식, 텍스트 분석 및 입력 유효성 검사에 유용",
    "copyright": "Copyright emoji-regex contributors"
  },
  {
    "name": "epubjs@^0.3.93",
    "license": "BSD-2-Clause",
    "uses": "브라우저에서 ePub 파일을 렌더링하는 라이브러리로 다양한 포맷을 지원하여 전자책 읽기 기능 제공",
    "copyright": "Copyright epubjs contributors"
  },
  {
    "name": "es5-ext@^0.10.64",
    "license": "MIT",
    "uses": "ECMAScript 5 확장을 제공하여 표준 자바스크립트 객체에 유용한 메서드와 함수 추가",
    "copyright": "Copyright es5-ext contributors"
  },
  {
    "name": "es6-iterator@^2.0.3",
    "license": "MIT",
    "uses": "ES6 규격을 준수하는 이터레이터 구현으로 반복 가능한 객체와 구조를 생성하는 데 유용",
    "copyright": "Copyright es6-iterator contributors"
  },
  {
    "name": "es6-symbol@^3.1.4",
    "license": "MIT",
    "uses": "ES6 심볼의 폴리필을 제공하여 객체 키로 심볼을 사용하거나 고급 기능을 사용할 수 있음",
    "copyright": "Copyright es6-symbol contributors"
  },
  {
    "name": "esbuild@^0.16.15",
    "license": "MIT",
    "uses": "현대 웹 개발을 위한 매우 빠른 자바스크립트 번들러 및 압축 도구",
    "copyright": "Copyright esbuild contributors"
  },
  {
    "name": "escalade@^3.1.1",
    "license": "MIT",
    "uses": "파일 시스템 탐색을 돕는 경량 모듈로, 모듈 및 파일 경로를 해결하는 데 유용",
    "copyright": "Copyright escalade contributors"
  },
  {
    "name": "escape-string-regexp@^1.0.5",
    "license": "MIT",
    "uses": "문자열의 특수 문자를 이스케이프 처리하여 정규식에서 안전하게 사용할 수 있도록 함",
    "copyright": "Copyright escape-string-regexp contributors"
  },
  {
    "name": "esniff@^2.0.1",
    "license": "MIT",
    "uses": "자바스크립트 코드에서 ES6 모듈 구문을 감지하여 호환성을 체크하는 데 유용",
    "copyright": "Copyright esniff contributors"
  },
  {
    "name": "event-emitter@^0.3.5",
    "license": "MIT",
    "uses": "자바스크립트에서 이벤트를 발생시키고 리스닝할 수 있는 이벤트 방출기 패턴의 간단한 구현",
    "copyright": "Copyright event-emitter contributors"
  },
  {
    "name": "events@1.1.1",
    "license": "MIT",
    "uses": "자바스크립트 애플리케이션에서 이벤트 기반 프로그래밍 기능을 제공하는 Node.js 이벤트 모듈",
    "copyright": "Copyright events contributors"
  },
  {
    "name": "ext@^1.7.0",
    "license": "MIT",
    "uses": "추가 메서드와 유틸리티로 자바스크립트 기능을 확장함",
    "copyright": "Copyright ext contributors"
  },
  {
    "name": "faye-websocket@0.11.4",
    "license": "MIT",
    "uses": "Node.js용 WebSocket 클라이언트 및 서버 구현으로 실시간 웹소켓 통신 제공",
    "copyright": "Copyright faye-websocket contributors"
  },
  {
    "name": "fbjs-css-vars@^1.0.0",
    "license": "MIT",
    "uses": "자바스크립트 애플리케이션에서 CSS 변수를 관리하기 위한 Facebook의 자바스크립트 유틸리티",
    "copyright": "Copyright fbjs-css-vars contributors"
  },
  {
    "name": "fbjs@^2.0.0",
    "license": "MIT",
    "uses": "Facebook의 React 프레임워크 및 기타 도구를 위한 유틸리티 라이브러리 모음",
    "copyright": "Copyright fbjs contributors"
  },
  {
    "name": "firebase@^10.13.1",
    "license": "Apache-2.0",
    "uses": "자바스크립트용 Firebase SDK로 Firebase 서비스(인증, Firestore, 호스팅 등) 통합 가능",
    "copyright": "Copyright Google"
  },
  {
    "name": "follow-redirects@^1.15.2",
    "license": "MIT",
    "uses": "HTTP 및 HTTPS 요청에서 리다이렉트를 따름, 자동으로 리다이렉트하는 HTTP 요청 작성에 유용",
    "copyright": "Copyright follow-redirects contributors"
  },
  {
    "name": "for-each@^0.3.3",
    "license": "MIT",
    "uses": "비열거형 속성을 지원하는 객체와 배열을 순회하기 위한 간단한 유틸리티",
    "copyright": "Copyright for-each contributors"
  },
  {
    "name": "form-data@^4.0.0",
    "license": "MIT",
    "uses": "HTTP 요청에서 폼 데이터를 처리하여 Node.js 애플리케이션에서 폼 데이터를 쉽게 전송할 수 있게 함",
    "copyright": "Copyright form-data contributors"
  },
  {
    "name": "fsevents@~2.3.2",
    "license": "MIT",
    "uses": "디렉토리 및 파일 변경과 같은 파일 시스템 이벤트에 대한 네이티브 액세스 제공",
    "copyright": "Copyright fsevents contributors"
  },
  {
    "name": "function-bind@^1.1.2",
    "license": "MIT",
    "uses": "자바스크립트의 function.bind() 메서드를 위한 폴리필로, 오래된 자바스크립트 환경과의 호환성을 제공",
    "copyright": "Copyright function-bind contributors"
  },
  {
    "name": "gensync@^1.0.0-beta.2",
    "license": "MIT",
    "uses": "동기 및 비동기 버전의 함수를 생성하여 비동기 작업을 보다 잘 제어할 수 있도록 함",
    "copyright": "Copyright gensync contributors"
  },
  {
    "name": "get-intrinsic@^1.2.2",
    "license": "MIT",
    "uses": "전역 객체 및 내장 함수와 같은 자바스크립트 내재적 객체에 접근할 수 있게 함",
    "copyright": "Copyright get-intrinsic contributors"
  },
  {
    "name": "globals@^11.12.0",
    "license": "MIT",
    "uses": "다양한 자바스크립트 환경을 위한 전역 변수를 정의하여 환경별 변수를 관리하는 데 유용",
    "copyright": "Copyright globals contributors"
  },
  {
    "name": "gopd@^1.0.1",
    "license": "MIT",
    "uses": "고급 객체 조작에 유용한 자체 속성 설명자를 가져오는 유틸리티",
    "copyright": "Copyright gopd contributors"
  },
  {
    "name": "has-flag@^3.0.0",
    "license": "MIT",
    "uses": "특정 명령줄 플래그가 설정되었는지 확인하여 애플리케이션 플래그 및 옵션을 관리하는 데 유용",
    "copyright": "Copyright has-flag contributors"
  }
]