import { social_google, social_naver } from '@/assets'
import styled from 'styled-components'


const Container = styled.div`
  width: 250px;
  max-width: 80vw;

  border: 1px solid #000;
  border-radius: 80px;
  height: 50px;
  
  .social_icon {
    width: 24px;
    height: 24px;
    left: 16px;
  }
`


export default function NaverLoginButton () {
  const CLIENT_ID = import.meta.env.VITE_NAVER_CLIENT_ID
  const NAVER_REDIRECT_URI = import.meta.env.VITE_NAVER_REDIRECT_URI

  const REDIRECT_URI = encodeURIComponent(NAVER_REDIRECT_URI);

  const submit = () => {
    const state = Math.random().toString(36).substring(2, 15); // CSRF 방지를 위한 state 값
    const naverAuthUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=${state}`;

    // http://localhost:5173/oauth/result/naver?code=TLNOiKcuYcwMiycHiY&state=w74gr31dyyc

    window.location.href = naverAuthUrl; // 네이버 로그인 페이지로 리디렉션
  };


  return (
    // <Container className='flex-center relative pointer' onClick={submit}>
    //   <img className='social_icon absolute' src={social_naver}/>
    //   <div className='pl-24 medium font-18'>네이버 계정으로 로그인</div>
    // </Container>
    
    <CircleContainer onClick={submit}>
      <img className='social_icon pointer' src={social_naver}/>

    </CircleContainer>
  )
}



const CircleContainer = styled.div`
  width: 36px;
  height: 36px;

  .social_icon {
    width: 36px;
    border-radius: 50%;
  }
`