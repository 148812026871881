import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import axios, { axiosAuth } from '@/lib/axios';
import { fetchUserLogout } from '@/api/user';

const userState = atom({
  key: 'User',
  default: null,
});



export const useUser = (location) => {
  const [user, setUser] = useRecoilState(userState);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()


  // 유저 데이터 새로고침
  useEffect(() => {
    if(location) getData();
  }, [location]);


  const getData = async () => {
    console.log(window.location.pathname);
    const { data } = await axiosAuth.post('/userauth/profile');
    if(!data?.email || !data?.name) {
      if(!window.location.pathname.includes('/social/info'))
        window.location.href = '/social/info'
    }
    else setUser(data);
  }


  const userLogout = async() => {
    try {
      const result = await fetchUserLogout();
      if(result) {
        setUser({});
        localStorage.removeItem('user_data')
        localStorage.removeItem('admin_data')
        localStorage.removeItem('user_access_token')
        localStorage.removeItem('user_refresh_token')
  
        return window.location.replace('/login');
      }
    } catch {
      // alert('로그아웃에 실패했습니다.')
    }
  }

  const userLocalSignin = async ({ id, password }) => {
    try {

      const deviceToken = localStorage.getItem('deviceToken');
      const result = await axios.post(`/userauth/login`, {
        email: id, password
      }, {
        withCredentials: true, // 쿠키 전송을 허용
      })
      
      console.log({result})
      

      localStorage.setItem('user_access_token', result.data.accessToken)

      if(result.data.status === 404) return false;
      
      const resultProfile = await axios.post('/userauth/profile', {}, {
        headers: { 'Authorization': `Bearer ${result.data.accessToken}` }
      })
      if(deviceToken) {
        await axiosAuth.post('/user/deviceToken', { deviceToken });
        localStorage.removeItem('deviceToken');
      }
      return true;
    } catch {
      return false
    }
  }


  const userSocialSignin = async (accessToken) => {
    try {
      const deviceToken = localStorage.getItem('deviceToken');

      localStorage.setItem('user_access_token', accessToken)
    
      const resultProfile = await axios.post('/userauth/profile', {}, {
        headers: { 'Authorization': `Bearer ${accessToken}` }
      })

      if(deviceToken) {
        await axiosAuth.post('/user/deviceToken', { deviceToken });
        localStorage.removeItem('deviceToken');
      }
      
      return true;
    } catch {
      return false
    }
  }

  const refreshUserData = async () => {
    const resultProfile = (await axiosAuth.post('/userauth/profile'));
    if(!resultProfile?.data?.email || !resultProfile?.data?.name) {
      if(!window.location.pathname.includes('/social/info'))
        window.location.href = '/social/info'
    }
    else {
      setUser(resultProfile.data);
      return resultProfile.data;
    }
  }


  const refreshUserDataWithAdultCheck = async () => {
    try {
      const resultProfile = (await axiosAuth.post('/userauth/profile'));
      if(!resultProfile?.data?.email || !resultProfile?.data?.name) {
        if(!window.location.pathname.includes('/social/info'))
          window.location.href = '/social/info'
      }
      else {
        setUser(resultProfile.data);
        return resultProfile.data;
      }
    } catch {
      
    }
  }

  return {
    user,
    setUser,
    isLoading,
    userLogout,
    userSocialSignin,

    refreshUserData,
    refreshUserDataWithAdultCheck,
    userLocalSignin, // 유저 로컬 로그인
  };
};
