import { fetchOwnInquiryOne } from '@/api/inquiry';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { formatDateYMD, formatDateYYMD } from '@/utils/format';
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  height: 100%;
  padding: 60px 0 0 0;
  display: flex;
  flex-direction: column;

  .border-item {
    border-top: 1px solid #e7e6e7;
  }
`



const InquiryContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const InquiryTitle = styled.div`
  border-bottom: 1px solid #e7e6e7;
  padding: 20px;
`


export default function Page () {
  const { uid } = useParams();
  const [detail, setDetail] = useState(null)

  useHideLayout('header', true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    const result = await fetchOwnInquiryOne(uid);

    if(!result) {
      alert('문의를 찾을 수 없습니다.');
      navigate(-1);
      return;
    }
    setDetail(result);
  }

  return (
    <Container>
      <TitleHeader title="나의 문의내역"/>
      
      <InquiryContainer>
        <InquiryTitle className='Inquiry-title'>
          <div className='font-20 bold mb-4'>{detail?.title}</div>
          <div className='font-14 c-grey100'>{formatDateYYMD(detail?.created_date)}</div>
        </InquiryTitle>

        <div className='p-16 pre-line'>
          {detail?.content}
        </div>

        
        {detail?.hasResponsed && 
          <div className='p-16 pre-line border-item mt-80'>
            {detail?.response_content}
          </div>
        }
      </InquiryContainer>

    </Container>
  )
}