import { axiosAuth } from "@/lib/axios";
import { useUser } from "@/stores";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function Page () {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    const { refreshUserData } = useUser();

    useEffect(() => {
        (async () => {
            try{
                const key = localStorage.getItem('key');
                const iv = localStorage.getItem('iv');
                const hmac_key = localStorage.getItem('hmac_key');
                const req_no = localStorage.getItem('req_no');

                console.log({key, iv, hmac_key, req_no});
                
                const enc_data = searchParams.get('enc_data');
                const integrity_value = searchParams.get('integrity_value');

                const res = await axiosAuth.post('/checkplus/success', {
                    enc_data,
                    integrity_value,
                    key,
                    iv,
                    hmac_key,
                    req_no
                });

                if(res.data.isAdult) {
                    await refreshUserData();
                    alert('성인인증이 완료되었습니다.');
                } else {
                    throw new Error('성인인증에 실패하였습니다.');
                }
            } catch (error) {
                console.error(error);
                alert('성인인증에 실패하였습니다.');
            } finally {
                localStorage.removeItem('key');
                localStorage.removeItem('iv');
                localStorage.removeItem('hmac_key');
                localStorage.removeItem('req_no');

                navigate('/');
            }
        })();
    }, [])

    return (
        <div>
            <h1>본인인증 결과</h1>
        </div>
    )
}

