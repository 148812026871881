import { social_google, social_kakao } from '@/assets'
import styled from 'styled-components'


const Container = styled.div`
  width: 250px;
  max-width: 80vw;

  border: 1px solid #000;
  border-radius: 80px;
  height: 50px;
  
  .social_icon {
    width: 24px;
    height: 24px;
    left: 16px;
  }
`


export default function KakaoLoginButton () {
  const REST_API_KEY = import.meta.env.VITE_KAKAO_REST_API_KEY
  const KAKAO_REDIRECT_URI = import.meta.env.VITE_KAKAO_REDIRECT_URI
  const REDIRECT_URI = encodeURIComponent(KAKAO_REDIRECT_URI);

  const submit = () => {
    const kakaoAuthUrl = `https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}`;
    window.location.href = kakaoAuthUrl; // 카카오 로그인 페이지로 리디렉션
  };


  return (
    // <Container className='flex-center relative pointer' onClick={submit}>
    //   <img className='social_icon absolute' src={social_kakao}/>
    //   <div className='pl-24 medium font-18'>카카오 계정으로 로그인</div>
    // </Container>

    <CircleContainer className='' onClick={submit}>
      <img className='social_icon pointer' src={social_kakao}/>

    </CircleContainer>
  )
}

const CircleContainer = styled.div`
  width: 36px;
  height: 36px;

  .social_icon {
    width: 36px;
    border-radius: 50%;
  }
`