import { formatDateYMD } from "@/utils/format";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import EpisodePaymentModal from "./EpisodePaymentModal";
import { useNovelData } from "@/stores/novelData";
import NovelRentBenefitModal from "./NovelRentBenefitModal";
import InsufficientBalanceModal from "./InsufficientBalanceModal";
import TimeFreeRentModal from "./TimeFreeRentModal";

import moment from 'moment';

const Container = styled.div`
  .c-878787 {
    color: #878787;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .c-929292 {
    color: #929292;
  }

  .episode-item {
    width: 100%; 
    border-bottom: 1px solid #e7e6e7;
    padding: 10px 20px;
  }
`

export function EpisodeList({ 
  isAudioMode,
  episodeList, 
  novelData, 
  openEpisode
}) {
  const [sort_type, set_sort_type] = useState(true);


  const navigate = useNavigate()


  function getTimeDifference(targetDateTime) {
    const now = moment();

    // 목표 시간을 moment 객체로 생성
    const target = moment(targetDateTime);
  
    // 목표 시간과 현재 시간의 차이를 계산
    const duration = moment.duration(target.diff(now));
  
    // 남은 시간을 시간과 분으로 추출
    const hours = duration.asHours(); // 전체 차이를 시간으로 계산
    const minutes = duration.minutes(); // 남은 분만 추출
  
    // 만료 여부 확인
    if (hours < 0 || (hours === 0 && minutes < 0)) {
      return "만료됨";
    }
  
    // 남은 시간과 분을 문자열로 포맷하여 반환
    return `${Math.floor(hours)}시간 ${minutes}분 남음`;
  }


  return (
    <Container>
      <div className='flex-center px-20 w-full py-10'>
        <div className='flex-1 font-12 flex align-center'>
          <div className="bold">총 {episodeList?.length}화</div>
        </div>
        <div className='font-12 flex-center medium'>
          <div className={!sort_type ? 'c-primary' : 'c-878787'} onClick={() => set_sort_type(false)}>회차순</div>
          <div className='mx-2 c-878787 font-10'>|</div>
          <div className={sort_type ? 'c-primary' : 'c-878787'}  onClick={() => set_sort_type(true)}>등록순</div>
        </div>
      </div>

      {(!novelData?.isBook && episodeList?.length > 0) && <div className='flex align-center px-20 w-full py-10'>
        <div className="flex" onClick={() => navigate(`/novel/permanent/buy/${novelData.uuid}`)}>
          <div className="font-12 bold">선택구매</div>
          <div className="font-12 bold mx-6">{'>'}</div>
          <div className="font-12">선택구매 혜택 보기</div> 
        </div>
      </div>}

      <div className='white-box'/>

      {
        [...episodeList]?.filter(item => {
          if(!isAudioMode) return item; // 오디오북 모드가 아닐 경우
          else if(item?.audioFile) return item; // 오디오북 모드 && 오디오북 파일 있을 경우
        })?.sort((a, b) => {
          if(!sort_type) {
            return b?.episode_index - a?.episode_index;
          } else {
            return a?.episode_index - b?.episode_index;
          }
        })?.map((item, idx) => (
          <div className='episode-item w-full flex align-center' key={`episode-${idx}`} onClick={() => openEpisode(item)} style={{
            backgroundColor: item.hasRead ? '#e8e8e8' : '#fff'
          }}>
            <div className="flex-1">
              <div className='medium mb-8 font-14 ellipsis-1 mb-2'>{item.title}</div>
              <div className='font-10 medium c-929292'>{formatDateYMD(item.updated_date)}</div>
            </div>


            <div className="text-right">
              <div className="semibold font-13">{item.salesStatus}</div>
              {item.salesStatus === "대여중" && 
                <div className="font-11 mt-2 medium c-grey100">{getTimeDifference(item.rentExpire)}</div>
              }
            </div>
          </div>
        ))
      }
    </Container>
  )
}