import { fetchFakeRefresh } from '@/api/user';
import Loading from '@/components/Loading';
import { useHideLayout } from '@/hooks';
import axios, { axiosAuth } from '@/lib/axios';
import { useUser } from '@/stores';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 99vh;
`;

export default function GoogleTokenLoginPage () {
  const [searchParams, setSearchParams] = useSearchParams();
  const { userLogout } = useUser();
  useEffect(() => {
    getUser();
  }, [])

  async function getUser() {
    const statusParam = searchParams.get('status');
    const accessParam = searchParams.get('access');
    const refreshParam = searchParams.get('refresh');
    const deviceToken = localStorage.getItem('deviceToken');

    console.log(accessParam, refreshParam)

    if(!accessParam || !refreshParam) {
      return alert('Wrong Value');
    }


    localStorage.setItem('user_access_token', accessParam)

    try {
      const resultFake = await fetchFakeRefresh(refreshParam);

      if(resultFake) {
        // 여기서 터짐.
        const resultProfile = (await axios.post('/userauth/profile', {}, {
          headers: { 'Authorization': `Bearer ${accessParam}` }
        }))

        
        if(deviceToken) {
          await axiosAuth.post('/user/deviceToken', { deviceToken });
          localStorage.removeItem('deviceToken');
        }
        
        
        localStorage.setItem('user_data', JSON.stringify({ email: resultProfile?.data?.email }))

        // if(statusParam == "203" || statusParam == 203)
        //   window.location.href = '/social/info';
        // else if(statusParam == "201" || statusParam == 201)
        //   window.location.href = '/';
      } else throw new Error('Invalid Refresh status');

    } catch (e) {
      // alert('로그인 데이터를 불러오는데 실패했습니다.');
      userLogout();
      localStorage.removeItem('user_data')
      localStorage.removeItem('admin_data')
      localStorage.removeItem('user_access_token')
      localStorage.removeItem('user_refresh_token')

      // return window.location.replace('/login');
    }
  }

  useHideLayout('header', true)

  return (
    <Container className='flex-center'>
      <Loading/>
    </Container>
  );
}