import { formatImageUri } from '@/utils/format';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import NeedAuthAdultModal from '../Auth/NeedAuthAdultModal';
import { useState } from 'react';
import { getTheme } from '@/styles/theme';
import { book_cover_sample, ic_adult, ic_odi } from '@/assets';


const Container = styled.div`

  .book_cover {
    border-radius: 12px;
    aspect-ratio: 80 / 108;
    object-fit: cover;
  }

  .adultMark {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 4px;
    right: 4px;
  }
`


export default function StalierItem ({ 
  book_cover, 
  ageGrade, 
  title, 
  isAudioBook, 
  uuid,
  isOwn=false,
  userAdultCheck=false,
}) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);



  return (
    <>
      <NeedAuthAdultModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />

      <Container className='w-full pointer relative' onClick={() => {
        if(isOwn) {
          navigate(`/stalier/own/detail/${uuid}`)
          return;
        }
        if(ageGrade === "19세 이용가") {
          if(userAdultCheck) setIsOpen(true);
          else navigate(`/stalier/detail/${uuid}`);
        }
        else navigate(`/stalier/detail/${uuid}`)
      }}>
        <div className='w-full'>
          <img src={book_cover ? formatImageUri(book_cover) : book_cover_sample} className='w-full book_cover'/>
        </div>

        {ageGrade === "19세 이용가" && 
          <img className='adultMark' src={ic_adult}/>
        }

        <div className='font-14 mt-6 medium ellipsis-1'>{title}</div>
        <div className='font-12 mt-4 exlight'>
          <span>웹소설</span>
          {isAudioBook && <span>, 스타오북</span>}
        </div>
      </Container>
    </>
  )
}