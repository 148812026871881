import { loading } from '@/assets';
import { useHideLayout } from '@/hooks';
import axiosInstance from '@/lib/axios';
import { useUser } from '@/stores';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 99vh;
`;


export default function GoogleLoginRedirectParam () {
  const hasFetched = useRef(false); // 요청 실행 여부를 추적하는 useRef

  const { userSocialSignin } = useUser();

  const { uid } = useParams();


  return (
    <Container className='flex-center'>
      {/* <img src={loading}/> */}
      <div className=''>{uid}</div>
    </Container>
  );
}