import { fetchNovelEpisodeData } from '@/api/novel';
import EpisodeHeader from '@/components/Episode/EpisodeHeader';
import { useHideLayout } from '@/hooks';
import { formatAudioUri, formatImageUri } from '@/utils/format';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 140px;

  .episode_book_cover {
    width: 64%;
    max-width: 200px;
    border-radius: 10px;
  }

  .episode_content {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-drag: none;
    line-height: 32px;
  }

  .highlighted {
    /* 하이라이트된 텍스트의 배경색 */
    background-color: yellow; 
  }
`


export default function Page () {
  const { uid } = useParams();
  const [episodeData, setEpisodeData] = useState(null);
  const navigate = useNavigate();

  const [content_text, set_content_text] = useState([]);

  useEffect(() => {
    async function getData() {
      const response = await fetchNovelEpisodeData(uid);
      setEpisodeData(response);

      const content = JSON.parse(response.content_text);
      
      console.log(content)
      set_content_text(content)
    }
    getData();
  }, [uid]);


  useHideLayout('header', true);


  const audioRef = useRef(null);
  
  const [highlightedIndex, setHighlightedIndex] = useState(-1);


  useEffect(() => {
    const audioElement = audioRef.current;
    if (!audioElement) return;

    const handleTimeUpdate = () => {
      const currentTime = audioElement.currentTime; // 현재 재생 시간 가져오기
      const highlightedIndex = getHighlightedIndex(currentTime); // 하이라이트 인덱스 계산
      console.log("Highlighted Index:", highlightedIndex); // 디버깅
      setHighlightedIndex(highlightedIndex)
    };

    audioElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
        audioElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [audioRef, content_text]); // 의존성 배열에 content_text 추가



  const getHighlightedIndex = (currentTime) => {
    for (let i = 0; i < content_text.length; i++) {
      const current = content_text[i];
      const next = content_text[i + 1];
      if (currentTime >= current.startAt && (!next || currentTime < next.startAt)) {
        return i;
      }
    }
    return -1; // 하이라이트 할 텍스트가 없는 경우
  };
  

  const handleTextClick = (startAt) => {
    const audio = audioRef.current;
    if (audio) {
      audio.currentTime = startAt;
      audio.play();  // 클릭 시 자동 재생을 시작하도록 설정
    }
  };

  
  return (
    <Container>
      <EpisodeHeader
        novel_uuid={episodeData?.novel_uuid}
        novel_title={episodeData?.novel?.title}
        episode_title={episodeData?.title}
      />

      
      <div className="w-full pt-32 pb-48 ">
        <div className='w-full flex-center'>
          <img className='episode_book_cover' src={formatImageUri(episodeData?.novel?.book_cover)}/>
        </div>



        {/* 소설 사운드 바 */}
        {episodeData && 
          <div className='w-full flex-center mt-24'>
            <audio ref={audioRef} controls src={formatAudioUri(episodeData?.audioFile)} preload="auto"/>
          </div>
        }
        
        <div className='text-center bold font-16' style={{ width: '80%', margin: '40px auto' }}>{episodeData?.title}</div>

        {/* 소설 컨텐츠 */}
        <div className='px-16 font-20 episode_content'>
          {content_text.map((item, idx) => (
            <div className={`pre-line ${highlightedIndex === idx ? 'highlighted' : ''}`} key={`line-${idx}`} onClick={() => handleTextClick(item.startAt)}>
              {item.text}
            </div>
          ))}
        </div>

        <div className='mb-60'/>



        {/* {episodeData?.next_uid && 
          <div onClick={() => {
            submit(episodeData?.next_uid)
            // window.location.href = `/episode/${episodeData?.next_uid}`
          }} className='floatElement flex flex-center w-full'>
            <img style={{ width: '14px '}} src={ic_scroll_down_black}/>
            <div className='ml-10 font-14 bold'>다음으로</div>
          </div>
        } */}
      </div>
    </Container>
  )
}