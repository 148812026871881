import { axiosAuth } from "@/lib/axios";

export const fetchOwnInquiryList = async () => {
  const { data } = await axiosAuth.get('/inquiry/list');
  return data;
}

export const fetchOwnInquiryOne = async (uid) => {
  try {
    const { data } = await axiosAuth.get(`/inquiry/detail/${uid}`);
    return data;
  } catch {
    return null;
  }
}


export const fetchNewInquiry = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/inquiry', {
      ...fetchData,
    });

    alert('문의가 등록되었습니다!');

    window.history.back();

    return data;
  } catch {
    alert('문의 등록에 실패했습니다.');
  }
}