import { fetchStalierGenreList } from '@/api/genre';
import { fetchStalierKeywordList } from '@/api/keyword';
import { fetchStalierOwnList } from '@/api/stalier';
import { book_cover_sample } from '@/assets';
import { Button, CheckBox, Dropdown, GridBox, LabelInput, Textarea } from '@/components';
import KeywordInput from '@/components/Keyword/KeywordInput';
import NovelItem from '@/components/NovelItem';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useStalierUpdateData } from '@/stores/stalierUpdate';
import { formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 60px;

  .no-data {
    width: 100%;
    height: calc(80vh - 60px);
  }

  .section_line {
    width: 100%;
    height: 2px;
    background-color: #000000;
  }

  .imageUpload {
    width: 155px;
    height: 246px;
    background-color: #fff;
    border-radius: 10px;
    
    .book_cover {
      width: 100%;
      height: 100%;
    }
  }
`


export default function Page () {
  useHideLayout('header', true);
  const { uid } = useParams();

  const { AgeGradeList, stalierUpdateData, handleChange, keywordSelect, submit, isLoading } = useStalierUpdateData(uid);
  

  const [genreList, setGenreList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);

  useEffect(() => {
    getGenreData();
  }, []);

  async function getGenreData() {
    const result = await fetchStalierGenreList();

    setGenreList(result.map((item) => {
      return {
        name: item.name,
        value: item._id
      }
    }));
  }


  return (
    <Container>

      <TitleHeader title="나의 스텔리에"/>
      
      <div className='px-20 w-full'>
        <LabelInput label={"제목"} value={stalierUpdateData.title} onChange={e => handleChange('title', e.target.value)}/>

        <>
          <div className='bold font-18 mt-32 mb-10'>
            장르 <span className='c-red'>*</span>
          </div>

          <div className='' style={{ width: '180px'}}>
            <Dropdown options={genreList} select={stalierUpdateData.genre} setSelect={item => {
              console.log(item)
              handleChange('genre', item)
            }}/>
          </div>
        </>


        <div className='mt-24'/>
        <KeywordInput selectKeywordList={stalierUpdateData.keywords} keywordSelect={keywordSelect}/>

        <>
          <div className='bold font-18 mt-32 mb-10'>
            연령등급 <span className='c-red'>*</span>
          </div>

          <div className='' style={{ width: '180px'}}>
            <Dropdown options={AgeGradeList} select={stalierUpdateData.ageGrade} setSelect={item => handleChange('ageGrade', item)}/>
          </div>
        </>

        <>
          <div className='bold font-18 mt-32 mb-10'>
            완결 여부 <span className='c-red'>*</span>
          </div>

          <div className='w-full flex align-center'>
            <CheckBox selected={!stalierUpdateData.hasCompletion} onClick={e => handleChange('hasCompletion', false)} label={"연재중"}/>
            
            <div className='mr-16'/>

            <CheckBox selected={stalierUpdateData.hasCompletion} onClick={e => handleChange('hasCompletion', true)} label={"완결"}/>
          </div>
        </>
        
        
        
        <>
          <div className='bold font-18 mt-32 mb-10'>
            작품 소개 <span className='c-red'>*</span>
          </div>

          <div className='w-full'>
            <Textarea value={stalierUpdateData.summary} onChange={e => handleChange('summary', e.target.value)}/>
          </div>
        </>


        <>
          <div className='bold font-18 mt-32 mb-24'>
            썸네일 <span className='c-red'>*</span>
          </div>

          <div className=''>
            <input type="file" accept="image/*" id="imageUpload" style={{ display: "none" }} onChange={(e) => handleChange('book_cover_file', e.target.files[0])}/>
            
            <label htmlFor='imageUpload' className='imageUpload flex-center flex-col w-full'>
              <img 
                className='book_cover mb-24 pointer' 
                src={stalierUpdateData.book_cover ? (stalierUpdateData.book_cover_file ? URL.createObjectURL(stalierUpdateData.book_cover_file) : null) || formatImageUri(stalierUpdateData.book_cover) : book_cover_sample}/>
            </label>
          </div>
        </>


        <div className='mt-48'/>
        <Button width={"100%"} fontSize={20} isLoading={isLoading} onClick={submit}>등록하기</Button>
      </div>



    </Container>
  )
}