import { accordion_down, accordion_up, dropdown_arrow } from "@/assets/components";
import { useState } from "react"
import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid #e7e6e7;
  color: #575757;

  .arrow-position {
    top: 15px;
    height: 24px;
    width: 24px;
    right: 16px;
  }

  .dropImg {
    width: 12px;
  }

  .title {
    font-weight: 600;
  }
`


export const AccordionMenu3 = ({ content, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className='px-20 py-16 w-full relative font-14' onClick={() => setIsOpen(chk => !chk)}>
      <div className="flex justify-between align-center">
        <div className="title">{content}</div>
        {/* <div className="bold p-16">상세</div> */}
        <img src={dropdown_arrow} className={`dropImg ${isOpen && "flip-v"}`}/>
      </div>
      {isOpen && <div className="mt-14 pre-line">{children}</div>}
    </Container>
  )
}