import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput } from '..'
import { getTheme } from '@/styles/theme'
import { useSignup } from '@/stores/signup'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import LoadingCover from '../Loading/cover'
import { validatePassword } from '@/utils/validate'


const Container = styled.div`

`


export default function Step3 () {
  const { submit, signupData, handleChange, agreeServicePolicy, isLoading } = useSignup();

  const [confirmPassword, setConfirmPassword] = useState('');

  function passwordConfirmation() {
    if(signupData.password === confirmPassword && signupData.password !== '') {
      if(!validatePassword(signupData.password))
        return alert('비밀번호는 8~20자, 대소문자, 숫자, 특수기호가 포함되어야 합니다.')
      else {
        if(signupData.agreedToServicePolicy.agreed) submit();
        else alert('서비스 이용약관 동의를 해주세요.');
      }
    }
    else return alert('비밀번호 확인 불일치');
  }

  return (
    <Container> 
      {isLoading && <LoadingCover/>}

      <div className='black font-30 c-primary100'>회원가입</div>
      <div className='bold font-24 mt-4 mb-72'>스텔라가 처음이신가요?</div>

      <LabelInput value={signupData.password} onChange={e => handleChange('password', e.target.value)} label={"비밀번호"} type="password"/>
      <div className='mt-24'/>

      <LabelInput value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} label={"비밀번호 확인"} type="password"/>
      <div className='mt-24'/>
      



      <div className='flex align-center'>
        <CheckBox selected={signupData.agreedToServicePolicy.agreed} onClick={agreeServicePolicy}/>
        <a href='https://stella-71d54.web.app/' target='_blank' className='underline c-primary100 semibold'>서비스 이용약관</a>
        <div className='medium'>에 동의합니다.</div>
      </div>

      <div className='mt-48'/>

      <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={passwordConfirmation}>완료</Button>

    </Container>
  )
}