import { Fragment, useState } from 'react'
import './font.css'
import { AppRouter } from './routers/AppRouter'
import GlobalStyles from './styles/GlobalStyles'

const setCSP = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const cspMetaTag = document.createElement('meta');
    cspMetaTag.httpEquiv = 'Content-Security-Policy';
    cspMetaTag.content = "default-src 'self'; script-src 'self'; object-src 'none';";
    document.head.appendChild(cspMetaTag);
  });
};

// setCSP();

function App() {

  return (
    <>
      <AppRouter/>
      <GlobalStyles/>
    </>
  )
}

export default App
