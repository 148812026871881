import { AccordionMenu2 } from '@/components/AccordionMenu2';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { OPENSOURCE_ANDROID } from '@/static/opensource_android';
import { OPENSOURCE_BACKEND } from '@/static/opensource_backend';
import { OPENSOURCE_IOS } from '@/static/opensource_ios';
import { OPENSOURCE_WEB } from '@/static/opensource_web';
import { useState } from 'react';
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 0 20px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`

const TabButton = styled.div`
  padding: 5px;
  font-size: 16px;
  font-weight: ${({ selected }) => selected ? 900 : 500};
`

const OpenSourceTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
`

const OpenSourceSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`

const TABS = ['web', 'android', 'ios', 'backend'];

export default function Page () {
  useHideLayout('header', true);
  const [tab, setTab] = useState(TABS[0]);
  const [datas, setDatas] = useState(OPENSOURCE_WEB);

  return (
    <Container>
      <TitleHeader title="오픈소스 사용 정보"/>
      <TabContainer>
        {TABS.map((t) => (
          <TabButton key={t} selected={tab === t} onClick={() => {
            if(t === "web") setDatas(OPENSOURCE_WEB);
            else if(t === "android") setDatas(OPENSOURCE_ANDROID);
            else if(t === "ios") setDatas(OPENSOURCE_IOS);
            else if(t === "backend") setDatas(OPENSOURCE_BACKEND);
            setTab(t)
          }}>{t}</TabButton>
        ))}
      </TabContainer>

      <div>
        {datas.map((item, idx) => (
          <AccordionMenu2 content={item.uses} key={`${tab}-${idx}`}>
            <OpenSourceTitle>{item.name}</OpenSourceTitle>
            <OpenSourceSubTitle>license명: {item.license}</OpenSourceSubTitle>
            <OpenSourceSubTitle>copyright: {item.copyright}</OpenSourceSubTitle>
          </AccordionMenu2>
        )) 
        }
      </div>
    </Container>
  )
}
