import { fetchOdiChargeWithIOSPayment } from '@/api/coin';
import Loading from '@/components/Loading';
import { useHideLayout } from '@/hooks'
import { useUser } from '@/stores';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'




const Container = styled.div`
  width: 100%;
  height: 100%;
`


export default function Page () {
  const { uid } = useParams(); // 상품 product_id_ios
  const [searchParams, ] = useSearchParams();
  const { refreshUserData } = useUser();

  const orderId = searchParams.get('orderId');
  const receipt = searchParams.get('receipt');

  useHideLayout('header', true);

  useEffect(() => {
    submit();
  }, []);

  async function submit() {
    try {
      const userData = (await refreshUserData());
      await fetchOdiChargeWithIOSPayment({
        orderId, 
        receipt,
        previousQuantity: userData?.currentBalance ?? 0,
        product_id: uid
      });

    } catch {
      alert('로그인 정보를 불러올 수 없었습니다.');
      return window.location.href = '/'
    }
  }


  return (
    <Container className='flex-center'>
      <Loading/>
      
    </Container>
  )
}