import { ic_scroll_down_black } from '@/assets';
import { useHideLayout } from '@/hooks';
import { formatImageUri } from '@/utils/format';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'


import { useEffect, useRef, useState } from 'react';
import { fetchCreateStalierView, fetchCreateView } from '@/api/view';

import StalierEpisodeHeader from '@/components/Stalier/EpisodeHeader';
import { fetchStalierEpisodeDetailData } from '@/api/stalier';


const Container = styled.div`
  padding-top: 140px;

  .episode_book_cover {
    width: 64%;
    max-width: 200px;
    border-radius: 10px;
  }
`


export default function Page () {

  const { uid } = useParams();

  const [episodeData, setEpisodeData] = useState(null);
  const navigate = useNavigate();



  useHideLayout('header', true)  


  useEffect(() => {
    async function createView() {

      // 조회
      await fetchCreateStalierView(
        episodeData?.stalier_uuid, 
        uid,
      );
    }

    if(episodeData?.stalier_uuid) createView();
  }, [episodeData])


  useEffect(() => {
    async function getData() {
      const response = await fetchStalierEpisodeDetailData(uid);
      setEpisodeData(response);
    }


    if(uid) getData();
  }, [uid])


  function openEpisode(uuid) {
    navigate(`/stalier/episode/${uuid}`);
  }

  return (
    <Container className='block-draggable'>
      <StalierEpisodeHeader
        stalier_uuid={episodeData?.stalier_uuid}
        stalier_title={episodeData?.stalier?.title}
        episode_title={episodeData?.title}
      />

      <div className="w-full pt-32 pb-48 ">
        {episodeData?.stalier?.book_cover && <div className='w-full flex-center'>
          <img className='episode_book_cover' src={formatImageUri(episodeData?.stalier?.book_cover)}/>
        </div>}

        <div className='text-center bold font-16' style={{ width: '80%', margin: '40px auto' }}>{episodeData?.title}</div>

        {/* Content */}
        <div className='px-20 pre-line font-16'>
          {episodeData?.content}
        </div>

        <div className='mb-60'/>

        {episodeData?.next_uid && 
          <div onClick={() => {
            openEpisode(episodeData?.next_uid)
          }} className='floatElement flex flex-center w-full'>
            <img style={{ width: '14px '}} src={ic_scroll_down_black}/>
            <div className='ml-10 font-14 bold'>다음으로</div>
          </div>
        }
      </div>
    </Container>
  )
}