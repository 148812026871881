import { ic_navigate_back } from '@/assets'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  top: 0;
  height: 60px;
  z-index: 20;
  background-color: #fff;
  border-bottom: 2px solid #e7e6e7;

  .back_image {
    width: 24px;
    left: 24px;
  }

  .wititle {
    width: calc(100vw - 76px);
    text-align: center;
  }
`


export default function TitleHeader ({ 
  title,
  link=-1
}) {
  const navigate = useNavigate();

  return (
    <Container className=' flex-center px-24'>
      <img className='absolute back_image pointer' src={ic_navigate_back} onClick={() => navigate(link)}/>

      <div className='font-20 exbold ellipsis-1 wititle'>{title}</div>
    </Container>
  )
}