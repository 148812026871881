import { fetchNewInquiry } from '@/api/inquiry';
import { Button, Dropdown, LabelInput, Textarea } from '@/components';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 20px 20px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Label = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  color: #2D2D2D;
`

const QUESTION_OPTIONS = [
  { value: '1', name: '문의 유형 1' },
  { value: '2', name: '문의 유형 2' },
  { value: '3', name: '문의 유형 3' },
  { value: '4', name: '문의 유형 4' },
  { value: '5', name: '문의 유형 5' },
]

export default function Page () {
  useHideLayout('header', true);
  const navigate = useNavigate();
  const [question, setQuestion] = useState({
    title: '',
    type: QUESTION_OPTIONS[0],
    content: '',
  });

  async function submit() {
    await fetchNewInquiry({
      ...question,
      inquiry_type: question.type.value
    })
  }

  return (
    <Container>
      <TitleHeader title="1대1 문의하기"/>
      <div className='mb-20'>
        <LabelInput
          label="문의 제목"
          placeholder="문의 제목을 입력해주세요"
          value={question.title}
          onChange={e => setQuestion({ ...question, title: e.target.value })}
        />
      </div>
      <div className='mb-20'>
        <Label>문의 유형</Label>
        <Dropdown options={QUESTION_OPTIONS} select={question.type} setSelect={type => setQuestion({ ...question, type })}/>
      </div>
      <div className='mb-20'>
        <Label>문의 내용</Label>
        <Textarea
          placeholder="문의 내용을 입력해주세요"
          value={question.content}
          onChange={e => setQuestion({ ...question, content: e.target.value })}
        />
      </div>

      <div className='mb-20'>3~5영업일 내에 답변을 드리게 됩니다.</div>

      <div style={{ marginTop: 'auto' }}>
        <Button width="100%" onClick={submit}>1:1 문의 등록하기</Button>
      </div>
    </Container>
  )
}
