export const OPENSOURCE_ANDROID = [
  {
    "name": "core-ktx",
    "license": "Apache-2.0",
    "uses": "Android Kotlin 확장 라이브러리",
    "copyright": "Copyright The Android Open Source Project"
  },
  {
    "name": "appcompat",
    "license": "Apache-2.0",
    "uses": "Android에서 이전 버전과의 호환성을 제공",
    "copyright": "Copyright The Android Open Source Project"
  },
  {
    "name": "material",
    "license": "Apache-2.0",
    "uses": "Material Design 컴포넌트를 제공",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "constraintlayout",
    "license": "Apache-2.0",
    "uses": "레이아웃을 제약 조건 기반으로 설계하는 라이브러리",
    "copyright": "Copyright The Android Open Source Project"
  },
  {
    "name": "firebase-bom",
    "license": "Apache-2.0",
    "uses": "Firebase 플랫폼을 위한 종속성 관리",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "firebase-messaging-ktx",
    "license": "Apache-2.0",
    "uses": "Firebase 클라우드 메시징 기능",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "firebase-auth",
    "license": "Apache-2.0",
    "uses": "Firebase 인증 기능",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "firebase-inappmessaging-display",
    "license": "Apache-2.0",
    "uses": "Firebase 인앱 메시지 표시 기능",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "play-services-auth",
    "license": "Apache-2.0",
    "uses": "Google Play 서비스 인증 기능",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "tedpermission-normal",
    "license": "Apache-2.0",
    "uses": "Android에서 권한 요청을 쉽게 관리하는 라이브러리",
    "copyright": "Copyright Park Sang Gwon"
  },
  {
    "name": "lottie",
    "license": "Apache-2.0",
    "uses": "JSON 기반 애니메이션을 렌더링하는 라이브러리",
    "copyright": "Copyright Airbnb"
  },
  {
    "name": "browser",
    "license": "Apache-2.0",
    "uses": "Chrome Custom Tabs를 지원하는 브라우저 기능 제공",
    "copyright": "Copyright The Android Open Source Project"
  },
  {
    "name": "retrofit",
    "license": "Apache-2.0",
    "uses": "HTTP 클라이언트 인터페이스 제공",
    "copyright": "Copyright Square, Inc."
  },
  {
    "name": "converter-moshi",
    "license": "Apache-2.0",
    "uses": "Moshi를 사용하여 JSON 변환을 지원하는 Retrofit 모듈",
    "copyright": "Copyright Square, Inc."
  },
  {
    "name": "converter-gson",
    "license": "Apache-2.0",
    "uses": "Gson을 사용하여 JSON 변환을 지원하는 Retrofit 모듈",
    "copyright": "Copyright Square, Inc."
  },
  {
    "name": "retrofit2-kotlin-coroutines-adapter",
    "license": "Apache-2.0",
    "uses": "Retrofit을 위한 코루틴 어댑터",
    "copyright": "Copyright Jake Wharton"
  },
  {
    "name": "okhttp",
    "license": "Apache-2.0",
    "uses": "HTTP 클라이언트 라이브러리",
    "copyright": "Copyright Square, Inc."
  },
  {
    "name": "logging-interceptor",
    "license": "Apache-2.0",
    "uses": "HTTP 요청 및 응답 로깅을 지원하는 OkHttp 모듈",
    "copyright": "Copyright Square, Inc."
  }
]
