import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchStalierGenreList } from '@/api/stalier';


const stalierListState = atom({
  key: 'StalierList',
  default: [],
});


export const genreState = atom({
  key: 'StalierGenreState',
  default: '',
});



export const useStalierList = () => {

  const [stalierList, setStalierList] = useRecoilState(stalierListState);
  const [genre, setGenre] = useRecoilState(genreState);
  const [isLoading, setIsLoading] = useState(false);
  
  // 장르 변경 시 초기화 필요
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
    
  const [searchParams, setSearchParams] = useSearchParams();
  

  useEffect(() => {
    const genreParam = searchParams.get('genre');
    setGenre(genreParam);

    if(genreParam) {

      // 장르 변경 시
      if(genreParam !== genre) {
        setStalierList([]);
        setPage(1);
        getStalierGenreInitList(genreParam, 1);
      }

      // 장르 변경 안되었을 시
      else {
        if (!genre && genreParam) {
          if(hasMore || page === 1) getStalierGenreList(genreParam, page);
        }
      }
    }
  }, [searchParams, page]);


  const goToNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };


  // 리스트 호출
  const getStalierGenreList = async (genre, page) => {
    if(isLoading) return;
    console.log('리스트 호출', stalierList.length, genre, page)
    setIsLoading(true);

    try {
      const genreList = await fetchStalierGenreList(genre, page);
      setStalierList([...stalierList, ...genreList.data]); // Pagination 적용 시, Genre, Page 변환에 따라 초기화 필요
      
      console.log([...stalierList, ...genreList.data])

      // 추가분이 있는지
      const listCount = [...stalierList, ...genreList.data].length;
      if(listCount < genreList.totalCount) setHasMore(genreList.hasMore);

    } catch (error) {
      console.error('Failed to fetch novel genre list:', error);
    } finally {
      setIsLoading(false);
    }
  };


  // 초기화 + 리스트 호출
  const getStalierGenreInitList = async (genre, page) => {
    if(isLoading) return;

    console.log('초기화 + 리스트 호출', stalierList.length, genre, page)
    setIsLoading(true);
    try {
      const genreList = await fetchStalierGenreList(genre, page);
      setStalierList(genreList.data); // Pagination 적용 시, Genre, Page 변환에 따라 초기화 필요
      
      // 추가분이 있는지
      const listCount = genreList.data.length;
      if(listCount < genreList.totalCount) setHasMore(genreList.hasMore);

    } catch (error) {
      console.error('Failed to fetch novel genre list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  
  return {
    stalierList,
    page,
    hasMore,
    genre,
    isLoading,
    goToNextPage,
  };
};
