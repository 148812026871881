import { ic_setting_center, ic_setting_comment, ic_setting_faq, ic_setting_noti, ic_setting_opensource, ic_setting_person, ic_setting_shop } from '@/assets';
import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 20px;

  .nav {
    width: fit-content;
  }
`


export default function Page () {

  useHideLayout('header', true);

  const navigate = useNavigate();


  return (
    <Container>
      <TitleHeader title={"설정"}/>

      <div className='nav flex align-center mb-32' onClick={() => navigate('/setting/user')}>
        <img src={ic_setting_person}/>
        <div className='semibold font-18 ml-8'>계정 설정</div>
      </div>
      
      <div className='nav flex align-center mb-32' onClick={() => window.location.href = '/setting/notification'}>
        <img src={ic_setting_noti}/>
        <div className='semibold font-18 ml-8'>알림 설정</div>
      </div>

      <div className='nav flex align-center mb-32' onClick={() => navigate('/charge/history/buy')}>
        <img src={ic_setting_shop}/>
        <div className='semibold font-18 ml-8'>구매 내역</div>
      </div>

      {/* <div className='nav flex align-center mb-32' onClick={() => navigate('/setting/comments')}>
        <img src={ic_setting_comment}/>
        <div className='semibold font-18 ml-8'>작성 댓글 목록</div>
      </div> */}

      <div className='nav flex align-center mb-32' onClick={() => navigate('/setting/faq')}>
        <img src={ic_setting_faq}/>
        <div className='semibold font-18 ml-8'>FAQ</div>
      </div>

      <div className='nav flex align-center mb-32' onClick={() => navigate('/setting/customers')}>
        <img src={ic_setting_center}/>
        <div className='semibold font-18 ml-8'>고객센터</div>
      </div>

      
      <div className='nav flex align-center mb-32' onClick={() => navigate('/setting/opensource')}>
        <img src={ic_setting_opensource}/>
        <div className='semibold font-18 ml-8'>오픈소스 사용정보</div>
      </div>
    </Container>
  )
}