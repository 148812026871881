import styled from 'styled-components'
import { loadPaymentWidget } from "@tosspayments/payment-widget-sdk"
import { useEffect, useRef, useState } from 'react'
import { useLocation } from "react-router-dom";
import { useHideLayout } from '@/hooks';
import { nanoid } from 'nanoid';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { Button } from '@/components';
import { getTheme } from '@/styles/theme';
import { useUser } from '@/stores';

const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 40px;
`

// [TODO] Query를 통해 amount, 상품 데이터 추가 필요
export default function Page () {
  useHideLayout('header', true);

  const { user } = useUser('payment');

  // 이전 페이지에서 상품 정보 가져오기
  const location = useLocation();
  const paymentState = location.state;

  // 위젯 Ref 선언
  const paymentWidgetRef = useRef(null);

  // 위젯 컴포넌트 설정 선언
  useEffect(() => {
    (async () => {
      const { email } = user

      const TOSS_PAYMENT_KEY = import.meta.env.VITE_TOSS_PAYMENT_KEY;

      const paymentWidget = await loadPaymentWidget(TOSS_PAYMENT_KEY, `user_id_e_${email}`)

      paymentWidget.renderPaymentMethods("#payment-widget", {
          value: paymentState.price
        }, 
        { variantKey: 'DEFAULT' }
      )

      paymentWidgetRef.current = paymentWidget
    })()
  }, []);

  
  const onRequestPayment = async () => {
    const id = nanoid(32);

    // 결제 위젯 - 결제 실행
    await paymentWidgetRef.current?.requestPayment({
      orderId: id, // 랜덤 값 생성
      orderName: paymentState.title, // 주문 내용
      successUrl: window.location.origin + `/charge/done/${paymentState.product_id}`,
      failUrl: window.location.origin + "/charge/fail", // 결제 실패 시
      customerEmail: user.email, // 주문자 email
      customerName: user.name, // 주문자 이름
    });
  }


  return (
    <Container>
      <TitleHeader title="스타 충전"/>
      {/* 결제 위젯 표출 */}
      <div id="payment-widget" />

      {/* 결제 버튼 */}
      <div className='w-full px-20'>
        <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={onRequestPayment}>다음</Button>
      </div>
    </Container>
  )
}