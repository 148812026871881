import { fetchStalierEpisodeListData } from '@/api/stalier';
import AD from '@/components/AD';
import GenreSelector from '@/components/GenreSelector';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useStalierOwnData } from '@/stores/stalierOwnData';
import { formatDateYMD, formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 80px;

  
  .book_cover {
    border-radius: 10px;
    width: 100px;
    height: 150px;
  }         
  .white-line {
    width: 100%;
    height: 1px;
    background-color: #e7e6e7;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .episode-button {
    margin-top: 12px;
    border: 1.5px solid #5e5f5e;
    height: 52px;
    border-radius: 4px;
  }

  

  .episode-item {
    width: 100%; 
    border-bottom: 1px solid #e7e6e7;
    padding: 10px 20px;
  }
`


export default function StalierPage () {
  useHideLayout('header', true);

  const { uid } = useParams();

  const { stalierOwnData } = useStalierOwnData(uid);
  
  const navigate = useNavigate();

  const [episodeList, setEpisodeList] = useState([]);
  const [sort_type, set_sort_type] = useState(true);
  
  useEffect(() => { 
    const getStailerOwnData = async () => {
      const result = await fetchStalierEpisodeListData(uid);
      setEpisodeList(result);
    } 

    if(uid) getStailerOwnData();
  }, []);


  function openEpisode(item) {
    // item.uuid
    navigate(`/stalier/own/episode/${uid}/edit`, { state: { episode_uuid: item.uuid }})
  }

  return (
    <Container className='block-draggable'>
      <TitleHeader title="나의 스텔리에"/>
      
      <div className='px-20 mb-20'>
        <div className='flex'>
          {/* Book Cover Image */}
          {stalierOwnData?.book_cover && <img className='book_cover' src={formatImageUri(stalierOwnData?.book_cover)} alt='표지가 없습니다.' />}

          <div className='ml-12'>
            <div className=''>
              <div className='font-16 bold mr-10'>{stalierOwnData?.title}</div>
            </div>
            <div className='font-14 mt-8'>{stalierOwnData?.writer_user?.nickname}</div>

            {!stalierOwnData?.release_note && <div className='mb-44'/>}
            {stalierOwnData?.release_note && <div className='mt-14 mb-20 font-12 bold'>{stalierOwnData?.release_note}</div>}
            
            <div className='mt-18 font-14'>{stalierOwnData?.genre?.name}</div>
            <div className='mt-2 font-12'>#{stalierOwnData?.keywords?.map(item => item.name).join(', #')}</div>
          </div>
        </div>
      </div>


      <div className='px-20 mb-12'>
        <div className='episode-button flex-center font-20 bold pointer' onClick={() => navigate(`/stalier/own/edit/${uid}`)}>작품 정보 수정</div>
      </div>

      <div className='white-box'/>


      <div className='px-20 mb-12'>
        {stalierOwnData?.isActive && <div className='episode-button flex-center font-20 bold pointer' onClick={() => navigate(`/stalier/own/episode/${uid}/add`)}>연재하기</div>}
        {!stalierOwnData?.isActive && <div className='episode-button flex-center font-20 bold pointer'>비활성화 상태입니다.</div>}
      </div>

      <div className='white-box'/>

      {/* Episode */}

      <div className='flex-center px-20 w-full py-10'>
        <div className='flex-1 font-12 flex align-center'>
          <div className="bold">총 {episodeList?.length}화</div>
        </div>
        <div className='font-12 flex-center medium'>
          <div className={!sort_type ? 'c-primary' : 'c-878787'} onClick={() => set_sort_type(false)}>회차순</div>
          <div className='mx-2 c-878787 font-10'>|</div>
          <div className={sort_type ? 'c-primary' : 'c-878787'}  onClick={() => set_sort_type(true)}>등록순</div>
        </div>
      </div>

      <div className='white-box'/>

      <div>
        {
          [...episodeList]?.sort((a, b) => {
            if(!sort_type) {
              return b?.episode_index - a?.episode_index;
            } else {
              return a?.episode_index - b?.episode_index;
            }
          })?.map((item, idx) => (
            <div className='episode-item w-full flex align-center' key={`episode-${idx}`} onClick={() => openEpisode(item)} style={{
              backgroundColor: item.hasRead ? '#e8e8e8' : '#fff'
            }}>
              <div className="flex-1">
                <div className='medium mb-8 font-14 ellipsis-1 mb-2'>{item.title}</div>
                <div className='font-10 medium c-929292'>{formatDateYMD(item.updated_date)}</div>
              </div>

              {!item?.isActive && <div className='c-red medium'>비활성화 (수정필요)</div>}
            </div>
          ))
        }
      </div>
    </Container>
  )
}