import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader'
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 0;

  .policy-text {
    line-height: 28px;
  }
`


export default function Page () {

  useHideLayout('header', true)
  return (
    <Container>
      <TitleHeader/>

      <div className='w-full px-20'>
        <div className='exbold font-30 text-center mb-60'>개인정보 처리방침</div>

        <div className='pre-wrap policy-text'>
{`개인정보 처리방침

주식회사 스텔미디어(이하 "회사")는 이용자의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」 제30조에 따라 이용자에게 개인정보의 처리와 보호에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립・공개합니다.

제 1 조 (처리하는 개인정보 항목)

회사는 다음의 개인정보 항목을 처리하고 있습니다.
① 회사는 회원 신규 가입 및 서비스 이용 과정에서 아래와 같은 정보를 제공받아 처리합니다.
  1. 이름, 생년월일, 이메일주소, 연령, 성별, 휴대폰번호, 디바이스번호, 기기정보, 닉네임, 프로필사진, 중복가입확인정보 해시값, 암호화된 동일인 식별정보, 내/외국인 정보, 서비스 이용기록, 접속IP주소, 쿠키
  2. 성인용 서비스를 이용하고자 하는 경우, 인증기관이 요청하는 개인정보(NICE평가정보(주)를 통한 성인인증에 필요한 이름, 생년월일, 휴대폰번호, 통신사, PASS앱 본인인증 정보 일체)를 인증기관을 통해 수집하고, 회사는 인증기록을 제공받음.
② 비회원 이용시 수집하는 개인정보 : 단말기 디바이스 번호, 서비스 이용기록
③ 유료 콘텐츠 등을 신규 게시하는 회원 및 기존 업로드 한 콘텐츠 등을 유료화하려는 회원으로부터 아래와 같은 정보를 제공받아 처리합니다.
  1. 개인 판매자 : 이름, 이메일 주소, 휴대폰번호, 주소, 주민등록번호, 계좌정보(예금주, 은행명, 계좌번호)
  2. 개인/법인사업자 : 이름, 대표자(대표이사)명, 사업자번호, 전화번호, 사업장주소, 담당자 이름, 담당자 전화번호, 담당자 이메일주소, 계좌정보(예금주, 은행명, 계좌정보)
④ 서비스 이용과정이나 서비스 제공 업무 처리 과정에서 아래와 같은 정보들이 자동으로 생성되거나 추가로 처리될 수 있습니다.
  - IP주소, 쿠키, 기기정보, 접속로그, 방문일시, 결제기록, 서비스 이용기록, 불량 이용기록
⑤ 유료 콘텐츠 이용 과정에서 아래와 같은 정보들이 개인정보 수탁자(결제대행사-토스페이먼츠(주))에 의해 수집될 수 있습니다.
  - 신용카드 카드사명, 카드번호 등


제 2 조 (개인정보 처리 목적)

회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.

  1. 콘텐츠 등 서비스 제공
  2. 이용자 식별, 본인 확인, 성인 인증, 연령 확인 및 법정대리인 동의 진행, 회원관리
  3. 서비스 부정이용 방지, 불량 이용자 제재, 분쟁 조정을 위한 기록 보존
  4. 인구통계학적 특성, 방문 및 이용기록 분석, 서비스 개선, 맞춤서비스 제공, 광고 게재, 마케팅 활용
  5. 유료 구매/판매 상품 관리, 결제처리, 요금청구 및 대금정산, 세금처리
  6. 이벤트 참여, 경품 추첨, 경품 중복 제공 방지
  7. 공지사항 전달, 민원처리, 회원탈퇴 의사의 확인


제 3 조 (개인정보의 처리 및 보유기간)

회사는 원칙적으로 이용자의 개인정보를 회원탈퇴 또는 이용목적 달성 시 지체없이 파기하고 있습니다. 단, 이용자에게 개인정보 보관기간에 대해 별도의 동의를 얻은 경우, 또는 법령에서 일정 기간 정보보관 의무를 부과하는 경우에는 해당 기간 동안 개인정보를 안전하게 보관합니다.

① 이용자에게 동의를 얻어 별도의 기간으로 보관하는 경우는 아래와 같습니다.
  1. 본인확인 및 연령확인을 위해 수집한 인증로그(휴대전화번호, 통신사 정보): 본인확인일로부터 1년 보관
  2. 법정대리인 증명서류를 제외한 법정대리인 정보: 아동이 서비스 해지 시 또는 성년이 된 시점까지 보관 (단, 미성년 기간 동안 판매 이력이 있는 경우에는 아동이 성년이 된 후 3년이 경과한 시점까지 보관)
  3. 이벤트 응모 및 경품 지급 정보: 개인정보 수집일 또는 이벤트 종료일로부터 최대 6개월 보관
  4. 작품 투고 : 투고일로부터 5년 보관
  5. 불량회원 및 부정 이용 방지를 위한 내부식별 정보: 탈퇴/해지 후 6개월 보관
② 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지 보관합니다.

  1. 전자상거래 등에서의 소비자보호에 관한 법률
    · 계약 또는 청약철회 등에 관한 기록 : 5년 보관
    · 대금결제 및 재화 등의 공급에 관한 기록 : 5년 보관
    · 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 보관
  2. 통신비밀보호법
    · 로그인 기록 : 3개월
  3. 국세기본법, 법인세법
    · 세법이 규정하는 모든 거래에 관한 장부 및 증빙서류 : 5년


제 4 조 (개인정보의 파기 절차 및 방법)

① 회사는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.
② 이용자로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.
  1. 파기절차
    회사는 파기 사유가 발생한 개인정보를 선정하고, 내부 방침 및 기타 관련 법령에 의한 사유에 따라 일정 기간 저장 후 파기합니다.
  2. 파기방법
    전자적 파일 형태로 기록・저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록・저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.


제 5 조 (개인정보의 제3자 제공)

회사는 이용자의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 이용자의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고, 그 이외에는 이용자의 개인정보를 제3자에게 제공하지 않습니다.


제 6 조 (개인정보 처리 위탁 사항)

① 회사는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
② 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.


제 7 조 (이용자의 권리와 의무 및 그 행사방법)

① 이용자 및 만 14세 미만 아동의 법정대리인은 회사에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.
② 권리 행사는 앱 또는 이메일을 통하여 하실 수 있으며, 회사는 이에 대해 지체없이 조치하겠습니다.
③ 이용자는 언제든지 앱 내 설정>계정정보 탭에서 '서비스 탈퇴'을 통해 개인정보의 수집 및 이용 동의를 철회할 수 있습니다.
④ 이용자는 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를 예방해야 하며, 부정확한 정보로 인해 발생하는 사고의 책임은 이용자 본인에게 있습니다. 타인의 정보를 도용하거나 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.
⑤ 이용자는 스스로를 보호하고 타인의 정보를 침해하지 않을 의무를 가지고 있습니다. 


제 8 조 (개인정보의 안전성 확보 조치)

회사는 이용자의 개인정보의 안전선 확보를 위해 다음과 같은 조치를 취하고 있습니다.

  1. 관리적 조치 : 내부관리계획의 수립 및 시행, 정기적 직원 교육
  2. 기술적 조치 : 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 개인정보의 암호화, 보안프로그램 설치 및 갱신
  3. 물리적 조치 : 자료보관 서버 접근통제


제 9 조 (개인정보 자동 수집 장치의 설치/운영 및 거부에 관한 사항)

① 회사는 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내 하드디스크에 저장되기도 합니다.
③ 이용자는 웹 브라우저 옵션 설정을 통해 쿠키 허용, 차단 등의 설정을 할 수 있습니다. 다만, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.


제 10 조 (개인정보 보호 책임자 및 관리자 연락처)

회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 책임자를 지정하고 있습니다.

[개인정보 보호책임자 및 담당부서]
성명 : 장한영
부서명 : 운영팀
전화번호 : 02-6375-5815
이메일 : greatyoung@stellmedia.xyz


제 11 조 (권익침해 구제방법)

① 이용자는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
  ‣ 개인정보분쟁조정위원회: (국번없이) 1833-6972 (http://www.kopico.go.kr/)
  ‣ 개인정보침해신고센터: (국번없이) 118 (http://privacy.kisa.or.kr/)
  ‣ 대검찰청: (국번없이) 1301 (http://www.spo.go.kr/)
  ‣ 경찰청: (국번없이) 182 (http://police.go.kr/).
② 회사는 이용자의 개인정보 보호 관련 민원에 대하여 성실한 상담 및 피해 구제 노력을 하고 있습니다. 신고나 상담이 필요한 경우 제10조 개인정보 보호책임자에게 연락해 주시기 바랍니다.


제 12조 (개인정보 처리방침의 변경에 관한 사항)

① 현 개인정보처리방침 내용의 추가 및 삭제·수정이 있을 수 있으며, 이 경우 시행 7일 전부터 시행일 전일까지 홈페이지의 공지사항을 통해 사전 공지하고 사전 공지가 곤란한 경우 지체 없이 공지합니다. 다만, 이용자의 권리에 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
② 회사가 변경 내용의 고지를 통해 안내한 변경 적용 일까지 사용자가 명시적으로 거부 의사를 표시하지 않는 경우, 변경 내용에 동의한 것으로 간주합니다.

이 개인정보 처리방침은 2024. 09. 01. 부터 적용됩니다.`}
        </div>
      </div>
      
    </Container>
  )
}