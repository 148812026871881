import { RefObject, useEffect } from 'react';

const useInfiniteScroll = (handler, target) => {
  const observer = new IntersectionObserver((entries) =>
    entries.forEach((entry) => {
      if (entry.isIntersecting) handler();
    })
  );

  useEffect(() => {
    if (target.current == null) return () => undefined;
    observer.observe(target.current);
    return () => observer.disconnect();
  }, [handler, target]);
};

export default useInfiniteScroll;
