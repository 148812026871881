import styled from 'styled-components'
import { Button, Modal } from '..'
import { getTheme } from '@/styles/theme'
import { fetchRentBenefitNovel } from '@/api/rent'


const Container = styled.div`

`


export default function NovelRentBenefitModal ({
  isOpen,
  onClose,
  changeBenefitCertificate,
  novelData
}) {

  async function submitRent() {
    await fetchRentBenefitNovel({
      novel_id: novelData._id, 
      benefitCount: novelData.rentBenefit,
      novel_uuid: novelData.uuid,
    }).then(() => {
      changeBenefitCertificate();
    })

    // setBenefitsCertificate를 false로 데이터 변경 필요

    onClose();
  }

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose} height={208}>
        <div className='bold font-24 text-center ellipsis w-full'>신규 독자 혜택</div>

        <div className='medium font-16 py-16 text-center ellipsis w-full'>
          <div className='font-18 bold'>"{novelData.title}"</div>
          대여권을 {novelData.rentBenefit}개 받을 수 있어요!
        </div>

        <div className='flex flex-center mt-20'>
          <div className='flex-1'>
            <Button background={getTheme('black')} style={{width: '100%'}} onClick={onClose}>
              <div className='flex align-end justify-center'>
                <div className='font-16 bold'>건너뛰기</div>
              </div>
            </Button>
          </div>

          <div className='flex-1 ml-8'>
            <Button background={getTheme('primary100')} style={{width: '100%'}} onClick={submitRent}>
              <div className='font-18 bold'>혜택받기</div>
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  )
}