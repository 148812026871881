import { fetchProductList } from '@/api/product'
import { ic_odi } from '@/assets'
import Banner from '@/components/Banner'
import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader'
import { useUser } from '@/stores'
import { formatNumber } from '@/utils/format'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  padding: 70px 0;

  .history_box {
    border-radius: 8px;
    border: 2px solid #e1e1e1;
  }

  .odi_medium {
    width: 30px;
  }
  .odi_small {
    width: 18px;
  }

  .grey_bottom_2 {
    border-bottom: 2px solid #e1e1e1;
  }

  .grey_bottom_1 {
    border-bottom: 1px solid #e1e1e1;
  }

  .price_box {
    border: 2px solid #000;
    width: 92px;
    height: 42px;
    border-radius: 8px;
  }
`


export default function Page () {
  useHideLayout('header', true);


  const { user } = useUser('payment-buy');

  
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data = await fetchProductList(); 
    setList(data);
    
    console.log({data})
  } 

  return (
    <Container>
      <TitleHeader title="스타나무"/>
      <Banner place={'odi_pay'}/>
      
      <div className='px-32 mt-28 mb-40'>
        <div className='w-full history_box'>
          <div className='flex-center px-10 py-10 grey_bottom_2'>
            <img className='odi_medium' src={ic_odi}/>
            <div className='ml-4 medium'>남은 스타</div>
            <div className='flex-1 ml-6 font-18 semibold'>{user?.currentBalance ?? 0}</div>

            <Link to='/charge/history/buy' className='c-grey100'>구매내역</Link>
          </div>
          {/* <div className='flex-center pl-16 pr-10 py-10'>
            <img className='odi_small' src={ic_odi}/>
            <div className='flex-1 ml-10 medium'>스타 자동충전</div>

            <Link to='/charge/auto'>신청하기</Link>
          </div> */}
        </div>
      </div>


      {
        list.map((item, idx) => (
          <OdiChargeItem
            {...item}
            key={`od-${idx}-${item.price}`}
          />
        ))
      }

      <div className='w-full px-20'>
        <div className='font-14 mt-10 medium'>충전한 스타의 유효기간은 충전일로부터 1년입니다.</div>
        <Link className='font-14 bold' to='/policy/service'>{'>'} 이용약관 환불규정</Link>
      </div>
    </Container>
  )
}



const OdiChargeItem = ({ title, quantity, price, product_id }) => {
  const navigate = useNavigate();

  return (
    <div className='grey_bottom_1 flex-center py-14 px-16'>
      <img className='odi_medium' src={ic_odi}/>
      
      <div className='font-16 ml-8 medium flex-1'>스타 {quantity}개</div>

      {/* 결제 페이지로 이동 */}
      <div className='price_box pointer flex-center medium' onClick={() => {
        navigate('/payment', { state: { title, quantity, price, product_id } });
      }}>
        <span className='bold font-14'>{formatNumber(price)}</span>원
      </div>
    </div>
  )
}

