import styled from 'styled-components'
import { Button, Modal } from '..'
import { getTheme } from '@/styles/theme'
import { fetchRentBenefitNovel } from '@/api/rent'
import { useNavigate } from 'react-router-dom'


const Container = styled.div`

`


export default function InsufficientBalanceModal ({
  isOpen,
  onClose,
}) {

  const navigate = useNavigate();

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose} height={170}>
        <div className='bold font-24 text-center ellipsis w-full'>
          <span className='c-primary100'>스타</span>가 부족합니다.
        </div>

        <div className='medium font-16 py-16 text-center ellipsis w-full'>
          
        </div>

        <div className='flex flex-center mt-20'>
          <div className='flex-1'>
            <Button background={getTheme('black')} style={{width: '100%'}} onClick={onClose}>
              <div className='flex align-end justify-center'>
                <div className='font-16 bold'>취소</div>
              </div>
            </Button>
          </div>

          <div className='flex-1 ml-8'>
            <Button background={getTheme('primary100')} style={{width: '100%'}} onClick={() => window.location.href = '/charge'}>
              <div className='font-18 bold'>충전하기</div>
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  )
}