import { getTheme } from '@/styles/theme';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  color: #212121;
  max-width: 460px;
  
  input {
    cursor: pointer;
  }
  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    width: 48px;
    height: 24px;
    border-radius: 18px;
    background: grey;
    transition: all 0.2s ease-out;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
  }

  input:checked[type='checkbox'] {
    background: ${getTheme('primary')};
  }

  input[type='checkbox']::before {
    content: '';
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background: white;
    position: relative;
    top: 0;
    left: 1px;
    float: left;
    transition: all 0.2s ease-out;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    transform: scale(0.9) translateY(0.5px);
  }
  input:checked[type='checkbox']::before {
    left: 23.5px;
  }
`;

function Switch({ label, defaultChecked, style, ...rest }) {
  return (
    <Container style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...style }}>
      {label && <div>{label}</div>}
      <input type='checkbox' defaultChecked={defaultChecked} {...rest} />
    </Container>
  );
}

export default Switch;
