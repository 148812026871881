// src/components/BirthdayPicker.jsx

import { getTheme } from '@/styles/theme';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DatePicker } from 'react-responsive-datepicker'
import 'react-responsive-datepicker/dist/index.css'
import ko from 'date-fns/locale/ko'; // 한국어 locale

import moment from 'moment';


const Container = styled.div`
  width: 100%;
  position: relative;


  .inputLabel {
    color: #2D2D2D;
  }

  input {
    height: 52px;
    border: 0;
    width: 100%;
    background-color: ${({ background }) =>  background};

    border: 1px solid #CCCCCC;
    border-radius: 8px;

    padding-left: 12px;

    color: ${getTheme('text')};
  }


  input:focus {
    border: 1px solid ${getTheme('primary')};
  }

  input::placeholder {
    color: rgba(0, 0, 0, 0.2);
  }
`


const BirthdayPicker = ({
  name, 
  required=false, 
  onChange, 
  label, 
  placeholder, 
  errorText='',
  background='#fff', 
  maxLength=30,
  readOnly=false,
  ...rest 
}) => {
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [day, setDay] = useState('');

  useEffect(() => {
    // year, month, day를 YYYY.MM.DD 형태로 포맷
    const formattedDate = `${year}.${month.padStart(2, '0')}.${day.padStart(2, '0')}`;

    // onChange에 포맷된 날짜 전달
    onChange(formattedDate);
  }, [year, month, day]);

  return (
    <>
      <Container background={background} readOnly={readOnly}>
        <div className={`font-18 mb-6 bold inputLabel`}>
          {label}{required && <span className='c-red'> *</span>}
        </div>
        <div className='w-full flex align-center'>
          
          {/* 연 */}
          <div className='flex-2'>
            <input
              className={`font-18`}
              placeholder={'2000'}
              name={name}
              onChange={(e) => {
                const { value } = e.target;
                if (/^\d{0,4}$/.test(value)) { // 숫자만 허용, 최대 4자리
                  setYear(value);
                }
              }}
              maxLength={4}
              {...rest}
              value={year}
            />
          </div>

          {/* 월 */}
          <div className='flex-1 mx-8'>
            <input
              className={`font-18`}
              placeholder={'01'}
              name={name}
              onChange={(e) => {
                const { value } = e.target;
                if (/^\d{0,2}$/.test(value)) { // 숫자만 허용, 최대 2자리
                  setMonth(value);
                }
              }}
              maxLength={2}
              {...rest}
              value={month}
            />
          </div>

          {/* 일 */}
          <div className='flex-1'>
            <input
              className={`font-18`}
              placeholder={'01'}
              name={name}
              onChange={(e) => {
                const { value } = e.target;
                if (/^\d{0,2}$/.test(value)) { // 숫자만 허용, 최대 2자리
                  setDay(value);
                }
              }}
              maxLength={2}
              {...rest}
              value={day}
            />
          </div>
          
        </div>
      </Container>
    </>
  );
};

export default BirthdayPicker;
