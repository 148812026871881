import { axiosAuth } from "@/lib/axios";

export const fetchProductList = async () => {
  const { data } = await axiosAuth.get('/product/user/list');
  return data.map(item => {
    return {
      title: item.title,
      price: item.price,
      quantity: item.amount,
      product_id: item._id
    }
  });  
}