import { ic_home, ic_setting } from "@/assets";
import { useClickOutside } from "@/hooks";
import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  z-index: 1001;
  top: 0;
  background-color: #fff;
  height: 60px;
  position: fixed;
  border-bottom: 1px solid #e7e6e7;

  .ic_home {
    width: 24px;
  }

  .settingIcon {
    width: 24px;
  }

  .flot_setting_menu {
    position: absolute;
    z-index: 1002;
    border: 2px solid #e7e6e7;
    border-bottom: 0;
    width: 160px;
    background-color: #fff;
    right: 16px;
    top: 32px;
  }
  
  .resultBox {
    width: 100%;
    padding: 8px 4px;
    background-color: #e9ecef;
    color: grey;
  }

  .gridItem {
    width: 100%;
    padding: 0 4px;
    height: 40px;
    border-bottom: 1px solid #e7e6e7;
  }

  .floating-menu-back {
    width: 100vw;
    height: calc(100vh - 1px);
    background-color: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1001;
  }
`

export default function EpisodeHeader ({novel_uuid, novel_title, episode_title, setFontSize}) {
  const [isOpen, setIsOpen] = useState(false);
  const floatingMenu = useRef(null);
  
  useClickOutside(floatingMenu, () => setIsOpen(false));

  function handleFontSize(num) {
    const beforeFontSize = localStorage.getItem('epub-font-size');
    
    if(!beforeFontSize || beforeFontSize != num) {
      localStorage.setItem('epub-font-size', num);
      setFontSize(num);
    }
    setIsOpen(false);
  }

  return (
    <Container className='w-full flex-center'>
      {isOpen && 
        <>
          <div className="floating-menu-back" onClick={() => setIsOpen(false)}></div>
          <div className="flot_setting_menu" ref={floatingMenu}>
            <div className="resultBox medium">폰트 크기 조절</div>
            <div onClick={() => handleFontSize(22)} className="gridItem pointer flex align-center medium font-22">크게</div>
            <div onClick={() => handleFontSize(18)} className="gridItem pointer flex align-center medium font-18">중간</div>
            <div onClick={() => handleFontSize(16)} className="gridItem pointer flex align-center medium font-16">작게</div>
          </div>
        </>
      }


      <div className='w-full h-full flex-center px-16'>
        <div className='w-full flex align-center flex-1'>
          <Link to={`/novel/detail/${novel_uuid}`} className='mr-8' replace={true}>
            <img className='ic_home' src={ic_home}/>
          </Link>

          <div className='ellipsis-1'>
            <span className='bold font-14'>{novel_title} - </span>
            <span className='medium font-12'>{episode_title}</span>
          </div>
        </div>

        <img className="settingIcon pointer" src={ic_setting} onClick={() => setIsOpen(true)}/>
      </div>
    </Container>
  )
}