import { fetchBannerListWithPlace } from '@/api/banner';
import { formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const CarouselItem = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const OuterContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
`;

const place = "sub";

export default function AD() {
  const [list, setList] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data = await fetchBannerListWithPlace(place);
    setList(data);
  }

  const settings = {
    dots: false,           // 하단 네비게이션 점 표시
    infinite: true,       // 무한 반복
    speed: 500,           // 슬라이드 전환 속도
    slidesToShow: 1,      // 한 번에 보여줄 슬라이드 수
    slidesToScroll: 1,    // 한 번에 스크롤할 슬라이드 수
    autoplay: true,       // 자동 재생
    autoplaySpeed: 5000,  // 자동 재생 속도
    arrows: true,         // 좌우 화살표 표시 여부
  };

  return (
    <OuterContainer>
      <Slider {...settings}>
        {list.map((item, index) => (
          <CarouselItem key={index} onClick={() => {
            if(item?.link) {
              window.location.href = item.link;
            } else if(item?.outLink) {
              window.open(item.outLink);
            }
          }}>
            <img src={formatImageUri(item?.post_image_uri)} alt={`Banner ${index}`} />
          </CarouselItem>
        ))}
      </Slider>
    </OuterContainer>
  );
}
