import { GridBox } from '@/components';
import Banner from '@/components/Banner';
import NovelItem from '@/components/NovelItem';
import StalierGenreSelector from '@/components/StalierGenreSelector';
import StalierItem from '@/components/StalierItem';
import { useInfiniteScroll } from '@/hooks';
import { useStalierList } from '@/stores/stalierList';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 134px 0;
  
  .no-data {
    width: 100%;
    height: 300px;
  }
`


export default function Page () {
  const { stalierList, goToNextPage, hasMore, isLoading, page } = useStalierList();

  const [searchParams, setSearchParams] = useSearchParams();

  const genreParam = searchParams.get('genre');
  const scrollEnd = useRef();

  useInfiniteScroll(() => goToNextPage(), scrollEnd);

  // [TODO] 로딩 프로세스
  return (
    <Container>
      <StalierGenreSelector/>

      <div className='mt-20'/>
      {genreParam === '추천' && 
        <>
          <Banner place={'stalier'}/>
          
          <div className='pl-16 font-20 black mt-40'>
            <span>신인작가들의 </span>
            <span className='c-primary'>도전, 스텔리에!</span>
          </div>
          
        </>
      }
      

      {genreParam !== '추천' && 
        <>
          <div className='pl-16 font-20 black'>
            <span>스텔리에 - </span>
            <span className='c-primary'>{genreParam}</span>
          </div>
        </>
      }


      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          {stalierList?.map((item, key) => 
            <StalierItem {...item} key={key}/>
          )}
        </GridBox>
      
        {(page > 1  && hasMore) && <div ref={scrollEnd} />}

        {stalierList?.length === 0 && 
          <div className='no-data text-center flex-center flex-col'>
            <div className='bold font-20'>작품이 아직 없습니다.</div>
          </div>
        }
      </div>
    </Container>
  )
}