import { fetchOwnInquiryList } from '@/api/inquiry';
import { Button } from '@/components';
import { AccordionMenu2 } from '@/components/AccordionMenu2';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { formatDateYMD, formatDateYYMD } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 0 20px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .no-data {
    width: 100%;
    height: calc(80vh - 60px);
  }

  .inqu-item {
    width: 100%; 
    border-bottom: 1px solid #e7e6e7;
    padding: 10px 20px;

  }
`

export default function Page () {
  const [list, setList] = useState([]);
  
  useHideLayout('header', true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, [])

  async function getData() {
    const result = await fetchOwnInquiryList();

    setList(result);
  }


  return (
    <Container>
      <TitleHeader title="나의 문의내역"/>

      <div>
        {
          list?.map((item) => (
            <div className='inqu-item w-full flex-center' key={item._id}
              onClick={() => navigate(`/setting/customers/detail/${item._id}`)}
            >
              <div className='flex-1'>
                <div className='font-18 medium mb-4'>{item.title}</div>
                <div className='font-14 c-grey100'>{formatDateYMD(item.created_date)}</div>
              </div>

              <div className='bold font-14'>
                {item.hasResponsed ? "답변완료" : ""}
              </div>
            </div>
          ))
        }
      </div>

      
      {list?.length === 0 && 
        <div className='no-data text-center flex-center flex-col'>
          <div className='bold font-20'>문의하신 내역이 없습니다.</div>
        </div>
      }
      
      <div style={{ marginTop: 'auto' }} className='p-20'>
        <Button width="100%" onClick={() => navigate('/setting/customers/help')}>1:1 문의가 필요해요</Button>
      </div>
    </Container>
  )
}
