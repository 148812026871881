import { useEffect, useState } from 'react';

const useIOSCheck = () => {
  const varUA = navigator.userAgent.toLowerCase();
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
      setIsIOS(true);
    } else setIsIOS(false);
  }, [varUA]);

  return isIOS;
};

export default useIOSCheck;
