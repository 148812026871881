import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { fetchNovelDetailData, fetchNovelEpisodeListWithAuthCheck } from '@/api/novel';
import { fetchCertifiateRentBenefitNovel, fetchUserNovelRentList } from '@/api/rent';
import { fetchHasCheckLike } from '@/api/like';
import { fetchNovelPromotionList, fetchUserUseOdiPromotionCheck } from '@/api/promotion';
import { fetchHasCheckAlarm } from '@/api/alarm';
import { useUser } from './user';

export const novelDataState = atom({
  key: 'novelData',
  default: {},
});


export const episodeListState = atom({
  key: 'episodeListState',
  default: [],
});


export const rentBenefitsCertificate = atom({
  key: 'rentBenefitsCertificate',
  default: false,
});

export const timeFreeRentCertificateState = atom({
  key: 'timeFreeRentCertificate',
  default: false
})


export const RentTicketList = atom({
  key: 'rentTicketList',
  default: []
})

const hasNovelLiked = atom({
  key: 'hasNovelLiked',
  default: false,
});

const hasNovelAlarm = atom({
  key: 'hasNovelAlarm',
  default: false,
});


export const NovelPromotionListState = atom({
  key: 'NovelPromotionListState',
  default: []
})



export const useNovelData = (uid) => {
  const { refreshUserDataWithAdultCheck } = useUser();
    
  const [novelData, setNovelData] = useRecoilState(novelDataState);
  const [episodeList, setEpisodeList] = useRecoilState(episodeListState);

  const [rentTicketList, setRentTicketList] = useRecoilState(RentTicketList);
  const [benefitsCertificate, setBenefitsCertificate] = useRecoilState(rentBenefitsCertificate);
  const [timeFreeRentCertificate, setTimeFreeRentCertificate] = useRecoilState(timeFreeRentCertificateState);
  
  // 좋아요
  const [hasLiked, setHasLiked] = useRecoilState(hasNovelLiked);

  const [hasAlarm, setHasAlarm] = useRecoilState(hasNovelAlarm);

  const [promotionList, setPromotionList] = useRecoilState(NovelPromotionListState);

  const [likeCount, setLikeCount] = useState(0)
  
  const [isLoading, setIsLoading] = useState(false);

  // 타임딜 존재여부
  const [timedeal, setTimedeal] = useState(null);
  const [timeFreeRent, setTimeFreeRent] = useState(null);

  useEffect(() => {
    const getNovelData = async () => {
      try {
        const response = await fetchNovelDetailData(uid)
        setNovelData(response);
        setLikeCount(response?.likeCount);

        const user = await refreshUserDataWithAdultCheck();
        if(!user) {
          if(response?.ageGrade === "19세 이용가") {
            alert('성인 인증이 필요한 콘텐츠입니다.');
            return window.location.replace('/adultverification');
          }
          else return;
        };

        if(!user?.adultVerification && response?.ageGrade === "19세 이용가") {
          alert('성인 인증이 필요한 콘텐츠입니다.');
          return window.location.replace('/adultverification');
        } 

        // 신규 유저 혜택 대여권 발급 가능 여부
        if(response.rentBenefit !== 0) {
          const certificate_response = await fetchCertifiateRentBenefitNovel({
            novel_id: response._id, 
            benefitCount: response.rentBenefit,
            novel_uuid: uid,
          });
          setBenefitsCertificate(certificate_response)
        }
        else setBenefitsCertificate(false);


        // 대여권 리스트
        const rent_ticket_list = await fetchUserNovelRentList(response._id);
        setRentTicketList(rent_ticket_list)


        // 좋아요 체크
        const liked = await fetchHasCheckLike(uid);
        setHasLiked(liked);

        // 알람 체크
        const alarm = await fetchHasCheckAlarm(uid);
        setHasAlarm(alarm);

        


        // 프로모션 리스트
        const promotion_list = await fetchNovelPromotionList(response._id);
        
        setPromotionList(promotion_list);
        
        // 타임딜이 프로모션에 있는지
        const timedealCheck = promotion_list.find(item => item.promotionNumber === 0);
        if(timedealCheck) setTimedeal(timedealCheck);

        // 스확시간이 프로모션에 있는지
        const timeFreeRentCheck = promotion_list.find(item => item.promotionNumber === 1); // 스확시간, 혹은 매열무
        if(timeFreeRentCheck) { // 스확시간 존재 시
          const timeFreeRentCert = await fetchUserUseOdiPromotionCheck(timeFreeRentCheck._id);
          setTimeFreeRentCertificate(timeFreeRentCert)
          setTimeFreeRent(timeFreeRentCheck);
        }
      } catch (error) {
        console.error('Failed to fetch novel genre list:', error);
      } finally {
        setIsLoading(false);
      }
    }

    const getEpisodeList = async () => {
      try {
        setIsLoading(true);
        const response = await fetchNovelEpisodeListWithAuthCheck(uid);



        setEpisodeList(response);
      } catch (error) {
        console.error('Failed to fetch novel data:', error);
      } finally {
        setIsLoading(false);
      }
    }

    if(uid) {
      getNovelData();
      getEpisodeList();
    }
  }, [])

  
  const changeBenefitCertificate = async () => {
    setBenefitsCertificate(false);
  
    // 대여권 리스트
    const rent_ticket_list = await fetchUserNovelRentList(novelData._id);
    setRentTicketList(rent_ticket_list)
  }

  const pushRentTicketList = async (rent_ticket) => {
    setRentTicketList([...rentTicketList, rent_ticket])
  }

  
  const popRentTicektList = async (rent_ticket_id) => {
    let list = rentTicketList.filter(item => item._id !== rent_ticket_id)
    setRentTicketList(list)
  }


  const refreshTimeRentFreeCertificate = async () => {
    const timeFreeRentCert = await fetchUserUseOdiPromotionCheck(timeFreeRent._id);
    setTimeFreeRentCertificate(timeFreeRentCert)
  }


  return {
    likeCount,
    setLikeCount,
    novelData,
    hasLiked,
    setHasLiked,
    hasAlarm,
    setHasAlarm,
    timedeal,
    timeFreeRent,
    benefitsCertificate,
    changeBenefitCertificate,
    timeFreeRentCertificate,
    refreshTimeRentFreeCertificate,
    pushRentTicketList,
    popRentTicektList,
    rentTicketList,
    episodeList
  }
}