import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { fetchBannerListWithPlace } from '@/api/banner';
import { formatImageUri } from '@/utils/format';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const CarouselItem = styled.div`
  width: 100%;
  padding: 0 10px;
  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
  }
`;

const OuterContainer = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 0;
`;

export default function Banner({ place }) {
  const [list, setList] = useState([]);
  const [isDragging, setIsDragging] = useState(false); // 드래그 여부 상태

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const data = await fetchBannerListWithPlace(place);
    setList(data);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    beforeChange: () => setIsDragging(true), // 슬라이드가 바뀌기 전 드래그 시작
    afterChange: () => setIsDragging(false),  // 슬라이드가 바뀐 후 드래그 끝
  };

  const handleClick = (item) => {
    if (!isDragging) { // 드래그 중이 아닌 경우에만 onClick 실행
      if (item?.link) {
        window.location.href = item.link;
      } else if (item?.outLink) {
        window.open(item.outLink);
      }
    }
  };

  return (
    <OuterContainer>
      <Slider {...settings}>
        {list.map((item, index) => (
          <CarouselItem
            key={index}
            onClick={() => handleClick(item)}
          >
            <img src={formatImageUri(item?.post_image_uri)} alt={`Banner ${index}`} />
          </CarouselItem>
        ))}
      </Slider>
    </OuterContainer>
  );
}
