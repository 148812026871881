import styled from 'styled-components'
import { Button, Modal } from '..'
import { getTheme } from '@/styles/theme'


const Container = styled.div`

`


export default function NeedAuthModal ({
  isOpen,
  onClose,
}) {

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose} height={140}>
        <div className='pt-4 bold font-24 text-center ellipsis w-full mb-24'>
          로그인이 필요합니다.
        </div>
    

        <Button background={getTheme('primary100')} to='/login' style={{width: '100%'}}>3초만에 간단히 로그인</Button>
      </Modal>
    </Container>
  )
}