import { Link, NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'

import { ic_header_menu, ic_odi, ic_search, main_logo, sample_profile } from '@/assets'
import { GridBox } from '@/components'
import { useEffect, useState } from 'react'
import { getTheme } from '@/styles/theme'
import { useUser } from '@/stores'
import { formatImageUri } from '@/utils/format'
import SearchMenu from './SearchMenu'

const Container = styled.div`
  width: 100%;
  height: 52px;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 21;

  .innerHeader {
    width: 100%;
    background-color: #fff;
    padding: 0 16px;
    height: 100%;
  }

  
  .ic_search {
    width: 24px;
    height: 24px;
  }

  .active {
    color: ${getTheme('primary')};
    font-weight: 900;
  }

  .grey_line {
    background-color: #fff;
  }



  
  
  .header_menu {
    width: 80vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    overflow: scroll;
    z-index: 23;
    transition: .3s;
    background-color: #fff;
    
    
    .active {
      color: ${getTheme('primary')}
    }


    .logo_box {
      height: 80px;
    }

    .purple_line {
      width: 100%;
      height: 3px;
      background-color: ${getTheme('primary100')};
    }
  }

  .header_menu_close {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.6);
  }


  .history_box {
    border-radius: 8px;
    border: 2px solid #e1e1e1;
  }

  .odi_semismall {
    width: 23px;
  }
  .odi_small {
    width: 18px;
  }

  .grey_bottom_2 {
    border-bottom: 2px solid #e1e1e1;
  }

  .grey_bottom_1 {
    border-bottom: 1px solid #e1e1e1;
  }

  .price_box {
    border: 2px solid #000;
    width: 76px;
    height: 32px;
    border-radius: 8px;
  }

  .price_box_small {
    border: 2px solid #000;
    width: 52px;
    height: 24px;
    border-radius: 8px;
  }

  .profile_image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .c-4f4f4f {
    color: #4f4f4f;
  } 


  .header_menu_btn {
    img {
      width: 24px;
    }
    height: 24px;
    width: 24px;
  }

  .header_menu_site {
    a {
      margin-right: 4px;
      display: inline-block;
    }
  }

  .header_menu_footer {
    color: #838383;
    line-height: 13px;
  }
`

const StyledLink = styled(NavLink)`
  color: #5b5b5b;
`



export default function Header () {
  // [TODO] 유저 데이터 가져오는 방식 -> Recoil State로 변경

  const { user, userLogout } = useUser('header');

  
  const [isOpen, setIsOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const navigate = useNavigate();

  // useEffect(() => {
  //   if (isOpen || isSearchOpen) {
  //     // 모달이 열렸을 때 body 스크롤 막기
  //     document.body.style.overflow = 'hidden';
  //   } else {
  //     // 모달이 닫혔을 때 body 스크롤 복원
  //     document.body.style.overflow = '';
  //   }

  //   // 컴포넌트가 언마운트될 때도 스크롤 복원
  //   return () => {
  //     document.body.style.overflow = '';
  //   };
  // }, [isOpen, isSearchOpen]);


  const deviceType = localStorage.getItem('device');



  return (
    <Container className='' id="top-header">
      <div className='innerHeader relative flex-center'>
        <Link to ='/'>
          <img style={{width: '100px'}} src={main_logo}/>
        </Link>

        <div className='flex-1'></div>
        
        {/* <img className='ic_search mx-16' src={ic_search} onClick={() => alert('준비중입니다.')}/> */}
        
        <div className='header_menu_btn pointer flex-center mr-12'  onClick={() => {
          if(!user) return window.location.href = '/login';
          else setIsSearchOpen(true)
        }}>
          <img src={ic_search}/>
        </div>


        <div className='header_menu_btn pointer flex-center'  onClick={() => {
          if(!user) return window.location.href = '/login';
          else setIsOpen(true)
        }}>
          <img src={ic_header_menu}/>
        </div>

        {isSearchOpen && <SearchMenu onClose={() => setIsSearchOpen(false)}/>}



        {isOpen && <div className='header_menu_close' onClick={() => setIsOpen(false)}/>}

        <div className='header_menu flex flex-col px-20 py-16' style={{ 
          left: isOpen ? '20vw' : '100vw',
        }}>
          <div className='w-full logo_box flex-center'>
            <Link to ='/'>
              <img style={{width: '100px'}} src={main_logo}/>
            </Link>
          </div>


          <div className='w-full mb-20 profile_box flex-center px-14'>
            {/* <img src={user?.profileImage ? formatImageUri(user?.profileImage) : sample_profile} className='profile_image mr-8'/> */}
            
            <div className='flex flex-1 justify-center flex-col'>
              <div className='font-14 bold mb-2'>{user?.nickname ?? '닉네임'} 님</div>
              <div className='font-10 c-4f4f4f' >{user?.email ?? '이메일'}</div>
            </div>
            <Link to='/setting/user' className='font-12 semibold'>프로필 설정</Link>
          </div>

          <div className='w-full history_box'>
            <div className='flex-center px-10 py-10 grey_bottom_2'>
              <img className='odi_semismall' src={ic_odi}/>
              <div className='ml-2 medium font-12'>남은 스타</div>
              <div className='flex-1 ml-6 font-14 semibold'>{user?.currentBalance ?? 0}</div>
              <div className='price_box pointer font-12 flex-center semibold' onClick={() => {
                setIsOpen(false);
                window.location.href = '/charge'
              }}>충전하기</div>

            </div>
            {/* [TODO] 스타 자동충전 */}
            {/* <div className='flex-center pl-12 pr-10 py-10'>
              <img className='odi_small' src={ic_odi}/>
              <div className='flex-1 ml-6 font-12 medium'>스타 자동충전</div>
              <div className='price_box_small font-10 pointer flex-center semibold' onClick={() => {
                setIsOpen(false);
                navigate('/charge')
              }}>신청하기</div>
            </div> */}
          </div>

          <div className='purple_line mt-24 mb-16'/>
 
          <div className='header_menu_box flex-1'>
            <NavMenu title="이벤트" handle={() => navigate('/event')} setIsOpen={setIsOpen}/>
            <NavMenu title="공지사항" handle={() => navigate('/notice')} setIsOpen={setIsOpen}/>
            <NavMenu title="나의 서재" handle={() => navigate('/mystudy')} setIsOpen={setIsOpen}/>
            <NavMenu title="좋아요한 작품 목록" handle={() => navigate('/novel/liked')} setIsOpen={setIsOpen}/>
            <NavMenu title="관심 작품 목록" handle={() => navigate('/novel/alarm')} setIsOpen={setIsOpen}/>
            {(deviceType !== "IOS" && !user?.adultVerification) && <NavMenu title="성인인증" handle={() => navigate('/adultverification')} setIsOpen={setIsOpen}/>}
            <NavMenu title="쿠폰 등록" handle={() => navigate('/coupon/add')} setIsOpen={setIsOpen}/>
            <NavMenu  title="나의 스텔리에" handle={() => navigate('/stalier/own')} setIsOpen={setIsOpen}/>
          </div>

          <div className='purple_line mb-24'/>
          
          <NavMenu title="설정" handle={() => navigate('/setting')} setIsOpen={setIsOpen}/>
          <NavMenu title="로그아웃" handle={() => userLogout()} setIsOpen={setIsOpen}/>
  
          <div className='header_menu_site mt-8 bold font-10'>
            {/* <a href='https://uponati.com/'>회사소개</a> */}
            <Link to='/policy/service'>이용약관</Link>
            <a href='https://stella-71d54.web.app/'>개인정보처리방침</a>
            {/* <Link to='/'>청소년 보호정책</Link> */}
            {/* <Link to='/'>성인콘텐츠정책</Link> */}
          </div>

          <div className='header_menu_footer mt-16 font-10'>
            주식회사 스텔미디어<br/>
            대표이사: 김현성 | 사업자등록번호 : 835-86-03090<br/>
            전화: 070-8058-9207<br/>
            주소: 경기도 안산시 단원구 당곡로33, 102동 1202호(고잔동, 안산프라움씨티오피스텔)<br/>
          </div>
        </div>
      </div>

      <div className='w-full grey_line font-18' style={{marginTop: "-1px"}}>
        <div className='w-full flex flex-center px-20' style={{background: '#fff'}}>
          <StyledLink to='/' className='w-full flex-center bold py-12 flex-1'>홈</StyledLink>
          <StyledLink to='/rank' className='w-full flex-center bold py-12 flex-1'>랭킹</StyledLink>
          <StyledLink to='/stalier' className='w-full flex-center bold py-12 flex-1'>스텔리에</StyledLink>
        </div>
      </div>
    </Container>
  )
}



const NavMenu = ({ icon, title, handle, setIsOpen }) => {
  return (
    <div className='flex align-center mb-12'>
      {/* <img className='nav_icon' src={icon}/> */}
      <div className='pointer bold font-14' onClick={() => {
        setIsOpen(false);
        handle();
      }}>{title}</div>
    </div>        
  )
}