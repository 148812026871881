import { axiosAuth } from "@/lib/axios"

export const fetchCreateAlarm = async (novel_id, novel_uuid) => {
  try {
    await axiosAuth.post('/alarm', {
      novel: novel_id,
      novel_uuid
    })
  } catch (err) {
  }
}


export const fetchDeleteAlarm = async (novel_id, novel_uuid) => {
  try {
    await axiosAuth.post('/alarm/delete', {
      novel: novel_id,
      novel_uuid
    })
  } catch (err) {
  }
}


export const fetchHasCheckAlarm = async (novel_uuid) => {
  try {
    const { data } = await axiosAuth.get(`/alarm/check/${novel_uuid}`)
    return data;
  } catch (err) {
  }
}