import { axiosAuth } from "@/lib/axios";

export const fetchFileToUri = async (
  foldername,
  filename,
  file
) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('foldername', `${foldername}`);
  formData.append('filename', `${filename}.png`);
 
  const { data } = await axiosAuth.post(`/upload/single`, formData, {
    headers: { 'content-type': 'multipart/form-data' },
  });

  return data;
}
