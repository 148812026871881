import axios, { axiosAuth } from "@/lib/axios";

// 검색어 리스트 가져오기
export const fetchGetSearchWordList = async (text) => {
  const { data } = await axiosAuth.get(`/search/word?text=${text}`);
  return data;
}

// 검색어 리스트 가져오기
export const fetchGetSearchResultList = async (text, type, page) => {
  const replaceText = text.replace('[단행본] ', '');

  const { data } = await axiosAuth.get(`/search/result/${type}?text=${replaceText}&page=${page}`);
  return data;
}

