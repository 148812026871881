import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  .labelPlace {
    width: 274px;
    height: ${({height}) => height};
    background-color: #f5f5f5;
    padding: 15px 26px;
    border-right: 1px solid #E8E8E8;
  }

  border: 1px solid #E8E8E8;

  .boxPlace {
    padding: 0 26px;
  }

`


export default function LabelBox ({
  label,
  children,
  height='auto'
}) {

  return (
    <Container className='w-full flex-center' height={height}>
      <div className='labelPlace flex align-center medium'>{label}</div>

      <div className='flex-1 flex align-center boxPlace'>
        {children}
      </div>
    </Container>
  )
}