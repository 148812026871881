import { fetchGetSearchResultList } from '@/api/search';
import { ic_search_white } from '@/assets';
import { GridBox } from '@/components';
import NovelItem from '@/components/NovelItem';
import { useHideLayout, useInfiniteScroll } from '@/hooks';
import SearchMenu from '@/layouts/Header/SearchMenu';
import { useUser } from '@/stores';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 60px;

  .no-data {
    width: 100%;
    height: calc(80vh - 120px);
  }

  .searchBox {
    z-index: 99;
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    
    background-color: #000;
    position: fixed;
  }
  .typeGrid{
    z-index: 99;
    position: fixed;
    top: 60px;

  }
  
  .searchBar {
    input {
      width: 100%;
      background-color: rgba(255,255,255,0.3);
      padding-left: 12px;
      border: none;
      height: 40px;
      color: lightgrey;
      font-size: 18px;
      border-radius: 8px;

      ::placeholder {
        color: grey;
      }
    }
  }

  .header_menu_btn {
    img {
      width: 24px;
    }
    height: 24px;
    width: 24px;
  }

  .gridItem {
    height: 40px;
    width: 100%;
    background-color: #000;
  }
`


export default function Page () {
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const [totalCount, setTotalCount] = useState(0);

  const [list, setList] = useState([]);
  const type = searchParams.get('type');
  const text = searchParams.get('text');
  const scrollEnd = useRef();

  
  useInfiniteScroll(() => {
    if(page < total) {
      getData(type, text, page + 1);
      setPage(p => p + 1)
    }
  }, scrollEnd);

  useEffect(() => {
    if(type && text) {
      setList([]);
      getData(type, text, page);
    }
  }, [type, text]);


  async function getData(type, text, page) {
    const { list, total, totalCount } = await fetchGetSearchResultList(text, type, page);
    
    setList(li => [...li, ...list]);

    setTotal(total);
    setTotalCount(totalCount);
  }

  
  useHideLayout('header', true);

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const navigate = useNavigate();

  function handleType(type) {
    setSearchParams({ text, type }, { replace: true });
  }

  const { user } = useUser('search');


  return (
    <Container>
      <div className='searchBox flex align-center px-16'>
        <div className='flex-1 searchBar'>
          <input
            // type="search"
            onClick={() => setIsSearchOpen(true)}
            value={text}
            readOnly
            placeholder='작품명, 작가명, 출판사명 검색'
          />
        </div>
        
        <div className='header_menu_btn mx-12'>
          <img src={ic_search_white} onClick={() => setIsSearchOpen(true)}/>
        </div>

        <div className='c-white pointer font-20 medium' onClick={() => {
          const prev_path = localStorage.getItem('previousPath');

          if(prev_path) navigate(prev_path, { replace: true });
          else navigate(-1, { replace: true })
          // navigate(-1)
        }}>취소</div>
      </div>

      {isSearchOpen && <SearchMenu onClose={() => setIsSearchOpen(false)}/>}

      <div className='w-full flex-center typeGrid'>
        <div className='gridItem flex-1 flex-center font-16 c-white' onClick={() => handleType("NOVEL")}>작품</div>
        <div className='gridItem flex-1 flex-center font-16 c-white' onClick={() => handleType("WRITER")}>작가</div>
        <div className='gridItem flex-1 flex-center font-16 c-white' onClick={() => handleType("LABEL")}>출판사</div>
      </div>

      <div className='px-16 w-full pt-16'>
        
        {list?.length > 0 && 
          <div className='font-18 medium mb-12'>
            검색 결과 <span className='bold'>{totalCount}</span>개 </div>
        } 
        <div className='w-full'>
          <GridBox col={3} gap={20}>
            {list?.map((novel, key) => 
              <NovelItem {...novel} key={key} userAdultCheck={user?.adultVerification}/>
            )}
          </GridBox>
        
          {list.length < totalCount && <div ref={scrollEnd} />}
        </div>
        {list?.length === 0 && 
          <div className='no-data text-center flex-center flex-col'>
            <div className='bold font-20'>검색 결과가 없습니다.</div>
            <div className='font-18 mt-20'>다른 내용을 검색해보세요!</div>
          </div>
        }
      </div>

    </Container>
  )
}