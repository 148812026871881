import { fetchOdiChargeWithTOSSPayment } from '@/api/coin';
import Loading from '@/components/Loading';
import { useHideLayout } from '@/hooks'
import { useUser } from '@/stores';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  width: 100%;
  height: 100%;
`


export default function Page () {
  const { uid } = useParams(); // 상품 UID

  const { refreshUserData } = useUser();
  const [searchParams, ] = useSearchParams();

  // 결제 타입, 주문 고유 ID, 결제 Key, 금액
  const paymentType = searchParams.get('paymentType');
  const orderId = searchParams.get('orderId');
  const paymentKey = searchParams.get('paymentKey');
  const amount = searchParams.get('amount');

  useHideLayout('header', true);

  useEffect(() => {
    submit();
  }, [])

  async function submit() {
    try {
      const userData = (await refreshUserData());
      await fetchOdiChargeWithTOSSPayment({
        paymentKey, 
        amount, 
        orderId, 
        paymentType, 
        previousQuantity: userData?.currentBalance ?? 0,
        product_id: uid
      });

    } catch {
      alert('로그인 정보를 불러올 수 없었습니다.');
      return window.location.href = '/'
    }
  }



  return (
    <Container className='flex-center'>
      <Loading/>
    </Container>
  )
}