import Loading from '@/components/Loading';
import { useHideLayout } from '@/hooks';
import axiosInstance from '@/lib/axios';
import { useUser } from '@/stores';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { nanoid } from 'nanoid';


const Container = styled.div`
  width: 100vw;
  height: 99vh;
`;

export default function Page() {
  const location = useLocation();
  const hasFetched = useRef(false); // 요청 실행 여부를 추적하는 useRef
  const { userSocialSignin } = useUser();

  useEffect(() => {
    // URL에서 code와 state 쿼리 매개변수 추출
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const state = searchParams.get('state');

    // useEffect가 두 번 실행되는 경우를 방지
    if (code && state && !hasFetched.current) {
      // 네이버 토큰을 요청하는 함수 호출
      fetchUserInfo(code, state);
      hasFetched.current = true; // 요청이 실행되었음을 표시
    }
  }, [location.search]);

  const fetchUserInfo = async (code, state) => {
    try {
      // 서버로 POST 요청을 보내서 액세스 토큰을 교환
      const user_response = await axiosInstance.post('/userauth/social/naver', { code, state });
      const userInfo = user_response.data.response;

      let user_email = userInfo?.email;
      if(!userInfo?.email) user_email = nanoid(16);

      const response = await axiosInstance.post('/userauth/oauth', {
        email: user_email,
        socialId: userInfo.id,
        loginType: "NAVER",
        socialToken: userInfo.access_token
      });

      // response status 확인
      if(response.status === 201) {
        const result = await userSocialSignin(response.data.accessToken)
        if(result) window.location.href = '/';
        else alert('로그인에 실패했습니다.');
      }
      else if(response.status === 203) {
        localStorage.setItem('user_data', JSON.stringify({ email: user_email }))
        localStorage.setItem('user_access_token', response.data.accessToken)

        window.location.href = '/social/info';
      }
    } catch (error) {
      alert('로그인에 실패했습니다.');
      window.location.href = '/login';

      console.error('Error fetching user info:', error);
    }
  };

  useHideLayout('header', true);

  return (
    <Container className='flex-center'>
      <Loading/>
    </Container>
  );
}
