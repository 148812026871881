import { ic_next, ic_prev, ic_scroll_down_black } from '@/assets';
import EpisodeBody from '@/components/Episode/EpisodeBody';
import EpisodeHeader from '@/components/Episode/EpisodeHeader';
import { useHideLayout } from '@/hooks';
import { useEpisodeData } from '@/stores/episode';
import { formatEpubUri, formatImageUri } from '@/utils/format';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import ePub from 'epubjs';

import { useEffect, useRef, useState } from 'react';
import { fetchCreateView } from '@/api/view';
import { episodeListState, novelDataState, useNovelData } from '@/stores/novelData';
import { useRecoilState } from 'recoil';
import InsufficientBalanceModal from '@/components/Episode/InsufficientBalanceModal';
import TimeFreeRentModal from '@/components/Episode/TimeFreeRentModal';
import NovelRentBenefitModal from '@/components/Episode/NovelRentBenefitModal';
import EpisodePaymentModal from '@/components/Episode/EpisodePaymentModal';
import { fetchNovelEpisodeData } from '@/api/novel';

import moment from 'moment';
import { useUser } from '@/stores';
import { getTheme } from '@/styles/theme';
import useShallowScroll from '@/hooks/useShallowScroll';


const Container = styled.div`
  width: 100vw;
  height: 99.5vh;
  padding-top: 72px;
  padding-bottom: 72px;
  overflow: hidden;
  
  .epub-container-div {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
`


export default function Page () {

  const { uid } = useParams();

  const [episodeData, setEpisodeData] = useState(null);
  const navigate = useNavigate();

  // [TODO] 유저 데이터 가져오는 방식 -> Recoil State로 변경
  const { user } = useUser('episode_detail');

  
  const [selectedEpisode, setSelectedEpisode] = useState(null);

  // 중간 저장
  const [selected, setSelected] = useState(null);

  // 신규 유저 혜택 모달
  const [isBenefitModalOpen, setIsBenefitModalOpen] = useState(false);
  
  // 스확시간 모달
  const [isTimeFreeRentModalOpen, setIsTimeFreeRentModalOpen] = useState(false);

  // 소지금 부족 모달
  const [isInsufficientBalanceModalOpen, setIsInsufficientBalanceModalOpen] = useState(false);



  const [fontSize, setFontSize] = useState(localStorage.getItem('epub-font-size') || 18);
  

  const { 
    novelData, 
    episodeList, 
    rentTicketList, 
    benefitsCertificate,
    changeBenefitCertificate,
    timedeal, // 타임딜
    timeFreeRent, // 스확시간
    popRentTicektList,
    pushRentTicketList, // 대여권 리스트 추가
    refreshTimeRentFreeCertificate,
    timeFreeRentCertificate,
  } = useNovelData();

  console.log({episodeData})

  useHideLayout('header', true);
  const viewerRef = useRef(null);

  const [page, setPage] = useState(1); // 페이지 번호 상태 변수 추가
  const [total_page, set_total_page] = useState(0);

  useEffect(() => {
    if (episodeData) { // 단행본 전용.
      const url = formatEpubUri(episodeData?.novel_uuid, episodeData?.episode_index, episodeData?.version); // EPUB 파일의 경로

      const book = ePub(url);
      const rendition = book.renderTo(viewerRef.current, {
        width: '100%',
        height: '100%',
        allowScriptedContent: true
      });
      
      // 페이지네이션 없이 세로로 끊김없이 나열
      rendition.flow('scrolled-doc');

      rendition.themes.default({
        body: {
          'padding-left': '16px !important',
          'padding-right': '16px !important',
          'user-select': 'none',
          '-webkit-user-select': 'none',
          '-moz-user-select': 'none',
          '-ms-user-select': 'none',
          'user-drag': 'none',
          'font-size': `${Number(fontSize)}px`,
          '-webkit-user-drag': 'none',
          'box-sizing': 'border-box',
          'overflow': 'auto', // 추가: 콘텐츠가 잘리지 않도록
          '-webkit-overflow-scrolling': 'touch' // 사파리 부드러운 스크롤
        }
      });

      rendition.themes.select('custom');

      // 특정 페이지 렌더링
      book.ready.then(() => {
        const spineItems = book.spine.spineItems;
        // const nonCoverSections = spineItems.filter(
        //   item => 
        //     !item.href.includes('cover.html') && 
        //     !item.href.includes('cover.xhtml') && 
        //     !item.href.includes('titlepage.xhtml')
        // );
        
        
        const nonCoverSections = spineItems
        console.log({nonCoverSections, page})

        set_total_page(nonCoverSections.length)
        // 현재 페이지를 기반으로 렌더링할 섹션 선택
        const sectionToDisplay = nonCoverSections[page - 1]; // 페이지 번호는 1부터 시작한다고 가정

        if (sectionToDisplay) {
          rendition.display(sectionToDisplay.href);
        } else {
          // 유효하지 않은 페이지 번호인 경우 기본 섹션을 표시
          rendition.display(nonCoverSections[0]?.href || spineItems[0].href);
        }
      });

      return () => {
        if (rendition) {
          try {
            rendition.destroy();
          } catch (error) {
            console.error("Error destroying rendition:", error);
          }
        }
      };
    }
  }, [episodeData, page, fontSize]); // 페이지 번호가 바뀔 때마다 렌더링

          
  const location = useLocation();


  useEffect(() => {
    async function createView() {
      const viewState = location.state;

      // 조회
      await fetchCreateView(
        episodeData?.novel_uuid, 
        uid,
        
        viewState?.read_ticket_type,
        viewState?.read_permanent,
        viewState?.read_rent
      );
    }

    if(episodeData?.novel_uuid) createView();
  }, [episodeData])


  useEffect(() => {
    async function getData() {
      const response = await fetchNovelEpisodeData(uid);
      setEpisodeData(response);
    }
    getData();
  }, [uid])

  
  function submit_E(next_uid) {
    const next_episode = episodeList?.find(item => item.uuid === next_uid);
    openEpisode(next_episode)
  }


  function openEpisode(episode_data) {
    const { salesStatus, hasTimedeal } = episode_data;

    // 유저가 없다면
    if(!user) return window.location.href = '/';

    if(salesStatus === "무료") {
      // 무료의 경우 넘기기
      if(hasTimedeal) // 타임딜 체크
        navigate(`/episode/${episode_data.uuid}`, { state: { read_ticket_type: "TIMEDEAL" }});
      else // 무료 처리
        navigate(`/episode/${episode_data.uuid}`, { state: { read_ticket_type: "FREE" }});
      return;
    }
    // 무료가 아닐 경우
    else {
      // 소장중일 경우, 바로 조회로 넘김.
      if(salesStatus === "소장중") {
        navigate(`/episode/${episode_data.uuid}`, { state: { read_ticket_type: "PERMANENT", read_permanent: episode_data.permanent_id }});
        return;
      }
      // 대여중일 경우
      if(salesStatus === "대여중") {
        const now = moment(); // 현재 시간
        const expireAt = moment(episode_data.rentExpire); // 만료 시간
          
        // 대여권 만료인지 계산
        // 만료일 경우
        if(expireAt.isBefore(now)) {} // 아래로 넘겨 결제 처리로
        // 만료가 아닐 경우
        else {
          navigate(`/episode/${episode_data.uuid}`, { state: { read_ticket_type: "RENT", read_rent: episode_data.rent_id }});
          return;
        }
      }

      // 무료가 아니며, 소장중이 아니며, 대여중이 아니거나 대여가 만료되었을 경우

      // 신규 독자 rent 베네핏 처리
      if(benefitsCertificate) {
        setIsBenefitModalOpen(true);
        setSelected(episode_data);
        return;
      }

      submitPayment(episode_data);
      return;
    }
  }

  async function submitPayment(episode_data) {
    // 스확시간가 존재하며
    if(timeFreeRent) {
      //  스확시간가 사용중이 아닐때
      if(!timeFreeRentCertificate) {
        setIsTimeFreeRentModalOpen(true);
        setSelected(episode_data);
      }
      // 스확시간 적용중일 때
      else {
        // 대여권을 발급할 수 있을 경우
        if(timeFreeRentCertificate?.timeRentFreeActive) {
          // 대여권 발급
          const ticket = await fetchGetPromotionRentTicket(
            timeFreeRent?.target_novel,
            timeFreeRentCertificate?.target_coin_promotion,
            timeFreeRent._id,
            timeFreeRent?.title,
          );

          pushRentTicketList(ticket);
          refreshTimeRentFreeCertificate();
          setSelectedEpisode(episode_data);
        }
        // 대여권을 발급할 수 없는 경우
        else setSelectedEpisode(episode_data);
      }
    }
  
    // 결제 팝업 ( 대여인지 아닌지 )
    else setSelectedEpisode(episode_data);
  }

  
  useEffect(() => {
    setPage(1);
  }, [uid]);

  function navigatgeItem(uuid, state) {
    // if(isAudioMode) navigate(`/audiobook/${uuid}`, state);
    // else navigate(`/episode/${uuid}`, state);
    navigate(`/episode/${uuid}`, state);
  }

   // 페이지 변경 핸들러 예시
   const goToNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const goToPreviousPage = () => {
    setPage(prevPage => Math.max(prevPage - 1, 1)); // 최소 1 페이지로 유지
  };


  const { isScrollingUp, isBottom, isTop } = useShallowScroll();

  return (
    <Container className='block-draggable relative flex-col'>
      <EpisodeHeader
        novel_uuid={episodeData?.novel_uuid}
        novel_title={episodeData?.novel?.title}
        episode_title={episodeData?.title}
        setFontSize={setFontSize}
      />

      <EpisodePaymentModal
        isOpen={!!selectedEpisode}
        onClose={() => setSelectedEpisode(null)}
        rentTicketList={rentTicketList}
        episode_data={selectedEpisode}
        user={user}
        popRentTicektList={popRentTicektList}
        navigatgeItem={navigatgeItem}
        // 재화 부족
        openEnoughModal={() => setIsInsufficientBalanceModalOpen(true)}
      />


      <NovelRentBenefitModal
        isOpen={isBenefitModalOpen}
        onClose={() => {
          setIsBenefitModalOpen(false);
          if(selected) submitPayment(selected);
        }}
        changeBenefitCertificate={changeBenefitCertificate}
        novelData={novelData}
      />

      {/* 스확시간 모달 */}
      <TimeFreeRentModal
        isOpen={isTimeFreeRentModalOpen}
        onClose={() => {
          setSelectedEpisode(selected);
          setIsTimeFreeRentModalOpen(false);
        }}
        novelData={novelData}
        timeFreeRent={timeFreeRent}
        pushRentTicketList={pushRentTicketList}
      />


      {/* 잔액부족 모달 */}
      <InsufficientBalanceModal
        isOpen={isInsufficientBalanceModalOpen}
        onClose={() => setIsInsufficientBalanceModalOpen(false)}
      />

      <div className='block-draggable epub-container-div flex-1' ref={viewerRef}></div>
        
       
      <ShallowContainer className='mt-20 w-full flex-center px-20'> 
        {episodeData?.prev_uid && <PreviousButton title="이전화" submit={() => submit_E(episodeData?.prev_uid)} />}
        {(episodeData?.prev_uid) && <div className='mr-16'/>}
        {page !== 1 && <PreviousButton title="이전챕터" submit={goToPreviousPage} />}

        <div className='flex-1'/>

        {page < total_page && <NextButton title="다음챕터" submit={goToNextPage} />}
        {(episodeData?.next_uid) && <div className='mr-16'/>}
        {episodeData?.next_uid && <NextButton title="다음화" submit={() => submit_E(episodeData?.next_uid)} />}
      </ShallowContainer>
    </Container>
  )
}


const ShallowContainer = styled.div`
  position: fixed;
  bottom: 0;
  background-color: #fff;
  height: 64px;
  width: 100%;
  border-top: 1px solid #e7e6e7;
  z-index: 1000; /* 필요에 따라 바텀 바를 위로 올리기 */
`



const PreviousButton = ({submit, title=""}) => {
  return (
    <div className='flex-col align-center' onClick={submit}>
      <img style={{width: '24px'}} src={ic_prev}/>
      <div className='font-14 bold mt-4'>{title}</div>
    </div>
  )
}


const NextButton = ({submit, title=""}) => {
  return (
    <div className='flex-col align-center' onClick={() => submit()}>
      <img style={{width: '24px'}} src={ic_next}/>
      <div className='font-14 bold mt-4'>{title}</div>
    </div>
  )
}