import { axiosAuth } from "@/lib/axios";


export const fetchCreateView = async (
  novel_uuid, 
  episode_uuid,
  read_ticket_type="FREE",
  read_permanent=null,
  read_rent=null
) => { 
  return await axiosAuth.post('/view', {
    novel_uuid, 
    episode_uuid,
    read_ticket_type,
    read_permanent,
    read_rent
  })
}



export const fetchCreateStalierView = async (
  stalier_uuid, 
  stalier_episode_uuid,
) => { 
  return await axiosAuth.post('/stalierview', {
    stalier_uuid, 
    stalier_episode_uuid,
  })
}



export const fetchNovelLastViewList = async () => {
  const deviceType = localStorage.getItem('device');

  let uri = '/novel/last/view/list'
  if(deviceType === "IOS") uri = '/novel/last/view/list?device=IOS';

  const { data } = await axiosAuth.get(uri);
  return data;
}




export const fetchNovelStudyList = async (page) => {
  const { data } = await axiosAuth.get(`/view/study/${page}`);
  return data;
}