import { axiosAuth } from "@/lib/axios"

export const fetchUpdateNotificationSetting = async (
  type,
  value
) => {
  const data = await axiosAuth.put(`/user/notifi/update/${type}`, {
    ...value
  });

  return data;
}