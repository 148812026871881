import axio from 'axios';

const context = import.meta.env.VITE_APP_BASE_URL;

const axiosInstance = axio.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
  },
  baseURL: context,
});

const axiosAuth = axio.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST',
    'Access-Control-Allow-Headers': 'Content-Type',
    'Content-Type': 'application/json',
  },
  baseURL: context,
})

axiosAuth.interceptors.request.use(config => {
  console.log(config)
  const token = localStorage.getItem('user_access_token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {


  console.error(error)
  Promise.reject(error)
})

axiosAuth.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response && error.response.status === 401) {
      // 401 에러 발생 시, 토큰 갱신 요청
      const result = await axio.post(`${context}/userauth/refresh`, {}, {
        withCredentials: true, // refreshToken을 포함한 쿠키 전송
      });

      console.log("토큰 갱신 요청 결과", result);

      if (result.status === 201 && result.data?.status !== 404) {
        console.log('refresh result', result);

        // 새로 받은 accessToken을 로컬 스토리지에 저장
        localStorage.setItem('user_access_token', result.data.accessToken);

        // 원래의 요청에 새 토큰 설정 후 재시도
        error.config.headers.Authorization = `Bearer ${result.data.accessToken}`;
        return axiosAuth.request(error.config);
      } else {
        console.error('토큰 갱신 실패', result);
        localStorage.removeItem('user_data');
        localStorage.removeItem('user_access_token');

        if(window.location.pathname !== '/login' && window.location.pathname !== '/login/')
          window.location.href = '/login';
      }
    }

    return Promise.reject(error);
  }
);


export default axiosInstance;

export { context, axiosAuth };
