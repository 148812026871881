import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Header from './Header';
import { Navigate, useLocation } from 'react-router-dom';


const AuthWhiteList = ['/login']

const Wrapper = ({children}) => {
  const isAuthenticated = localStorage.getItem('user_access_token');
  const location = useLocation();
  const authentication = !AuthWhiteList.includes(location.pathname);
  
  const excludePattern = /^\/(episode|novel\/detail|stalier\/detail|stalier\/episode)\//;

  const searchPattern = /^\/search$/;


  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);

    const currentPath = location.pathname;
    const currentPathWithQuery = `${location.pathname}${location.search}`;
    if (!excludePattern.test(currentPath) && !searchPattern.test(currentPath)) {
      localStorage.setItem("previousPath", currentPathWithQuery);
    }
  }, [location.pathname]);

  if(authentication) {
    // 인증이 반드시 필요한 페이지
    return isAuthenticated === null ? <Navigate to='/login'/> : children;  
  } else {
    // 인증이 반드시 필요 없는 페이지
    return isAuthenticated === null ? children : <Navigate to="/"/>;
  }
} 


const Container = styled.div`
`

const InnerContainer = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  min-height: 100vh;

  .main_section {
    width: 100%;
  }
`

function LayoutContainer({ children }) {
  const isAuthenticated = localStorage.getItem('user_access_token');

  return (
    <>
      {/* 로그인 시 나오는 레이아웃 */}
      {isAuthenticated && 
        <Container id="#wrap" className='flex-center'>
          <Wrapper>
            <Header/>

            <InnerContainer id='inner_container'>

              <div className='main_section flex-1'>
                {children}
              </div>
            </InnerContainer>

          </Wrapper>
        </Container>
      }
      
      {/* 로그인 없을 시 나오는 레이아웃 */}
      {!isAuthenticated && 
        <Container id="#wrap" className='flex-center'>
          <Header/>

          <InnerContainer id='inner_container'>

            <div className='main_section flex-1'>
              {children}
            </div>
          </InnerContainer>

        </Container>
      }
    </>
  );
}

export default LayoutContainer;
