import { fetchUpdateNotificationSetting } from '@/api/notification';
import Switch from '@/components/Switch';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useUser } from '@/stores';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 20px;

`

export default function Page () {

  useHideLayout('header', true);

  const navigate = useNavigate();


  const { user, refreshUserData } = useUser('notification')

  const [isLoading, setIsLoading] = useState({
    notification_event: false,
    notification_event_night: false,
    notification_novel_update: false,
    notification_novel_update_like: false,
    notification_promotion_everyday: false,
  });

  console.log(user)

  async function submit(type, value) {
    if(isLoading[type]) return;
    setIsLoading({ ...isLoading, [type]: true });

    try {
      let json = {}
      json[type] = value;
      await fetchUpdateNotificationSetting(type, json);
      await refreshUserData();
    } catch (e) {
      alert('알림 설정 변경에 실패했습니다.');
    } finally {
      setIsLoading({ ...isLoading, [type]: false });
    }
  }


  return (
    <Container>
      <TitleHeader title={"알림 설정"}/>
      
      <div className='nav flex align-center mb-32 w-full'>
        <div className='semibold font-18 flex-1'>이벤트 </div>
        <Switch defaultChecked={user?.notification_event} onChange={e => submit('notification_event', e.target.checked)}/>
      </div>
      
      <div className='nav flex align-center mb-32 w-full'>
        <div className='semibold font-18 flex-1'>야간 알림 수신</div>
        <Switch defaultChecked={user?.notification_event_night} onChange={e => submit('notification_event_night', e.target.checked)}/>
      </div>

      
      <div className='nav flex align-center mb-32 w-full'>
        <div className='semibold font-18 flex-1'>관심 작품 업데이트</div>
        <Switch defaultChecked={user?.notification_novel_update} onChange={e => submit('notification_novel_update', e.target.checked)}/>
      </div>

{/*       
      <div className='nav flex align-center mb-32 w-full'>
        <div className='semibold font-18 flex-1'>관심작품 정보</div>
        <Switch defaultChecked={user.notification_novel_update_like} onChange={e => submit('notification_novel_update_like', e.target.checked)}/>
      </div> */}

      
      <div className='nav flex align-center mb-32 w-full'>
        <div className='semibold font-18 flex-1'>스확시간 이용권 지급</div>
        <Switch defaultChecked={user?.notification_promotion_everyday} onChange={e => submit('notification_promotion_everyday', e.target.checked)}/>
      </div>
    </Container>
  )
}