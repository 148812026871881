import { axiosAuth } from "@/lib/axios"

export const fetchNewPermanentList = async (target_novel, target_episode, amount, previousQuantity, expense_list_sales_promo) => {
  try {
    await axiosAuth.post('/permanent/list', {
      target_novel, // 대상 소설
      target_episode, // 대상 회차
      amount,
      previousQuantity,
      expense_list_sales_promo, // 선택구매 혜택
      // [TODO] 선택구매 할인 프로모션 적용이나, 현재는 사용하지 않음
      expense_promotion: null
    })
  
    alert('소장권을 구매했습니다.')
  } catch (err) {
    alert('구매에 실패했습니다.')
    console.log(err);
  }
}
