import { checkbox_check } from '@/assets/components';
import { getTheme } from '@/styles/theme';
import styled from 'styled-components'


const Container = styled.div`


  .checkbox-out {
    width: 20px;
    height: 20px;
    border-radius: 4px;

    border: 2px solid ${({selected}) => !selected ? '#c3c3c3' : getTheme('primary100')};
    
    padding-top: 2px;
    img {
      width: 100%;
    }
  }
`

const CheckBox = ({
  label,
  selected=false,
  onClick
}) => {

  return (
    <Container className='flex align-center pointer' selected={selected} onClick={onClick}>
      <div className='checkbox-out flex-center'>
        {selected && <img className='checked-item' src={checkbox_check}/>}
      </div>

      <div className='ml-4 font-14 medium'>{label}</div>
    </Container>
  )
}

export default CheckBox