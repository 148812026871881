import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader'
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 0;

  .policy-text {
    line-height: 28px;
  }
`


export default function Page () {

  useHideLayout('header', true)
  return (
    <Container>
      <TitleHeader/>

      <div className='w-full px-20'>
        <div className='exbold font-30 text-center mb-60'>서비스 이용약관</div>

        <div className='pre-wrap policy-text'>
{`스텔라 이용약관


제 1 조 (목적)

본 약관은 주식회사 스텔미디어(이하 “회사”라 합니다)가 제공하는 “스텔라” 웹사이트, 앱, 기타 향후 개발되는 콘텐츠 등 정보 접근의 도구로 사용되는 여하한 모든 형태(이하 “플랫폼”이라 합니다.)를 통하여 제공하는 콘텐츠 등 서비스(이하 “서비스”라 합니다)의 이용과 관련하여 회사와 회원 간의 권리와 의무, 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.


제 2 조 (용어의 정의)

① 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
  1. “서비스”라 함은 “회사”가 “회원”에게 유·무선 접근기기를 통해 제공하는 콘텐츠 및 관련 정보와 소프트웨어 등(이하 “콘텐츠 등”이라 합니다.)을 의미합니다.
  2. “회원”은 본 이용약관에 따라 가입한 이용자로서 본 약관 및 개인정보 제공에 동의하고 회사로부터 서비스 이용 자격을 부여받아 서비스를 이용하는 자를 말합니다.
  3. “콘텐츠 등”이라 함은 디지털 방식으로 제작, 처리되어 회원에게 서비스되는 내용물 일체(글, 그림, 사진, 음향, 동영상 및 각종 파일과 여하한 형태의 디지털콘텐츠 및 제반 서비스)를 말합니다.
  4. “접근기기”라 함은 PC, 휴대폰, 태블릿 등 회사의 서비스에 접근하는 도구로 사용되는 여하한 형태의 기계적 유형물이 포함됩니다.
  5. “유료서비스”는 “회사”가 유료로 제공하는 제1호의 서비스를 의미합니다.
  6. “스타”라 함은 회원이 유료서비스 이용 시 사용할 수 있도록 회사가 내용과 조건을 정하여 제공하는 전자적 지급 수단을 의미합니다. 
  7. “정액권”은 일정 기간 동안 회사가 정한 내용의 조건을 충족하는 서비스를 자유롭게 이용할 수 있는 상품을 의미합니다.
  8. “쿠폰”, “이용권”, “포인트” 등(이하 “쿠폰 등”이라 합니다.)은 회사가 회원에게 마케팅이나 프로모션의 목적으로 무상으로 제공하는 전자적 지급수단을 의미합니다. 회사가 무상으로 지급한 포인트 등은 회사의 정책에 따라 소멸될 수 있습니다.
② 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 것은 개인정보 처리방침, 청소년보호정책, 무료작품 게시약관, 개별약관 등(본 약관에서 정하는 바에 따름)에서 정하는 바에 따르며, 그 외에는 관계 법령과 일반 상관례에 따릅니다.


제 3 조 (약관의 명시 및 개정 등)

① 회사는 이용자가 별도의 연결화면을 통하여 본 약관의 내용, 개인정보 처리방침, 상호, 대표자 성명, 주소, 전화번호, 전자우편주소, 사업자등록번호, 통신판매업 신고번호, 고객센터 등을 확인할 수 있도록 웹사이트 첫 화면 또는 팝업화면 등 플랫폼상 적절한 위치에 게시합니다.
② 회사는 약관의규제에관한법률, 정보통신망이용촉진및정보보호등에관한법률, 전자상거래등에서의소비자보호에관한법률 등 관계 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
③ 회사가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여, 그 개정약관의 적용일자 7일 전부터 적용일자 전일까지 공지합니다. 다만, 회원에게 현저히 불리한 약관 개정의 경우에는 적용일자 30일 전에 공지하고 회원이 등록한 전자우편으로 통지합니다.
④ 회사가 개정약관을 공지 또는 통지한 후 적용일자 전에 거부의 의사표시를 하지 않으면 약관 개정에 동의한 것으로 간주하며, 변경된 약관에 대한 정보를 알지 못하여 발생하는 회원의 피해에 대해 회사는 책임을 지지 않습니다.
⑤ 개정된 약관에 동의하지 않는 회원은 회원탈퇴를 요청할 수 있습니다.
⑥ 본 조 제1항 내지 제5항에서 정한 바에도 불구하고, 단순한 오기의 수정에 불구한 경우 회사는 별도의 개정 절차 없이 오기만을 수정할 수 있습니다.


제 4 조 (약관의 해석)

① 회사는 본 약관 외에 별도의 이용약관 및 정책(이하 “개별약관 등”)을 둘 수 있으며, 해당 내용이 본 약관과 상충할 경우에는 개별약관 등이 우선하여 적용됩니다.
② 본 약관에서 명시하지 않은 사항은 관계 법령 또는 상관례에 따릅니다.
③ 회사가 콘텐츠 공급자로부터 이용 또는 사용을 허락 받아 회원의 이용에 제공하는 유료 서비스에 대해서는 콘텐츠 공급자의 국적 국가의 법 및 조약이 적용될 수 있습니다.


제 5 조 (회사의 의무)

① 회사는 법령과 본 약관에 반하는 행위를 하지 않으며, 본 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하는 데 최선을 다합니다.
② 회사는 회원이 안전하게 서비스를 이용할 수 있도록 회사의 개인정보 보호정책을 마련, 준수하고 회원의 개인정보보호를 위한 보안 시스템을 갖추어 회원의 개인정보보호에 최선을 다합니다.
③ 회사는 광고, 마케팅 정보 수신에 동의하지 않은 회원에게 영리 목적의 광고, 마케팅 정보를 발송하지 않습니다.


제 6 조 (회원가입 및 이용계약)

① 이용계약은 “회원”이 되고자 하는 자(이하 “가입신청자”라고 합니다.)가 약관의 내용에 대하여 동의를 한 다음 회원가입신청을 하고, 회사가 이러한 신청에 대하여 승낙함으로써 체결됩니다.
② 회사는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다. 다만, 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니하거나, 회원의 귀책사유로 인하여 승인이 불가능한 경우, 실명이 아니거나 타인의 명의를 이용한 경우, 회원 자격을 상실하는 경우, 기타 이 약관의 제반 사항을 위반한 경우 등에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
③ 제1항에 따른 신청에 있어 회사는 전문기관을 통한 실명확인 및 본인인증을 요청할 수 있습니다.
④ 회사는 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수 있습니다.
⑤ 이용계약의 성립 시기는 회사가 가입완료를 신청절차 상에서 표시한 시점으로 정하고, 유료서비스 이용계약의 경우는 구매완료를 신청절차 상에서 표시한 시점으로 정합니다.
⑥ 회사는 회원에 대해 회사의 정책에 따라 등급별로 구분하여 이용시간, 이용회수, 서비스 메뉴 등을 세분하여 이용에 차등을 둘 수 있습니다.
⑦ 회사는 회원에 대하여 청소년보호법, 영화및비디오물의진흥에관한법률 등에 따른 등급 및 연령 준수를 위해 이용제한이나 등급별 제한을 할 수 있고, 성인인증 절차를 거치도록 할 수 있습니다.
⑧ 회원이 회사의 유료서비스를 이용하기 위해서는 본 약관 및 해당 유료서비스에 대한 개별약관 등에 동의 후 유료서비스의 이용조건에 따라 이용요금을 지급하여야 합니다.
⑨ 만 19세 미만의 미성년 회원이 유료서비스를 이용하고자 하는 경우에 부모 등 법정대리인의 동의를 얻거나, 계약체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있습니다.


제 7 조 (회원정보의 변경)

① 회원은 개인정보관리화면을 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 실명, 주민등록번호, 아이디 등은 수정할 수 없습니다.
② 회원은 회원가입신청 시 기재한 사항이 변경되었을 경우 온라인으로 수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알리고 수정을 요청하여야 합니다.
③ 전항의 조치를 취하지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.


제 8 조 (개인정보보호 의무)

회사는 개인정보보호법 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 사용에 대해서는 관련법 및 회사의 개인정보 처리방침이 적용됩니다. 다만, 회사의 공식 사이트 및 앱 이외의 링크된 사이트에서는 회사의 개인정보 처리방침이 적용되지 않습니다.


제 9 조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)

① 회사의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
② 회사는 회원의 아이디가 개인정보 유출 우려가 있거나, 반사회적 또는 미풍양속에 어긋나거나 회사 및 서비스의 운영자로 오인될 우려가 있는 경우, 직접 해당 아이디의 이용을 제한할 수 있습니다.
③ 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 안내에 따라야 합니다.
④ 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.


제 10 조 (회원에 대한 통지)

① 회사가 회원에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 가입 시  기입한 전자우편, 서비스 내 알림, 로그인 시 팝업화면 등으로 할 수 있습니다.
② 회사는 회원 전체에 대한 통지의 경우 7일 이상 서비스 내에 게시함으로써 전항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 전항의 통지를 합니다.


제 11 조 (회원의 의무)

① 회원은 다음 행위를 하여서는 안 된다.
  1. 신청 또는 변경 시 허위내용의 등록
  2. 타인의 정보도용
  3. 회사가 게시한 정보의 변경
  4. 회사가 회원에게 제공하는 콘텐츠 등을 회사의 사전 승낙 없이 복제, 크롤링, 스크래핑 등과 유통시키는 행위 일체
  5. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
  6. 회사와 기타 제3자의 저작권 등 지식재산권에 대한 침해
  7. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
  8. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위
  9. 회사의 동의 없이 영리를 목적으로 회사의 서비스를 사용하는 행위
  10. 회원 ID를 본인 외 제3자로 하여금 이용하도록 하는 행위
  11. 기타 불법적이거나 부당한 행위
② 회원은 서비스 이용 시 본 약관 및 개별약관 등의 규정, 이용안내 및 공지사항, 회사가 통지하는 사항, 관계법령 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안 된다.


제 12 조 (서비스의 제공 및 변경)

① 회사는 다음 각 호의 업무를 수행합니다.
  1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
  2. 구매계약이 체결된 재화 또는 용역의 제공 및 배송
  3. 재화 또는 용역과 관련된 광고 등 판촉 행위
  4. 기타 회원에게 유용한 정보 제공을 위한 제반 업무
② 회사는 재화 또는 용역(이하 “재화 등”이라 합니다.)의 품절 또는 기술적 사양의 변경 등의 불가피한 경우에는 장차 체결되는 계약에 의해 제공할 재화 등의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 등의 내용 및 제공 일자를 명시하여 현재의 재화 등의 내용을 게시한 곳에 그 제공 일자 이전 7일부터 공지합니다. 단, 회사가 합리적으로 예측할 수 없는 불가피한 여건이나 사정이 있는 경우나 단순 오기 수정 등의 경우, 위 공지를 하지 않을 수 있습니다.
③ 회사가 제공하기로 계약을 체결한 재화 등의 변경으로 인하여 회원에게 손해가 발생할 경우 그 손해를 배상합니다. 단, 회사에 고의 또는 과실이 없는 경우에는 그 책임을 지지 않습니다.
④ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요 상 수정, 중단, 변경할 수 있으며, 이에 대하여 회원에게 별도의 보상을 하지 않습니다.


제 13 조 (서비스의 중단)

① 회사와 계약한 콘텐츠 공급자와의 계약 종료 및 변경 등의 사유로 서비스의 내용이 변경되거나 서비스가 중단될 수 있습니다.
② 회사는 정보통신 설비의 보수점검 및 고장, 통신의 두절, 천재지변 등의 불가항력의 사유가 발생한 경우에는 서비스의 전부 또는 일부를 제한하거나 일시 중단할 수 있습니다.
③ 회사는 서비스의 제공이 일시적으로 중단됨으로 인하여 회원이 입은 손해에 대하여 그와 동일 또는 유사한 서비스를 제공하거나 배상합니다. 단, 제2항에서 정한 사유 또는 회사에 고의 또는 과실이 없는 경우에는 책임을 지지 않습니다.
④ 회사가 사업 종목의 전환, 사업의 포기, 업체 간 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 회사가 정한 방법으로 회원에게 통지하고, 회원의 손해가 최소화되도록 조치합니다. 다만, 회사가 통제할 수 없는 사유로 인하여 사전 통지가 불가능할 경우 사후에 통지할 수 있습니다.


제 14 조 (유료서비스의 제공 등)

① 회사는 다음 각 호의 내용을 유료서비스 이용화면이나 결제화면, 팝업화면, 고객센터 등에 회원이 알기 쉽게 표시합니다.
  1. 유료서비스의 명칭
  2. 유료서비스의 내용, 이용방법, 이용료 및 기타 이용조건
② 회사가 제공하는 유료서비스의 이용이 가능한 접근기기 사양은 최소사양정보 및 권장사양정보에 따릅니다.


제 15 조 (유료서비스 이용계약의 성립 등)

① 회원은 다음 각 호에 따른 절차 또는 이와 유사한 절차에 의하여 유료서비스 이용신청을 합니다. 회사는 이용계약 체결 전에 아래 각 호의 사항에 관하여 회원이 정확하게 이해하고 실수 또는 착오 없이 거래할 수 있도록 그와 관련된 정보를 회원에게 제공합니다.
  1. 유료서비스의 명칭, 내용, 이용방법, 이용료 및 기타 이용조건 확인 및 선택
  2. 결제방법의 선택 및 결제정보의 입력
  3. 유료서비스의 이용신청에 관한 확인 또는 회사의 확인에 대한 동의
② 유료서비스 이용계약의 성립시기는 구매완료를 신청절차 상에서 표시한 시점으로 합니다.
③ 회원은 본 약관에 동의하고 본 약관 및 개별약관 등에서 정한 결제수단을 이용해 유료상품을 구매한 후 유료서비스를 이용할 수 있습니다.


제 16 조 (지급방법)

① 회원은 다음 각 호의 방법으로 유료서비스를 이용할 수 있으며, 회사는 서비스 운영 상의 이유나 콘텐츠 공급자와의 계약 등으로 인해 일부 콘텐츠에 대해서는 그 중 일부 방법만을 결제 수단으로 제공할 수 있습니다. 단, 회원이 선택한 결제수단 제공자가 별도로 존재하는 경우, 회원은 해당 결제수단을 이용하기 전에 해당 결제수단에서 요구되는 절차를 이행하여야 해당 결제수단을 이용하여 결제를 진행할 수 있으며, 해당 결제수단을 이용한 결제에 관해서는 회원이 해당 결제수단의 제공자와 사이에 체결한 계약조건이 적용됩니다. 
  1. 카드결제
  2. 간편결제
  3. 계좌이체
  4. 휴대폰결제
  5. 상품권결제
  6. 인앱결제
  7. 그 밖에 회사가 정한 결제수단
② 회사의 정책 및 제1항의 결제방법 제공자가 정한 기준에 따라 회원의 월별 누적 결제액 및 유료서비스 구매한도가 제한될 수 있습니다. 해당 기준을 초과한 경우 유료서비스의 추가 이용은 불가능할 수 있습니다.


제 17 조 (상품의 유효기간 등)

① 회사의 서비스 이용을 위한 유료상품은 각 콘텐츠 등을 개별 구입 시 이용할 수 있는 지급 수단인 “스타”와, 일정기간 회사가 정한 내용의 서비스를 자유롭게 이용할 수 있는 “정액권”으로 분류됩니다.
② 제1항에서 정한 유료상품 외 신규 유료상품이 추가될 수 있으며, 별도로 공지한 상품안내에서 내용을 확인할 수 있습니다.
③ “스타”의 유효기간은 충전일로부터 1년으로 규정합니다. 마지막으로 유료서비스를 이용한 날로부터 1년 경과 시, 상사소멸시효에 따라 소멸될 수 있습니다. 
④ 회원은 유료상품인 “스타” 또는 “정액권”과 회사에서 무료로 제공한 “쿠폰 등”으로 서비스를 이용할 수 있습니다. 단, 무상으로 제공된 쿠폰 등의 경우 회사의 정책에 따라 소멸될 수 있습니다. 
⑤ 회원이 동시에 여러 종류의 이용권을 보유하고 있는 경우 무료로 제공한 “쿠폰 등”이 먼저 소진된 후에 유료 구매한 “스타”가 사용되며, 동일한 종류의 이용권 간에는 유효기간이 먼저 도래하는 순서에 따라 사용됩니다. 단, “정액권”의 이용기간은 구매완료 시점부터 차감되며 다른 이용권의 이용여부에 영향을 받지 않습니다.
⑥ 그 밖에 본 약관에서 정하지 아니한 상품에 대한 안내는 별도로 공지한 상품안내, 당사의 이용정책 등을 통해 확인할 수 있습니다.


제 18 조 (회원의 청약철회 및 계약해지)

① 회원은 언제든지 서비스 내의 “회원탈퇴”를 통하여 이용계약 해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를 즉시 처리하여야 합니다.
② 회원탈퇴 시 불량회원 재가입 및 부정 이용 방지 목적을 위해 내부 식별 정보는 6개월 간 보관합니다.
③ 회원탈퇴 시 회원이 작성한 게시물 중 본인 계정에 등록된 게시물 일체는 삭제되나, 제3자에 의해 재게시되거나, 제3자가 볼 수 있는 공용게시판에 등록된 게시물은 삭제되지 않으니 사전에 삭제 후 탈퇴하여야 합니다.
④ 유료서비스 이용계약에 따라 유료상품을 구매한 회원은 유료 결제를 한 날로부터 7일 이내에 회사에 대하여 유료서비스 이용계약을 철회할 수 있습니다. 단, 다음 각 호의 경우로서 청약철회가 불가능하다는 사실을 회원이 쉽게 알 수 있도록 표시하거나 시험 사용 상품을 제공하는 등의 방법으로 회원의 청약철회의 권리 행사가 방해받지 아니하도록 조치된 경우에는 회원의 청약철회가 제한됩니다.

  1. 회원에게 책임이 있는 사유로 재화등이 멸실되거나 훼손된 경우. 다만, 재화등의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외한다.
  2. 회원의 사용 또는 일부 소비로 재화등의 가치가 현저히 감소한 경우.
  3. 시간이 지나 다시 판매하기 곤란할 정도로 재화등의 가치가 현저히 감소한 경우.
  4. 복제가 가능한 재화등의 포장을 훼손한 경우.
  5. 용역 또는 문화산업진흥기본법 제2조 제5호의 디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 용역 또는 가분적 디지털콘텐츠로 구성된 계약의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 아니하다.
  6. 회원의 주문에 따라 개별적으로 생산되는 재화등 또는 이와 유사한 재화등에 대하여 청약철회를 인정하는 경우 회사에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 회원의 서면(전자문서 포함)에 의한 동의를 받은 경우.
⑤ 회원은 전항에도 불구하고 회사의 유료서비스의 내용이 표시·광고의 내용과 현저히 다르거나 계약내용과 다르게 이행되었고, 유료서비스의 하자가 수정·보완될 수 없는 경우에는 그 재화등을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회를 할 수 있습니다.


제 19 조 (환불정책)

① 회사는 회원이 청약철회, 계약해지의 의사표시를 한 날부터 3영업일 이내에 대금의 결제와 동일한 방법으로 이를 환불하여야 하며, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지하여야 합니다. 다만, 수납확인이 필요한 결제수단의 경우에는 수납확인일부터 3영업일 이내에 이를 환불하도록 할 수 있습니다.
② 회사가 전항에 따라 환불할 경우 회원이 유료서비스 이용으로부터 얻은 이익에 해당하는 금액을 공제하고 환급할 수 있습니다.
③ 회사는 환불 시 회원이 신용카드 또는 전자결제수단 등의 결제수단으로 재화등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 다만, 제1항 단서 및 제2항의 경우는 그러하지 아니할 수 있습니다.
④ 회사와 유료서비스의 대금을 받은 자 또는 회원과 유료서비스 이용계약을 체결한 자가 동일인이 아닌 경우에 각자는 청약철회, 계약해지와 관련한 의무의 이행에 있어서 연대하여 책임을 집니다.
⑤ 회사는 앱마켓 결제(인앱결제)에 있어 해당 앱마켓에 대하여 대금지급의 정지∙취소 요청 등 전자상거래등에서의소비자보호에관한법률에 따른 관련 조치를 취합니다. 다만, 위와 같은 회사의 요청에 따른 해당 앱마켓의 조치에 관하여는 해당 앱마켓의 정책에 따릅니다.



제 20 조 (계약해제, 해지 및 이용제한 등)

① 회사는 회원이 본 약관, 개별약관 등을 위반하거나 서비스의 정상적인 운영을 방해하는 것이 합리적으로 의심되거나 밝혀진 경우, 경고, 일시정지, 영구이용정지 등으로 서비스 전체 또는 일부 이용을 제한할 수 있습니다.
② 회사는 회원이 정상적인 목적이 아닌 것으로 의심되는 소비 패턴 및 이용 행태로 서비스를 이용하고 있다고 합리적으로 판단하는 경우(예: 단시간 내 실제 이용 목적으로 보기 어려운 속도로 콘텐츠를 대량 구매 및 이용하는 경우, 명의 도용이 의심되는 ID를 이용하여 콘텐츠를 구매 및 이용하는 경우 등), 회원의 서비스 전체 또는 일부 이용을 정지할 수 있습니다다.
③ 제1항 또는 제2항에 따른 이용 제한 조치에도 불구하고 더 이상 서비스 이용계약의 온전한 유지를 기대하기 어려운 경우, 회사는 부득이하게 이용 제한 조치에 더해 회원과의 이용계약을 해제 또는 해지할 수 있습니다.
④ 회사는 회원이 계속해서 1년 이상 로그인하지 않는 경우 회원정보의 보호 및 운영의 효율성을 위해 직접 서비스 이용을 제한할 수 있습니다.
⑤ 제1항 내지 제4항과 관련하여 서비스 이용을 통해 획득한 회원의 무상 혜택은 소멸되고, 회사는 이에 대해 별도로 보상하지 않습니다. 단, 회원의 귀책사유로 인하여 회사가 이용계약을 해제 또는 해지하는 경우, 회원의 요청에 따라 유료서비스 대금을 환불하되, 회원의 ID가 도용되었거나 ID가 콘텐츠의 무단 복제·배포 등을 포함한 기타 불법적인 행위에 이용되는 등 특별한 사정이 있는 경우에는 환불 또는 보상을 하지 않습니다.
⑥ 회사는 제5항 단서에 따라 환불이 필요한 경우 제19조 제1항을 준용하되, 회원이 유료서비스 이용으로부터 얻은 이익 및 환불수수료(10% 또는 1,000원 중 큰 금액)에 해당하는 금액을 환불할 금액에서 공제할 수 있습니다.
⑦ 제3항의 해제, 해지는 회사가 이 약관에서 정한 통지방법에 따라 회원에게 그 의사를 표시한 때에 효력이 발생합니다.
⑧ 회사의 해제, 해지 및 이용제한에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있다. 이때 이의가 정당하다고 인정되는 경우 회사는 즉시 서비스의 이용을 재개합니다.


제 21 조 (손해배상)
①회원이 본 약관상 의무를 위반하거나 현행 법규를 위반하는 등 책임 있는 사유로 회사에 손해를 입힌 경우에 회원은 회사에 대하여 위반행위로 인하여 발생한 손해를 배상하여야 합니다.
②회원이 서비스를 이용하는 중에 행한 불법행위나 본 약관 위반행위로 인하여 회사가 해당 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우, 해당 회원은 자신의 책임과 비용으로 회사를 면책시켜야 하며, 회사가 면책되지 못한 경우 해당 회원은 그로 인하여 회사에 발생한 모든 손해를 배상할 책임이 있습니다.
③회사는 회사가 무료로 제공하는 서비스와 관련하여 회원에게 발생한 손해에 대해서 책임지지 않습니다. 다만, 회사의 고의 또는 중대한 과실로 인하여 발생한 손해의 경우는 제외합니다.


제 22 조 (과오금)

① 회사는 과오금이 발생한 경우 회원이 결제한 방법과 동일한 방법으로 환불하며, 동일한 방법으로 환불이 불가능할 때에는 이를 사전에 고지합니다.
② 회사의 책임이 있는 사유로 과오금이 발생한 경우에는 과오금 전액을 환불하며, 회원의 책임이 있는 사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.


제 23 조 (사용기간 등)

유료서비스의 사용기간 또는 유효기간은 사전에 별도로 표시되지 않는 한 거래일로부터 1년으로 합니다. 해당 기간이 경과한 유료서비스는 이용권한이 소멸되며 보유목록에서 삭제될 수 있습니다.


제 24 조 (정보의 제공 및 광고의 게재)

① 회사는 회원이 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 회원에게 제공할 수 있습니다. 다만, 회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편 등에 대해서 수신을 거부할 수 있습니다.
② 회원이 회사의 서비스에 대한 푸쉬 알림에 동의할 경우, 회사는 앱 푸쉬를 통해 다음 각 호의 정보를 제공합니다. 회원이 앱 푸쉬 알림에 동의했으나 회원의 사정으로 앱 푸쉬가 불가능할 경우 SMS, 전자우편 등 대체수단을 통해 정보를 제공할 수 있습니다.
1. 콘텐츠 등의 회차 업데이트, 완결 등에 관한 정보
2. 유료서비스 이용조건 변경(할인 및 할인종료 등)에 대한 정보
3. 무료 이용권 충전 등 회사가 제공하는 혜택 알림
4. 회원의 폭 넓은 서비스 이용을 위한 추천 콘텐츠 등에 대한 알림
5. 기타 회원의 원활한 서비스 이용을 위해 필요한 정보에 대한 알림
③ 업데이트 알림의 내용은 회사의 서비스 정책에 따라 변경, 제거 또는 추가될 수 있습니다.
④ 회원은 언제든지 설정화면에서 변경을 통해 업데이트 알림 기능을 해제할 수 있습니다. 이 경우 회사는 회원에게 업데이트 알림을 보내지 않습니다.
⑤ 회사는 서비스 화면, 홈페이지 등에 직접 또는 제휴 관계에 있는 제3자를 통해 광고를 게재할 수 있습니다.
⑥ 회사는 회원이 사전 동의한 경우에 한하여 전자우편, SMS, 앱 푸쉬(App Push), 전자쪽지 등 전자적 전송매체를 통해 광고성 정보를 발송할 수 있습니다. 광고성 정보를 수신한 회원은 원하지 않는 경우에 언제든지 수신을 거부할 수 있으며, 회사는 회원의 수신 거부시 광고성 정보를 발송하지 않습니다.


제 25 조 (콘텐츠 등의 저작권)

① 회원이 서비스 내에 게시한 콘텐츠 등의 저작권은 해당 게시물의 저작자에게 귀속됩니다.
② 회원이 서비스 내에 게시하는 콘텐츠 등은 서비스, 관련 프로모션 등에 노출(해당 노출을 위해 필요한 범위 내에서의 일부 수정, 복제, 편집 포함)될 수 있고, 회사의 서비스를 위한 연구목적으로 활용될 수 있습니다. 이 경우 회사는 관련 법령을 준수하며, 회원은 언제든지 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 취할 수 있습니다.
③ 회사는 제2항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다.


제 26 조 (콘텐츠 등의 관리)

① 회원이 게시한 콘텐츠 등에 정보통신망이용촉진및정보보호등에관한법률, 저작권법 등 관련법에 위반되는 내용이 포함된 경우, 권리자는 관련법이 정한 절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사는 관련법에 따라 조치를 취하여야 합니다.
② 회사는 전항에 따른 권리자의 요청이 없는 경우라도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우에는 관련법에 따라 해당 콘텐츠 등에 대해 임시조치 등을 취할 수 있습니다.
③ 본 조에 따른 세부절차는 정보통신망이용촉진및정보보호등에관한법률, 저작권법이 규정한 범위 내에서 회사가 정한 게시중단요청서비스에 따릅니다.


제 27 조 (권리의 귀속)

① 서비스에 대한 저작권 및 지식재산권은 회사에 귀속된다. 단, 회원의 게시물 및 콘텐츠 공급자와의 계약에 따라 제공된 콘텐츠 등은 그 콘텐츠를 제공한 회원 및 콘텐츠 공급자에게 저작권이 있습니다.
② 회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정, 아이디, 정보, 결제수단 등을 이용할 수 있는 이용권만을 부여하며, 회원은 회사가 허용하지 않는 한 이를 양도, 판매, 담보제공 등의 방법으로 처분하여서는 안 됩니다.

제 28 조 (책임제한)

① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
② 회사는 회원의 귀책사유로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
③ 회사는 회원이 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 관하여는 책임을 지지 않습니다.
④ 회사는 회원 간 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 책임이 면제됩니다.
⑤ 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는 한 책임을 지지 않습니다.

제 29 조 (준거법 및 재판관할)

① 회사와 회원 간 제기된 소송은 대한민국법을 준거법으로 합니다.
② 회사와 회원 간 발생한 분쟁에 관한 소송은 제소 당시의 회사의 주소에 의합니다.

[부칙]
본 약관의 개정사항은 2024년 9월 1일 업데이트부터 적용됩니다.`}
        </div>
      </div>
      
    </Container>
  )
}