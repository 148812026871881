import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput, Modal } from '..'


const Container = styled.div`
  .keywordItem {
    border: 1px solid #b9b9b9;
    width: 145px;
    height: 47px;
    border-radius: 8px;
    cursor: pointer;
  }
`

export default function KeywordModal ({
  keywordSelect,
  selectKeywordList,
  keywordList,
  isOpen,
  setIsOpen
}) {

  console.log(selectKeywordList)
  return (
    <Container>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <div className='innerModalContainer'>
          <div className='bold font-30 mb-8'>키워드</div>
          <div className='c-828282 medium font-14 mb-32'>
            * 최대 5개까지 등록 가능합니다.<br/>
            * 독자님이 작품을 검색할때 도움을줍니다.
          </div>

          <div className='flex align-center mb-12'>
            <div className='bold font-20 mr-12'>장르 형태 키워드</div>
            <CheckBox label={'본 작품의 장르 관련 키워드만 띄우기'}/>
          </div>

          <GridBox col={5} gap={20}>
            {keywordList.filter(item => item.keywordType.includes(0)).map((item, idx) => (
              <KeywordItem 
                name={item.name}
                key={`item-0-${idx}`}
                isActive={selectKeywordList.find(fit => fit.name === item.name && fit.keywordType.includes(0))}
                onClick={() => keywordSelect(item)}
              />
            ))}
          </GridBox>


          


          <div className='flex align-center mt-32 mb-12'>
            <div className='bold font-20 mr-12'>소재 관련 키워드</div>
            <CheckBox label={'본 작품의 장르 관련 키워드만 띄우기'}/>
          </div>

          <GridBox col={5} gap={20}>
            {keywordList.filter(item => item.keywordType.includes(1)).map((item, idx) => (
              <KeywordItem 
                name={item.name}
                key={`item-1-${idx}`}
                isActive={selectKeywordList.find(fit => fit.name === item.name && fit.keywordType.includes(1))}
                onClick={() => keywordSelect(item)}
              />
            ))}
          </GridBox>
          


          <div className='flex align-center mt-32 mb-12'>
            <div className='bold font-20 mr-12'>주인공 관련 키워드 </div>
            <CheckBox label={'본 작품의 장르 관련 키워드만 띄우기'}/>
          </div>

          <GridBox col={5} gap={20}>
            {keywordList.filter(item => item.keywordType.includes(2)).map((item, idx) => (
              <KeywordItem 
                name={item.name}
                key={`item-2-${idx}`}
                isActive={selectKeywordList.find(fit => fit.name === item.name && fit.keywordType.includes(2))}
                onClick={() => keywordSelect(item)}
              />
            ))}
          </GridBox>
        </div>
      </Modal>
    </Container>
  )
}

const KeywordItemContainer = styled.div`
  .keywordItem {
    background-color: ${({isActive}) => isActive ? '#ba1c1f' : '#fff'};
    color: ${({isActive}) => isActive ? '#ffffff' : '#000000'};
    transition: .2s;
  }
`

const KeywordItem = ({
  name,
  isActive,
  onClick
}) => {
  return (
    <KeywordItemContainer onClick={onClick} isActive={isActive}>
      <div className='keywordItem flex-center font-18 regular'>
        #{name}
      </div>
    </KeywordItemContainer>
  )
}