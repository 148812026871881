import { Button } from '@/components';
import { AccordionMenu3 } from '@/components/AccordionMenu3';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 0 20px 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: 900;
  margin-bottom: 16px;
  text-align: center;
`

export default function Page () {
  useHideLayout('header', true);
  const navigate = useNavigate();

  return (
    <Container>
      <TitleHeader title="자주 묻는 질문"/>


      <div>
        <AccordionMenu3 content="성인 인증 방법">
          메뉴 클릭시 성인인증 메뉴가 있습니다. 해당 메뉴 클릭하시면 성인인증 시스템으로 연결됩니다.<br/>
          19세 미만 열람 불가 작품 클릭시에도 성인인증 시스템으로 이어집니다.
        </AccordionMenu3>

        <AccordionMenu3 content="결제 취소 방법">
          고객센터 1:1문의 게시판에 유저닉네임, 혹은 아이디(이메일 주소)와 환불하고자 하는 주문을 작성해주시면 확인 후 조치 취하도록 하겠습니다.
        </AccordionMenu3>

        <AccordionMenu3 content="비밀번호 변경은 어떻게 하나요?">
          1. 스텔라 노벨 {'<이메일 아이디>'}만 비밀번호 변경이 가능합니다.<br/>
          2. 소셜 로그인 방식을 통해 가입한 경우 해당 사이트에서 비밀번호를 변경하셔야 합니다.<br/><br/>
          메뉴{'>'}하단 설정{'>'}계정설정에 들어가시면 비밀번호 변경과 프로필, 닉네임 등이 가능합니다.
        </AccordionMenu3>
        
        <AccordionMenu3 content="해외 이용 가능한가요?">
          아쉽게도 현재는 보안 문제로 인하여 해외 서비스를 진행하고 있지 않습니다.<br/>
          추후 서비스 확장 계획은 있으니 기다려주시면 감사하겠습니다. {':)'}
        </AccordionMenu3>
        
        <AccordionMenu3 content="오류 발생 시">
          고객센터 1:1문의 남겨주시면 확인 후 조치를 취하도록 하겠습니다.<br/>감사합니다.
        </AccordionMenu3>
      </div>
    </Container>
  )
}
