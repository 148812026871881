import axios, { axiosAuth } from "@/lib/axios";


// 장르에 맞는 스텔리에 리스트 가져오기
export const fetchStalierGenreList = async (genre, page) => {
  const deviceType = localStorage.getItem('device');
  let uri = `/stalier/nlist/${page}/${genre}`
  if(deviceType === "IOS") uri = `/stalier/nlist/${page}/${genre}?device=IOS`;

  const { data } = await axios.get(uri);
  return data;
};


// 장르에 맞는 스텔리에 리스트 가져오기
export const fetchStalierOwnList = async (page) => {
  const { data } = await axiosAuth.get(`/stalier/writer/own/${page}`);
  return data;
};


// 스텔리에 생성
export const fetchNewStalierData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/stalier/writer', {
      ...fetchData
    })

    alert('작품을 생성했습니다.')


    return data;
  } catch (e) {
    return alert('작품 생성에 실패했습니다.')
  }
}


// 스텔리에 생성
export const fetchUpdateStalierData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.put('/stalier/writer', {
      ...fetchData
    })

    alert('작품을 수정했습니다.')


    return data;
  } catch (e) {
    return alert('작품 수정에 실패했습니다.')
  }
}




// 스텔리에 상세 데이터 가져오기
export const fetchStalierDetailData = async (uuid) => {
  try {
    const { data } = await axios.get(`/stalier/view/${uuid}`);
    return data;
  } catch (e) {
    alert('에러')
    console.log(e)
  }
}


// 작가유저 - 스텔리에 상세 데이터 가져오기
export const fetchStalierWriterDetailData = async (uuid) => {
  try {
    const { data } = await axiosAuth.get(`/stalier/writer/detail/${uuid}`);
    return data;
  } catch (e) {
    alert('에러')
    console.log(e)
  }
}



// 스텔리에 에피소드 생성
export const fetchNewStalierEpisodeData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.post('/stalierepisode/writer', {
      ...fetchData
    })


    return data;
  } catch (e) {
    throw e; 
  }
}

// 스텔리에 에피소드 수정
export const fetchUpdateStalierEpisodeData = async (fetchData) => {
  try {
    const { data } = await axiosAuth.put('/stalierepisode/writer', {
      ...fetchData
    })


    return data;
  } catch (e) {
    throw e; 
  }
}



// 스텔리에 에피소드 전체 가져오기
export const fetchStalierEpisodeListData = async (uuid) => {
  try {
    const { data } = await axiosAuth.get(`/stalierepisode/writer/list/${uuid}`);
    return data;
  } catch (e) {
    alert('에러')
    console.log(e)
  }
}


// 작가유저 - 스텔리에 에피소드 상세 데이터 가져오기
export const fetchStalierEpisodeWriterDetailData = async (uuid) => {
  try {
    const { data } = await axiosAuth.get(`/stalierepisode/writer/detail/${uuid}`);
    return data;
  } catch (e) {
    alert('에러')
    console.log(e)
  }
}





// 스텔리에 회차 리스트 가져오기 - 비로그인
export const fetchStalierEpisodeList = async (uuid) => {
  const { data } = await axios.get(`/stalierepisode/list/${uuid}`);
  return data;
}

// 스텔리에 회차 리스트 가져오기 - 로그인
export const fetchStalierEpisodeListWithUser = async (uuid) => {
  const { data } = await axiosAuth.get(`/stalierepisode/list/user/${uuid}`); 
  return data;
}


// 스텔리에 회차 가져오기
export const fetchStalierEpisodeListWithAuthCheck = async (uuid) => {
  const accessToken = localStorage.getItem('user_access_token');
  if(accessToken) return await fetchStalierEpisodeListWithUser(uuid);
  else return await fetchStalierEpisodeList(uuid);
}


// 작가유저 - 스텔리에 에피소드 상세 데이터 가져오기
export const fetchStalierEpisodeDetailData = async (uuid) => {
  try {
    const { data } = await axiosAuth.get(`/stalierepisode/detail/${uuid}`);
    
    if(data?.status === 404) throw new Error();
    return data;
  } catch (e) {
    console.log(e)
  }
}


