import { fetchNewPermanentList } from '@/api/permanent';
import { CheckBox } from '@/components';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useUser } from '@/stores';
import { useNovelData } from '@/stores/novelData'
import { getTheme } from '@/styles/theme';
import { formatDateYMD } from '@/utils/format';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;

  .c-929292 {
    color: #929292;
  }

  .episode-item {
    width: 100%; 
    border-bottom: 1px solid #e7e6e7;
    padding: 10px 20px;
  }

  .buyButton {
    width: 100%;
    height: 60px;
    position: fixed;
    bottom: 0;
    background-color: #ba1c1f;
  }
`


export default function Page () {

  const { uid } = useParams();


  const { novelData, episodeList } = useNovelData(uid);
  const [sort_type, set_sort_type] = useState(true);


  const { user } = useUser('parmanent-buy-uid');
  const { refreshUserData } = useUser();
  useHideLayout('header', true);

  


  const [selected, setSelected] = useState([]);


  // 소장 가능한지 체크
  function validateSelect(item) {
    if(!item.isPaid) return false; // 무료
    else if(item.salesStatus === "소장중") return false; // 소장중일 경우
    else if(item.hasTimedeal) return true; // 타임딜일 경우
    else if(item.salesStatus === "대여중") return true; // 대여중일 경우
    else return true;
  }

  // 소장 가능한 리스트 filter
  function validateSelectedList () {
    const list = episodeList.filter(item => {
      if(!item.isPaid) return false; // 무료
      else if(item.salesStatus === "소장중") return false; // 소장중일 경우
      else if(item.hasTimedeal) return true; // 타임딜일 경우
      else if(item.salesStatus === "대여중") return true; // 대여중일 경우
      else return true;
    });

    return list;
  }

  // 전체 선택 ( 체크 되었는지 확인하기 )
  function getValidateAllChecked() {
    const v_list = validateSelectedList().map(item => item._id);
    console.log({v_list, selected});
    if(selected.length === v_list.length) return true;
    else return false;
  }

  // 전체 선택하기
  function selectAll () {
    const v_list = validateSelectedList().map(item => item._id);
    setSelected(v_list);
  }

  // 전체 해제하기
  function dropAll() {
    setSelected([]);
  }

  // 단일 선택하기
  function selectItem (item_id) {
    setSelected(list => [...list, item_id]);
  }

  // 단일 해제하기
  function dropItem(item_id) {
    let list = selected.filter(item => item !== item_id);
    setSelected(list);
  }

  function getPrice() {
    let sum = 0;
    const num = selected.length;
    
    for(let i=0; i< num; i++) { 
      let item = episodeList.find(ida => ida._id === selected[i]);
      sum += item.episode_price_amount;
    }
    
    

    
    if(num >= 100) return sum - 12; // 100개 - 스타 12개 할인
    else if(num >= 50) return sum - 6; // 50개 - 스타 6개 할인
    else if(num >= 10) return sum - 1; // 스타 1개 할인
    else return sum; // 스타 0개 할인


  }

  async function submit() {
    const amount = getPrice();
    const num = selected.length;

    let expense_list_sales_promo = '없음'

    if(num >= 100) expense_list_sales_promo = "100개 - 스타 12개 할인"; // 100개 - 스타 12개 할인
    else if(num >= 50) expense_list_sales_promo = "50개 - 스타 6개 할인"; // 50개 - 스타 6개 할인
    else if(num >= 10) expense_list_sales_promo = "10개 - 스타 1개 할인"; // 스타 1개 할인


    await fetchNewPermanentList(
      novelData._id,
      selected,
      amount,
      user?.currentBalance,
      expense_list_sales_promo
    )
    await refreshUserData();

    window.location.href = `/novel/detail/${novelData.uuid}`
  }

  return (
    <Container className='relative'>
      <TitleHeader title={`선택구매 - ${novelData.title}`}/>
      
      
      <div className='px-20 flex-1 font-12 mb-20'>
        <div className='mb-8'>선택구매 혜택</div>

        <div className='mb-4'>
          <span className='bold c-primary100'>소장 10화: </span>
          <span>스타 1개 할인</span>
        </div>
        
        <div className='mb-4'>
          <span className='bold c-primary100'>소장 50화: </span>
          <span>스타 6개 할인</span>
        </div>
        
        <div className='mb-4'>
          <span className='bold c-primary100'>소장 100화: </span>
          <span>스타 12개 할인</span>
        </div>
      </div>

      
      <div className='px-20 flex-1 font-12 flex align-center'>
        <div className="bold">전체 구매 가능회차 : {validateSelectedList()?.length}화</div>
      </div>


      <div className='mt-24 flex-center px-20 w-full py-10'>
        <div className='w-full flex align-center' style={{width: 'fit-content'}}>
          <div className='font-12 bold mr-4'>전체선택</div>
          <CheckBox selected={getValidateAllChecked()} onClick={() => {
            if(getValidateAllChecked()) dropAll();
            else selectAll();
          }}/>
        </div>

        <div className='flex-1'/>

        <div className='font-12 flex-center medium'>
          <div className={!sort_type ? 'c-primary' : 'c-878787'} onClick={() => set_sort_type(false)}>회차순</div>
          <div className='mx-2 c-878787 font-10'>|</div>
          <div className={sort_type ? 'c-primary' : 'c-878787'}  onClick={() => set_sort_type(true)}>등록순</div>
        </div>
      </div>



      {
        [...episodeList]?.sort((a, b) => {
          if(!sort_type) {
            return b?.episode_index - a?.episode_index;
          } else {
            return a?.episode_index - b?.episode_index;
          }
        })?.map((item, idx) => (
          <div className='episode-item w-full flex align-center' key={`episode-${idx}`} style={{
            color: selected.includes(item._id) ? '#ba1c1f' : '#000'
          }} onClick={() => {
            if(validateSelect(item)) {
              if(selected.includes(item._id)) dropItem(item._id);
              else selectItem(item._id);
            }
          }}>
            <div className="flex-1">
              <div className='medium mb-8 font-14 ellipsis-1 mb-2'>{item.title}</div>
              <div className='font-10 medium' style={{
                color: selected.includes(item._id) ? '#ba1c1f' : '#929292'
              }}>
                {formatDateYMD(item.updated_date)}
              </div>
            </div>


            <div className="text-right">
              {validateSelect(item) ? 
                <div className='flex align-center'>
                  <div className='medium mr-12 font-12'>{item.episode_price_amount} 스타</div>
                  <CheckBox selected={selected.includes(item._id)}/>
                </div> : 
                <div className="semibold font-13">{item.salesStatus}</div>
              }
              
            </div>
          </div>
        ))
      }

      <div className='pointer buyButton flex-center c-white bold font-20' onClick={submit}>구매하기 - {getPrice()}스타</div>
    </Container>
  )
}