import axios from "@/lib/axios";

export const fetchGenreList = async () => {
  const { data } = await axios.get(`/genre/list`);
  return data;
}




export const fetchStalierGenreList = async () => {
  const { data } = await axios.get(`/genre/stalier/list`);
  return data;
}
  