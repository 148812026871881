import { accordion_down, accordion_up } from "@/assets/components";
import { useState } from "react"
import styled from "styled-components";

const Container = styled.div`
  border-bottom: 1px solid #e7e6e7;
  color: #575757;


  .line-24 {
    line-height: 24px;
  }

  .arrow-position {
    top: 15px;
    height: 24px;
    width: 24px;
    right: 16px;
  }
`


export const AccordionMenu = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container className='px-20 py-16 w-full relative font-16' onClick={() => setIsOpen(chk => !chk)}>
      {!isOpen && <div className='ellipsis-1 wdwq1'>{content}</div>}
      {isOpen && <div className='pre-line wdwq1 line-24'>{content}</div>}

      <img className='absolute arrow-position' src={isOpen ? accordion_up : accordion_down}/>
    </Container>
  )
}