import Loading from '@/components/Loading';
import { useHideLayout } from '@/hooks';
import axiosInstance from '@/lib/axios';
import { useUser } from '@/stores';
import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 99vh;
`;


export default function Page () {
  const location = useLocation();
  const hasFetched = useRef(false); // 요청 실행 여부를 추적하는 useRef

  const { userSocialSignin } = useUser();


  useEffect(() => {
    // URL에서 code와 state 쿼리 매개변수 추출
    // const searchParams = new URLSearchParams(location.search.slice(13));
    const access_token = location.search.slice(13)
    // const access_token = searchParams.get('access_token');

    console.log(access_token)

    // useEffect가 두 번 실행되는 경우를 방지
    if (access_token && !hasFetched.current) {
      // 네이버 토큰을 요청하는 함수 호출
      fetchUserInfo(access_token);
      hasFetched.current = true; // 요청이 실행되었음을 표시
    }
  }, [location.search]);


  const fetchUserInfo = async (access_token) => {
    try {
      const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      const socialId = userInfoResponse.data.id;
      const socialEmail = userInfoResponse.data.email;

      const response = await axiosInstance.post('/userauth/oauth', {
        email: socialEmail,
        socialId,
        loginType: "GOOGLE",
        socialToken: access_token
      });

      // response status 확인
      if(response.status === 201) {
        const result = await userSocialSignin(response.data.accessToken)
        if(result) window.location.href = '/';
        else alert('로그인에 실패했습니다.');
      }
      else if(response.status === 203) {
        localStorage.setItem('user_data', JSON.stringify({ email: socialEmail }))
        localStorage.setItem('user_access_token', response.data.accessToken)

        window.location.href = '/social/info';
      }
    } catch (error) {
      if (error.response) {
        // 서버가 응답을 반환한 경우 (에러 응답)
        const statusCode = error.response.status;

        if (statusCode === 409) alert('소셜 계정이 사용중인 이메일입니다.'); 
      }
      else alert('로그인에 실패했습니다.');
      
      console.error('Error fetching user info:', error);
      window.location.href = '/login';
    }
  }
  
  useHideLayout('header', true);

  return (
    <Container className='flex-center'>
      <Loading/>
    </Container>
  );
}