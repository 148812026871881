import { fetchFileToUri } from "@/api/file";
import { fetchNewStalierData } from "@/api/stalier";
import { validateFile, validateLength } from "@/utils/format";
import { nanoid } from "nanoid";
import { useState } from "react";
import { atom, useRecoilState } from "recoil";

const AgeGradeList = [
  { name: "전체이용가", value: "전체이용가" },
  { name: "15세 이용가", value: "15세 이용가" },
  { name: "19세 이용가", value: "19세 이용가" },
]


const stalierCreateDataState = atom({
  key: 'stalierCreateData',
  default: {
    title: '', // 제목
    book_cover: '', // 표지
    book_cover_file: null, // 표지 ( 파일 )
    keywords: [], // 키워드
    genre: {name: "장르", value: ''}, // 장르
    summary: '', // 줄거리 = 작품소개
    ageGrade: AgeGradeList[0], // 연령 등급
    hasCompletion: false,
  },
});

export const useStalierCreateData = () => {
  const [stalierCreateData, setStalierCreateData] = useRecoilState(stalierCreateDataState);
  const [isLoading, setIsLoading] = useState(false);


  const handleChange = (key, value) => {
    // validation check
    switch(key) {
      case 'title':
        if(!validateLength(value, 50)) return alert('제목은 50자 이내로 작성해주세요.'); // 필요시 length 수정
        break;
      case 'summary':
        if(!validateLength(value, 5000)) return alert('작품소개는 5000자 이내로 작성해주세요.'); // 필요시 length 수정
        break;
      case 'book_cover_file':
        if(!validateFile(value, 1024 * 1024 * 30)) return alert('이미지 파일은 30MB 이하로 업로드해주세요.'); // 필요시 size 수정
        break;
      default:
        break;
    }

    setStalierCreateData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };
  
  

  const keywordSelect = (item) => {
    let copyKeywordSelect = [...stalierCreateData.keywords];

    if(stalierCreateData.keywords.find(fit => fit.name === item.name && fit.keywordType === item.keywordType)) {
      copyKeywordSelect = copyKeywordSelect.filter(fit => fit._id !== item._id);
    }
    else copyKeywordSelect.push(item);
    

    setStalierCreateData((prevData) => ({
      ...prevData,
      keywords: copyKeywordSelect,
    }));
  }




  async function submit() {
    if(isLoading) return;
    setIsLoading(true);

    try{
      const uuid = nanoid(40);
      let new_stalier = { ...stalierCreateData };

      // validation check
      if(stalierCreateData.title.length === 0) throw new Error('제목을 입력해주세요.');
      if(!validateLength(stalierCreateData.title, 50)) throw new Error('제목은 50자 이내로 작성해주세요.');
      if(stalierCreateData.book_cover_file && !validateFile(stalierCreateData.book_cover_file, 1024 * 1024 * 30)) throw new Error('이미지 파일은 30MB 이하로 업로드해주세요.');
      if(stalierCreateData.keywords.length === 0) throw new Error('키워드를 입력해주세요.');
      if(stalierCreateData.genre.value === '') throw new Error('장르를 입력해주세요.');
      if(stalierCreateData.summary.length === 0) throw new Error('작품소개를 입력해주세요.');
      if(!validateLength(stalierCreateData.summary, 5000)) throw new Error('작품소개는 5000자 이내로 작성해주세요.');

      // 이미지 처리
      try {
        if(stalierCreateData.book_cover_file) {
          await fetchFileToUri(`stalier/${uuid}`, 'book_cover', stalierCreateData.book_cover_file);
        
          new_stalier.book_cover = `stalier/${uuid}/book_cover.png`;
        }
      } catch(e) {
        console.error(e);
        throw new Error('이미지 업로드에 실패했습니다.');
      }
      console.log({new_stalier})

      new_stalier.uuid = uuid;
      new_stalier.ageGrade = stalierCreateData.ageGrade.value;
      new_stalier.genre = stalierCreateData.genre.value;


      new_stalier.keywords = stalierCreateData.keywords.map(item => item._id);


      // Fetch Data
      try {
        await fetchNewStalierData(new_stalier);
        setStalierCreateData({
          title: '', // 제목
          book_cover: '', // 표지
          book_cover_file: null, // 표지 ( 파일 )
          keywords: [], // 키워드
          genre: {name: "장르", value: ''}, // 장르
          summary: '', // 줄거리 = 작품소개
          ageGrade: AgeGradeList[0], // 연령 등급
          hasCompletion: false,
        })
        window.history.back();
      } catch (e) {
        console.error(e);
        throw new Error('작품 등록에 실패했습니다.');
      }

    } catch(e) {
      alert(e.message);
    } finally {
      setIsLoading(false);
    }
  }



  return {
    submit,
    handleChange,
    keywordSelect,
    stalierCreateData,
    isLoading,
    AgeGradeList
  }
}