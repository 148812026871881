import { formatImageUri } from '@/utils/format';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import NeedAuthAdultModal from '../Auth/NeedAuthAdultModal';
import { ic_adult, ic_odi, ic_promotion_0, ic_promotion_1, sample_adult } from '@/assets';


const Container = styled.div`
  width: 80px;

  .adult_cover {
    border: 1px solid #d3d3d3;
  }


  .book_cover {
    width: 80px;
    height: 108px;

    border-radius: 12px;
  }

  .adultMark {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 2px;
    left: 2px;
  }  
  
  
  .promotionMark {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 2px;
    right: 2px;
  }

  
`


export default function NovelItemSmall ({ 
  book_cover, 
  ageGrade, 
  title, 
  isAudioBook, 
  uuid,
  isBook,
  hasTimedeal=false, // 타임딜인지
  hasTimeFreeRent=false, // 스확시간인지
  userAdultCheck=false,
}) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>  
      <NeedAuthAdultModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      
      <Container className='pointer mr-16 relative' onClick={() => {
        if(ageGrade === "19세 이용가") {
          if(!userAdultCheck) setIsOpen(true);
          else navigate(`/novel/detail/${uuid}`);
        }
        else navigate(`/novel/detail/${uuid}`)
      }}>
        <div className='w-full'>
          {
            ageGrade === "19세 이용가" && !userAdultCheck ?
            <img 
              src={sample_adult} 
              className='w-full book_cover adult_cover'
            />
            :
            <img 
              src={formatImageUri(book_cover)} 
              className='w-full book_cover'
            />
          }
        </div>

        {(ageGrade === "19세 이용가" && userAdultCheck) &&
          <img className='adultMark' src={ic_adult}/>
        }

        
        {hasTimedeal && 
          <img className='promotionMark' src={ic_promotion_0}/>
        }

        
        {hasTimeFreeRent && 
          <img className='promotionMark' src={ic_promotion_1}/>
        }

        <div className='font-12 mt-6 medium w-full ellipsis-1'>{isBook ? "[단행본] " : ""}{title}</div>
        <div className='font-12 mt-4 exlight'>
          <span>웹소설</span>
          {isAudioBook && <span>, 오디오북</span>}
        </div>
      </Container>
    </>
  )
}