import { social_apple, social_google } from '@/assets'
import styled from 'styled-components'


const Container = styled.div`
  width: 250px;
  max-width: 80vw;

  border: 1px solid #000;
  border-radius: 80px;
  height: 50px;
  
  .social_icon {
    width: 24px;
    height: 24px;
    left: 16px;
  }
`

export default function AppleLoginButton() {
  const CLIENT_ID = import.meta.env.VITE_APPLE_CLIENT_ID;
  const URI = import.meta.env.VITE_APPLE_CALLBACK_URL;
  const REDIRECT_URI = encodeURIComponent(URI);
  const STATE = Math.random().toString(36).substring(7); // CSRF 방지용 상태 값

  const submit = () => {
    const appleAuthUrl = `https://appleid.apple.com/auth/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=email&state=${STATE}&response_mode=form_post`;
    window.location.href = appleAuthUrl; // 애플 로그인 페이지로 리디렉션
  };

  return (
    // <Container className='flex-center relative pointer' onClick={submit}>
    //   <img className='social_icon absolute' src={social_apple}/>
    //   <div className='pl-24 medium font-18'>애플 계정으로 로그인</div>
    // </Container>
    
    <CircleContainer onClick={submit}>
      <img className='social_icon pointer' src={social_apple}/>

    </CircleContainer>
  );
}


const CircleContainer = styled.div`
  width: 36px;
  height: 36px;

  .social_icon {
    width: 36px;
    border-radius: 50%;
  }
`