import { axiosAuth } from "@/lib/axios";


// 신규독자 베네핏 대여권 발급
export const fetchRentBenefitNovel = async ({
  novel_id, 
  benefitCount,
  novel_uuid,
}) => {
  try {
    const { data } = await axiosAuth.post('/rent/rentbenefit', {
      target_novel: novel_id, // 대상 소설
      promotionType: "신규 독자 혜택",
      promotion: null,
      benefitCount,
      novel_uuid,
      benefitCount
    });

    if(data?.response?.statusCode === 409) return alert('대상에 포함되지 않습니다. 발급 받을 수 없습니다.')

    else {
      alert('대여권이 발급되었습니다.');
      return data;
    }
  } catch (e) {
    return alert('대여권이 발급에 실패했습니다.')
  }
}




// 신규독자 베네핏 대여권 발급 권한 여부
export const fetchCertifiateRentBenefitNovel = async ({
  novel_id, 
  benefitCount,
  novel_uuid,
}) => {
  try {
    const { data } = await axiosAuth.post('/rent/certificate/rentbenefit', {
      target_novel: novel_id, // 대상 소설
      promotionType: "신규 독자 혜택",
      promotion: null,
      benefitCount,
      novel_uuid
    });

    return data;
  } catch (e) {
  }
}



// 특정 소설에 대한 대여권 리스트
export const fetchUserNovelRentList = async (novel_id) => {
  try {
    const { data } = await axiosAuth.get(`/rent/novel/list/${novel_id}`);
    return data;
  } catch (e) {
  }
}


// 대여권 사용
export const fetchUseRentTicket = async (
  rentTicket_id,
  target_episode,
  novel_id
) => {
  try {
    const { data } = await axiosAuth.post('/rent/use', {
      target_novel: novel_id,  // 대상 소설
      ticket_id: rentTicket_id, // 대여권 _id
      target_episode: target_episode  // 대상 Episode
    });

    return alert('대여권이 사용되었습니다.');
  } catch (e) {
    return alert('사용할 수 없는 대여권입니다.')
  }
}


// 프로모션 대여권 발급 - 1매 ( 스확시간에서 사용중 )
export const fetchGetPromotionRentTicket = async (
  target_novel, 
  target_coin_promotion, 
  userpromotion_id,
  title
) => {
  try {
    await axiosAuth.post('/promotion/user/rent', {
      target_novel,
      target_coin_promotion, // 적용 재화 프로모션
      title, // 비고, 설명 등
      userpromotion_id // "Odi", "Exposure"
    })
  
    alert('스확시간 대여권이 발급되었습니다.'); // 토스트가 더 나음
  } catch (err) {
    alert('스확시간 대여권이 발급에 실패했습니다.')
    console.log(err);
  }
}
