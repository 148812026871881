export const OPENSOURCE_BACKEND = [
  {
    "name": "aws-sdk",
    "license": "Apache-2.0",
    "uses": "AWS 서비스와 상호작용하는 클라이언트 라이브러리 (S3 포함)",
    "copyright": "Copyright Amazon"
  },
  {
    "name": "axios",
    "license": "MIT",
    "uses": "Promise 기반의 HTTP 클라이언트 라이브러리",
    "copyright": "Copyright Axios Project"
  },
  {
    "name": "bcrypt",
    "license": "MIT",
    "uses": "비밀번호 해싱 기능을 제공하는 라이브러리",
    "copyright": "Copyright Nils Winkler"
  },
  {
    "name": "class-transformer",
    "license": "MIT",
    "uses": "데이터를 클래스 인스턴스로 변환하는 유틸리티 라이브러리",
    "copyright": "Copyright TypeStack"
  },
  {
    "name": "class-validator",
    "license": "MIT",
    "uses": "데이터 유효성 검사를 위한 라이브러리",
    "copyright": "Copyright TypeStack"
  },
  {
    "name": "cookie-parser",
    "license": "MIT",
    "uses": "HTTP 쿠키를 해석하는 미들웨어",
    "copyright": "Copyright TJ Holowaychuk"
  },
  {
    "name": "cross-env",
    "license": "MIT",
    "uses": "플랫폼에 따라 환경 변수를 설정하는 유틸리티 라이브러리",
    "copyright": "Copyright Kent C. Dodds"
  },
  {
    "name": "firebase-admin",
    "license": "Apache-2.0",
    "uses": "Firebase 백엔드 서비스를 관리하는 SDK",
    "copyright": "Copyright Google"
  },
  {
    "name": "moment",
    "license": "MIT",
    "uses": "날짜 및 시간을 쉽게 처리하는 라이브러리",
    "copyright": "Copyright Moment.js contributors"
  },
  {
    "name": "moment-timezone",
    "license": "MIT",
    "uses": "시간대를 지원하는 Moment 라이브러리 확장",
    "copyright": "Copyright Moment.js contributors"
  },
  {
    "name": "mongoose",
    "license": "MIT",
    "uses": "MongoDB 객체 모델링 도구",
    "copyright": "Copyright Automattic"
  },
  {
    "name": "nanoid",
    "license": "MIT",
    "uses": "짧고 고유한 ID를 생성하는 라이브러리",
    "copyright": "Copyright Andrey Sitnik"
  },
  {
    "name": "passport",
    "license": "MIT",
    "uses": "Node.js 애플리케이션을 위한 인증 미들웨어",
    "copyright": "Copyright Jared Hanson"
  },
  {
    "name": "passport-apple",
    "license": "MIT",
    "uses": "Apple 로그인 전략을 제공하는 Passport 모듈",
    "copyright": "Copyright Fausto Núñez"
  },
  {
    "name": "passport-google-oauth20",
    "license": "MIT",
    "uses": "Google OAuth 2.0 인증 전략을 제공하는 Passport 모듈",
    "copyright": "Copyright Jared Hanson"
  },
  {
    "name": "passport-jwt",
    "license": "MIT",
    "uses": "JWT 기반 인증을 제공하는 Passport 모듈",
    "copyright": "Copyright Auth0"
  },
  {
    "name": "passport-kakao",
    "license": "MIT",
    "uses": "Kakao 로그인 전략을 제공하는 Passport 모듈",
    "copyright": "Copyright Jaewoo Park"
  },
  {
    "name": "passport-local",
    "license": "MIT",
    "uses": "로컬 로그인 전략을 제공하는 Passport 모듈",
    "copyright": "Copyright Jared Hanson"
  },
  {
    "name": "passport-naver",
    "license": "MIT",
    "uses": "Naver 로그인 전략을 제공하는 Passport 모듈",
    "copyright": "Copyright Jaewoo Park"
  },
  {
    "name": "randomstring",
    "license": "MIT",
    "uses": "임의 문자열을 생성하는 라이브러리",
    "copyright": "Copyright Kazuhito Hokamura"
  },
  {
    "name": "reflect-metadata",
    "license": "MIT",
    "uses": "메타데이터 정의를 지원하는 라이브러리",
    "copyright": "Copyright Microsoft"
  },
  {
    "name": "rxjs",
    "license": "Apache-2.0",
    "uses": "리액티브 프로그래밍을 위한 라이브러리",
    "copyright": "Copyright RxJS Project"
  },
  {
    "name": "@nestjs/cli",
    "license": "MIT",
    "uses": "NestJS 애플리케이션을 쉽게 생성하고 관리할 수 있는 CLI 도구",
    "copyright": "Copyright NestJS"
  },
  {
    "name": "@nestjs/schematics",
    "license": "MIT",
    "uses": "NestJS에서 코드 생성과 프로젝트 구성을 지원하는 도구",
    "copyright": "Copyright NestJS"
  },
  {
    "name": "@nestjs/testing",
    "license": "MIT",
    "uses": "NestJS 애플리케이션의 테스트 유틸리티 라이브러리",
    "copyright": "Copyright NestJS"
  },
  {
    "name": "@typescript-eslint/eslint-plugin",
    "license": "MIT",
    "uses": "TypeScript 코드에 대한 ESLint 검사 규칙 제공",
    "copyright": "Copyright TypeScript ESLint Team"
  },
  {
    "name": "@typescript-eslint/parser",
    "license": "MIT",
    "uses": "TypeScript 코드를 ESLint에서 파싱할 수 있도록 해주는 파서",
    "copyright": "Copyright TypeScript ESLint Team"
  },
  {
    "name": "eslint",
    "license": "MIT",
    "uses": "JavaScript 및 TypeScript 코드의 일관성과 품질을 보장하는 도구",
    "copyright": "Copyright OpenJS Foundation and contributors"
  },
  {
    "name": "eslint-config-prettier",
    "license": "MIT",
    "uses": "Prettier와 ESLint 간 충돌을 해결하기 위한 구성 파일",
    "copyright": "Copyright Prettier contributors"
  },
  {
    "name": "eslint-plugin-prettier",
    "license": "MIT",
    "uses": "ESLint에서 Prettier 규칙을 실행하는 플러그인",
    "copyright": "Copyright Prettier contributors"
  },
  {
    "name": "jest",
    "license": "MIT",
    "uses": "JavaScript 및 TypeScript 테스트를 위한 테스트 프레임워크",
    "copyright": "Copyright Meta"
  },
  {
    "name": "prettier",
    "license": "MIT",
    "uses": "코드의 일관된 스타일링을 자동으로 맞춰주는 코드 포매터",
    "copyright": "Copyright Prettier contributors"
  },
  {
    "name": "source-map-support",
    "license": "MIT",
    "uses": "소스맵을 지원하여 디버깅을 쉽게 해주는 라이브러리",
    "copyright": "Copyright V8 Project"
  },
  {
    "name": "supertest",
    "license": "MIT",
    "uses": "HTTP 서버에 대한 테스트를 쉽게 할 수 있게 도와주는 라이브러리",
    "copyright": "Copyright Visionmedia"
  },
  {
    "name": "ts-jest",
    "license": "MIT",
    "uses": "Jest에서 TypeScript 코드를 테스트할 수 있게 해주는 라이브러리",
    "copyright": "Copyright Jest Community"
  },
  {
    "name": "ts-loader",
    "license": "MIT",
    "uses": "Webpack에서 TypeScript 파일을 로드할 수 있도록 해주는 로더",
    "copyright": "Copyright TypeStrong"
  },
  {
    "name": "ts-node",
    "license": "MIT",
    "uses": "Node.js 환경에서 TypeScript 코드를 직접 실행할 수 있게 해주는 라이브러리",
    "copyright": "Copyright Nathan Rajlich"
  },
  {
    "name": "tsconfig-paths",
    "license": "MIT",
    "uses": "TypeScript 경로 별칭을 런타임에서 지원하는 유틸리티 라이브러리",
    "copyright": "Copyright TypeStrong"
  },
  {
    "name": "typescript",
    "license": "Apache-2.0",
    "uses": "TypeScript 언어와 컴파일러",
    "copyright": "Copyright Microsoft"
  }
]
