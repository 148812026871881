import { GridBox } from '@/components';
import Banner from '@/components/Banner';
import GenreSelector from '@/components/GenreSelector'
import MainRanking from '@/components/MainPage/Ranking';
import MainRecommend from '@/components/MainPage/Recommend';
import NovelItem from '@/components/NovelItem';
import NovelItemSmall from '@/components/NovelItemSmall';
import { useUser } from '@/stores';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 134px 0;


  .novel-slider {
    display: flex;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
  }
`




export default function MainPage () {
  const [searchParams, setSearchParams] = useSearchParams();

  const genreParam = searchParams.get('genre');

  const { user } = useUser();


  return (
    <Container>
      <GenreSelector/>
      <div className='mt-20'/>
      
      {genreParam == '추천' && <MainRecommend user={user}/>}

      {genreParam !== '추천' && <MainRanking user={user}/>}
    </Container>
  )
}1