import { fetchGetSearchWordList } from '@/api/search';
import { ic_search_white } from '@/assets'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;

  z-index: 999;
  padding-top: 60px;

  .searchBox {
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    
    background-color: #000;
    position: fixed;
  }

  

  .header_menu_btn {
    img {
      width: 24px;
    }
    height: 24px;
    width: 24px;
  }


  .searchBar {
    input {
      width: 100%;
      background-color: rgba(255,255,255,0.3);
      padding-left: 12px;
      border: none;
      height: 40px;
      color: lightgrey;
      font-size: 18px;
      border-radius: 8px;

      ::placeholder {
        color: grey;
      }
    }
  }

  .horizontal-scroll {
    display: flex;        /* flexbox를 사용하여 아이템을 가로로 배치 */
    overflow-x: auto;     /* 가로 스크롤 활성화 */
    white-space: nowrap;  /* 텍스트 줄바꿈 방지, 아이템이 한 줄에 표시되도록 함 */
  }

  .searchHistoryItem {
    padding: 12px;
    margin-right: 12px;
    background-color: #f0f0f0;
    border-radius: 4px;
    flex: 0 0 auto;       /* 아이템의 크기가 부모 요소의 크기에 따라 줄어들지 않도록 설정 */
  }

  .deleteButton {
    border: 0;
  }

  .searchWordList {
    position: fixed;
    top: 60px;
    width: 100vw;
    height: calc(100vh - 60px);
    background-color: #fff;
    overflow-y: auto;

    .resultBox {
      width: 100%;
      padding: 8px 16px;
      background-color: #e9ecef;
      color: grey;
    }

    .searchItem {
      width: 100%;
      padding: 16px 16px;
      border-bottom: 1px solid #e2e2e2;
      font-size: 18px;
    }
  }
`


export default function SearchMenu ({
  onClose
}) {
  const [searchText, setSearchText] = useState('');
  const [searchHistory, setSearchHistory] = useState(getSearchHistory());
  const [searchResult, setSearchResult] = useState({
    label_list: [],
    novel_list: [],
    writer_list: []
  })


  function submit(text, type = 'novel') {
    if(!text) return;
    else {
      setSearchText('');
      let searchHistoryData = getSearchHistory();

      // 기존 히스토리 없을 시
      if(!searchHistoryData.includes(text)) searchHistoryData = [text, ...searchHistoryData]
      else { // 있을 경우
        searchHistoryData = searchHistoryData.filter(item => item !== text);
        searchHistoryData = [text, ...searchHistoryData]
      }
      
      setSearchHistory(searchHistoryData);
      localStorage.setItem('searchHistory', JSON.stringify(searchHistoryData));
      submitSearch(text, type);
    }
  }


  function getSearchHistory() {
    let searchHistory = JSON.parse(localStorage.getItem('searchHistory')) ?? [];
    return searchHistory;
  }


  function clearSearchHistory() {
    localStorage.removeItem('searchHistory');
    setSearchHistory([])
  }


  function handleDelete(item) {
    const updatedHistory = searchHistory.filter(historyItem => historyItem !== item);
    setSearchHistory(updatedHistory);
    localStorage.setItem('searchHistory', JSON.stringify(updatedHistory)); // 역순으로 저장
  }

  const navigate = useNavigate();


  function submitSearch(text, type) {
    navigate(`/search?text=${text}&type=${type}`, { replace: true });
    onClose();
  }


  useEffect(() => {
    if(searchText) getSearchWordList(searchText);
  }, [searchText]);

  // 검색어 가져오기
  async function getSearchWordList(text) {
    const result = await fetchGetSearchWordList(text);

    setSearchResult(result);
  }

  return (
    <Container>
      <div className='searchBox flex align-center px-16'>
        <div className='flex-1 searchBar'>
          <input
            // type="search"
            value={searchText}
            onChange={e => {
              if(e.target.value.length < 15) 
              setSearchText(e.target.value)
            }}
            placeholder='작품명, 작가명, 출판사명 검색'
          />
        </div>
        
        <div className='header_menu_btn mx-12'>
          <img  src={ic_search_white} onClick={() => submit(searchText)}/>
        </div>

        <div className='c-white pointer font-20 medium' onClick={onClose}>취소</div>
      </div>


      {
        (searchResult?.writer_list.length + 
        searchResult?.novel_list.length + 
        searchResult?.label_list.length) > 0 && 
        <div className='searchWordList'>
          {
            searchResult?.novel_list.length > 0 && 
              <div>
                <div className='resultBox medium'>작품</div>

                {
                  searchResult?.novel_list?.map((item, idx) => (
                    <div className='searchItem' key={`novelword-${item}`} onClick={() => submit(item, 'NOVEL')}>{item}</div>
                  ))
                }
              </div>
          }
          
          {
            searchResult?.writer_list.length > 0 && 
              <div>   
                <div className='resultBox medium'>작가</div>
                
                {
                  searchResult?.writer_list?.map((item, idx) => (
                    <div className='searchItem' key={`writerword-${item}`} onClick={() => submit(item, 'WRITER')}>{item}</div>
                  ))
                }
              </div>
          }

          {
            searchResult?.label_list.length > 0 && 
              <div>
                <div className='resultBox medium'>출판사</div>
                {
                  searchResult?.label_list?.map((item, idx) => (
                    <div className='searchItem' key={`labelword-${item}`} onClick={() => submit(item, 'LABEL')}>{item}</div>
                  ))
                }
              </div>
          }
        </div>
      }

      <InnerContainer className="px-16 w-full">
        <div className='flex-center w-full pt-20'>
          <div className='font-20 bold flex-1'>검색 기록</div>
          <div className='font-18 c-grey100 medium' onClick={clearSearchHistory}>전체 삭제</div>
        </div>


        {/* 검색 기록 리스트 */}
        <div className='w-full mt-12 horizontal-scroll font-16'>
          {searchHistory?.map((item, idx) => (
            <div className='searchHistoryItem flex' key={`search-history-${idx}-${item}`}>
              <div onClick={() => submit(item)}>{item}</div>
              <button className='ml-4 bold deleteButton' onClick={() => handleDelete(item)}>X</button>
            </div>
          ))}
        </div>
      </InnerContainer>
    </Container>
  )
}


const InnerContainer = styled.div`

`