import { useHideLayout, useInfiniteScroll } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useNoticeList } from '@/stores/noticeList';
import { formatDateYMD } from '@/utils/format';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 0;
  
  .no-data {
    width: 100%;
    height: calc(80vh - 60px);
  }
`

const Li = styled.div`
  padding: 20px;
  font-size: 16px;
  border-bottom: 1px solid #dfdfdf;

  .notice-title {
    font-weight: 600;
  }

  .date {
    color: #939393;
  }
`

export default function Page () {
  useHideLayout('header', true);
  const navigate = useNavigate();
  const scrollEnd = useRef();
  const { noticeList, page, handlePage } = useNoticeList();
  useInfiniteScroll(() => handlePage(page + 1), scrollEnd);

  return (
    <Container>
      <TitleHeader title={"공지사항"}/>

      <div>
        {noticeList.list?.map((notice) => (
          <NoticeItem key={notice._id} title={notice.title} date={formatDateYMD(notice.created_date)} onClick={() => navigate(`/notice/${notice._id}`)} />
        ))}
        {noticeList.list.length < noticeList.totalCount && <div ref={scrollEnd} />}
      </div>
      
      {noticeList.list?.length === 0 && 
        <div className='no-data text-center flex-center flex-col'>
          <div className='bold font-20'>공지사항이 없습니다.</div>
        </div>
      }
    </Container>
  )
}

function NoticeItem ({ title, date, onClick }) {
  return (
    <Li onClick={onClick}>
      <div className='notice-title'>{title}</div>
      <div className='date mt-8'>{date}</div>
    </Li>
  )
}
