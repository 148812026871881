import styled from 'styled-components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getTheme } from '@/styles/theme';
import { useEffect, useState } from 'react';
import { fetchGenreList } from '@/api/genre';
import useShallowScroll from '@/hooks/useShallowScroll';

const Container = styled.div`
  position: fixed;
  top: 96px;
  z-index: 20;
  width: 100%;
  border-bottom: 1px solid lightgrey;
  border-top: 1px solid lightgrey;
  background-color: #fff;

  .inner_container {
    width: 100%;
    overflow-x: auto;
    display: flex;
    white-space: nowrap;
    max-width: 500px;
  }

  .genre-item {
    font-size: 12px;
    padding: 10px 0px;
    margin: 0px 10px;
    cursor: pointer;
    color: black;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-bottom 0.3s;
    background-color: #fff;
    flex-shrink: 0; /* Prevent items from shrinking */
  }

  .genre-item.active {
    font-weight: 800;
    color: ${getTheme('primary')};
    border-bottom: 2px solid ${getTheme('primary')};
  }

  .genre-item:not(.active):hover {
    color: gray;
  }
`

const Dump = styled.div`
  width: 100%;
  height: 1px;  
  position: fixed;
  z-index: 20;
  top: 96px;
  background-color: 1px solid lightgrey;
`


const GenreList = [
  { name: '추천', value: '추천' }, 
  { name: '신작', value: '신작' }
]

const RankingGenreList = [
  { name: '전체', value: '전체' }, 
  { name: '신작', value: '신작' }
]


// isRankingPage

// 
export default function GenreSelector ({ isRankingPage=false }) {
  const [genreDataList, setGenreDataList] = useState([]);

  const [activeGenre, setActiveGenre] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  
	const location = useLocation();

  useEffect(() => {
    getGenreList();
  }, [])

  useEffect(() => {
    const genre = searchParams.get('genre');
    if (genre) {
      setActiveGenre(genre);
    } else {
      const InitValue = (isRankingPage ? RankingGenreList : GenreList)[0];
      handleGenreClick(InitValue)
    }
  }, [searchParams]);


  const getGenreList = async () => {
    const list = await fetchGenreList();
    setGenreDataList(list.map(item => {
      return {
        name: item.name,
        value: item._id
      }
    }))
  }

  const handleGenreClick = (genre) => {
    setActiveGenre(genre.name);
    setSearchParams({ genre: genre.name });
    navigate(`?genre=${genre.name}`);
  };

  
  const handleGenreNavigate = (genre) => {
  	const urlPathname = location.pathname;
    const exceptionList = ["/", "/rank", "/stalier"]

    console.log(urlPathname)
    
    setActiveGenre(genre.name);
    setSearchParams({ genre: genre.name });

    if(exceptionList.includes(urlPathname))
      navigate(`?genre=${genre.name}`);
    else navigate(`/?genre=${genre.name}`);
  };


  const { isScrollingUp, isTop } = useShallowScroll();
  return (
    <>
      {(isScrollingUp || isTop) ? <Container className='flex-center'>
        <div className='inner_container'>
          {(isRankingPage ? 
            [...RankingGenreList, ...genreDataList] : 
            [...GenreList, ...genreDataList]
          ).map((genre) => (
            <div
              key={genre.name}
              className={`bold font-14 genre-item ${activeGenre === genre.name ? 'active' : ''}`}
              onClick={() => handleGenreNavigate(genre)}
            >
              {genre.name}
            </div>
          ))}
        </div>
      </Container>
      : 
      <Dump></Dump>
      }
    </>
  )
}