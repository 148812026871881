import { formatImageUri } from '@/utils/format';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import NeedAuthAdultModal from '../Auth/NeedAuthAdultModal';
import { useState } from 'react';
import { getTheme } from '@/styles/theme';
import { book_cover_sample, ic_adult, ic_odi, ic_promotion_0, ic_promotion_1, sample_adult } from '@/assets';


const Container = styled.div`
  .adult_cover {
    border: 1px solid #d3d3d3;
  }

  .book_cover {
    border-radius: 12px;
    aspect-ratio: 80 / 108;
    object-fit: cover;
  }

  .adultMark {
    position: absolute;
    width: 32px;
    height: 32px;
    top: 4px;
    right: 4px;
  }
    
  
  .promotionMark {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 2px;
    right: 2px;
  }

  .adult_cover {
    width: 100%;
    aspect-ratio: 80 / 108;
    margin-bottom: 10px;
    border-radius: 12px;
    object-fit: cover;
    background-color: #000;
  }
`


export default function NovelItem ({ 
  book_cover, 
  ageGrade, 
  title, 
  isAudioBook,
  uuid, 
  isBook, 
  hasTimedeal=false, // 타임딜인지
  hasTimeFreeRent=false, // 스확시간인지
  userAdultCheck=false,
}) {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);



  return (
    <>
      <NeedAuthAdultModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />

      <Container className='w-full pointer relative' onClick={() => {
        if(ageGrade === "19세 이용가") {
          if(!userAdultCheck) setIsOpen(true);
          else navigate(`/novel/detail/${uuid}`, { replace: false});
        }
        else navigate(`/novel/detail/${uuid}`, { replace: false})
      }}>
        <div className='w-full'>
          {
            ageGrade === "19세 이용가" && !userAdultCheck ?
            <img 
              src={sample_adult} 
              className='w-full book_cover adult_cover'
            />
            :
            <img 
              src={book_cover ? formatImageUri(book_cover) : book_cover_sample} 
              className='w-full book_cover'
            />
          }
        </div>
        

        {hasTimedeal && 
          <img className='promotionMark' src={ic_promotion_0}/>
        }

        
        {hasTimeFreeRent && 
          <img className='promotionMark' src={ic_promotion_1}/>
        }


        {(ageGrade === "19세 이용가" && userAdultCheck) &&
          <img className='adultMark' src={ic_adult}/>
        }

        <div className='font-14 mt-6 medium ellipsis-1'>{isBook ? "[단행본] " : ""}{title}</div>
        <div className='font-12 mt-4 exlight'>
          <span>웹소설</span>
          {isAudioBook && <span>, 오디오북</span>}
        </div>
      </Container>
    </>
  )
}