import { useNovelList } from '@/stores/novelList';
import styled from 'styled-components'
import { GridBox } from '..';
import NovelItem from '../NovelItem';
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';
import { useUser } from '@/stores';


const Container = styled.div`

`


export default function MainRanking ({ user }) {
  const { 
    genre,
    novelList, 
    hasMore,
    goToNextPage
  } = useNovelList();

  const [ref, inView] = useInView({ threshold: 1 });


  useEffect(() => {
    if (inView && hasMore) {
      goToNextPage();
    }
  }, [inView, hasMore]);

  return (
    <Container>
      <div className='pl-16 font-20 black'>
        <span>{genre} 중 </span>
        <span className='c-primary'>실시간 랭킹</span>
      </div>
      
      
      {/* 랭킹 리스트 */}
      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          <>
            {novelList?.map((item, key) => 
              <NovelItem {...item} key={key} userAdultCheck={user?.adultVerification}/>
            )}
          </>
          {hasMore && <div ref={ref}></div>}
        </GridBox>
      </div>
    </Container>
  )
}