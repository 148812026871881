import { ic_adult } from '@/assets';
import { Button } from '@/components';
import axiosInstance from '@/lib/axios';
import { useUser } from '@/stores';
// import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 180px;
`


export default function Page () {
  const navigate = useNavigate();
  const ref = useRef(null);
  const [sEncDataS, setSEncData] = useState(null)

  const { refreshUserData } = useUser();

  useEffect(() => {
    adultCheck();  
  }, []);

  async function adultCheck () {
    const user = (await refreshUserData());

    console.log(user);

    if(user?.adultVerification) {
      alert('이미 인증된 회원입니다.');
      window.location.href = '/'
    }
  }


  const onClickCertify = async () => {
    const { form } = document;
    const left = screen.width / 2 - 500 / 2;
    const top = screen.height / 2 - 800 / 2;
    const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;
    // const returnUrl = 'https://dev.api.oddnary.co.kr/users/module/checkplus_success';  // 본인인증 결과를 전달받을 api url
    // const redirectUrl = `${import.meta.env.VITE_HOST_URL}/api/nice`;  // 본인인증 결과를 전달받을 api url

    // 위에서 언급했던 token api가 요청 데이터를 암호화한 후 표준창 호출에 필요한 데이터를 응답해준다.
    
    const res = await axiosInstance.get('/checkplus/formdata');

    if (form && res.data) {
      try {
        const { enc_data, integrity, token_version_id } = res.data;
        // const popup = window.open('', 'nicePopup', option);
  
        // form.target = 'nicePopup';
        form.enc_data.value = enc_data;
        form.token_version_id.value = token_version_id;
        form.integrity_value.value = integrity;

        localStorage.setItem('req_no', res.data.req_no);
        localStorage.setItem('key', res.data.key);
        localStorage.setItem('iv', res.data.iv);
        localStorage.setItem('hmac_key', res.data.hmac_key);

        
        form.submit();
      } catch (e) {
        console.log(e);
      }
    }


  };

  

  
  return (
    <Container>

      <form name="form" id="form" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb" method='post'>
        <input type="hidden" id="m" name="m" value="service" />
        <input type="hidden" id="token_version_id" name="token_version_id" value="" />
        <input type="hidden" id="enc_data" name="enc_data" />
        <input type="hidden" id="integrity_value" name="integrity_value" />

        {/* <input type="hidden" name="EncodeData" value={sEncDataS.enc_data}/> */}
      </form>

      <InnerContainer className="w-full flex-col align-center">
        <img style={{width: '160px'}} src={ic_adult}/>

        <div className='font-24 text-center bold line-36 mt-40 mb-40'>
          연령 제한 콘텐츠를 열람하기 위해<br/>
          <span className='exbold c-primary'>성인인증</span>이 필요합니다.  
        </div>
        <Button onClick={onClickCertify}>인증하기</Button>

      </InnerContainer>
    </Container>
  )
}

const InnerContainer = styled.div`

`