import styled from "styled-components"
import { useRef, useState } from "react";
import { GridBox } from "@/components";

const FileUploadContainer = styled.div`
  width: 100%;
  color: #1a1a1a;
  
  .fileUploadBox {  
    border-radius: 24px;
    background-color: #f8f8f8;
    width: 100%;
  }

  .fileUploadUI {
    background-color: #000;
    border-radius: 4px;
    width: 91px;
    height: 28px;
  }

  .fileUploadBox * {pointer-events: none;}

  .uploadIcon {
    height: 20px;
  }
  
  .fileList {
    overflow-y: scroll;
    max-height: 162px;
    padding: 16px;
    
  }

  .IconCircle {
    width: 60px;
    height: 60px;
    background-color: #fff;
    border-radius: 50%;
  }

  .t {
    color: #727272;
  }

  .preview {
    width: 150px;
    height: 150px;
  }
`

export const FileArrayUpload = ({ idx, label, className='', text, accept, guide, setEfiles, maxSizeStr }) => {  

  const drop = useRef(null);
  const [dragging, setDragging] = useState(false);
  
  const [filenames, setFilenames] = useState([])

  const [imgFile, setImgFile] = useState([]);


  const onUpload = (files) => { // 파일 업로드 시 처리

    if(files.length > 5) return alert('업로드는 5개 이하만 가능합니다.')

    let fileURLs = []
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
    
      let reader = new FileReader();
      reader.onload = () => {
        console.log(reader.result);
        fileURLs[i] = reader.result;
        setImgFile([...fileURLs]);
      };

      reader.readAsDataURL(file);
    }

    let AcceptType = [...accept.split(',')]
    if([...files].length >= 200) return alert('한 번에 200개 이하로 올려주세요.')
    let f = [...files].filter(item => {
      if(item.size >= 31457280) {
        alert('30MB를 초과한 파일이 필터링되었습니다.')
        return false
      } 
      else return AcceptType.includes(item.type) && item.size < 31457280
    })

    let names = [...f].map(item => item.name)
    
    setEfiles(f)
    setFilenames(names)

  };

  
  return (
    <FileUploadContainer className={`flex-col` + className}>
      
      {imgFile.length !== 0 && 
        <GridBox col={5}>
          {
            imgFile.slice(0,5).map((post, key) => (
              <img className="preview mb-24" src={post}/>
            ))
          }
        </GridBox>
      }

      <label className="fileUploadUI flex-center pointer c-white font-14" htmlFor={`fileUpload${idx}`}>
        파일 업로드
      </label>

      <input
        id={`fileUpload${idx}`} 
        type="file" 
        accept={accept}
        style={{display: 'none'}}
        onChange={e => {
          onUpload(e.target.files)
        }}
        multiple
      />
    </FileUploadContainer>
  )
}