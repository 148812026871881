import { social_google } from '@/assets'
import styled from 'styled-components'
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import axiosInstance from '@/lib/axios';
import { useUser } from '@/stores';


const Container = styled.div`
  width: 250px;
  max-width: 80vw;

  border: 1px solid #000;
  border-radius: 80px;
  height: 50px;
  
  .social_icon {
    width: 24px;
    height: 24px;
    left: 16px;
  }
`


export default function GoogleLoginButton () {
  const { userSocialSignin } = useUser();

  const submit = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log('Login Success:', tokenResponse);
      
      try {
        const { access_token } = tokenResponse;
        
        const userInfoResponse = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo', {
          headers: {
            Authorization: `Bearer ${access_token}`
          }
        });

        const socialId = userInfoResponse.data.id;
        const socialEmail = userInfoResponse.data.email;

        // Google에서 받은 access_token을 API 서버로 전송
        // const response = await axios.post('http://your-api-server.com/auth/google', {
        //   token: access_token,
        // });

        const response = await axiosInstance.post('/userauth/oauth', {
          email: socialEmail,
          socialId,
          loginType: "GOOGLE",
          socialToken: access_token
        });

        // response status 확인
        if(response.status === 201) {
          const result = await userSocialSignin(response.data.accessToken)
          if(result) window.location.href = '/';
          else alert('로그인에 실패했습니다.');
        }
        else if(response.status === 203) {
          localStorage.setItem('user_data', JSON.stringify({ email: socialEmail }))
          localStorage.setItem('user_access_token', response.data.accessToken)

          window.location.href = '/social/info';
        }
      } catch (error) {
        console.error('Failed to communicate with API server:', error);
      }
    },
    onError: (error) => {
      console.error('Login Failed:', error);
    },
  });

  return (
    // <Container className='flex-center relative pointer' onClick={submit}>
    //   <img className='social_icon absolute' src={social_google}/>
    //   <div className='pl-24 medium font-18'>구글 계정으로 로그인</div>
    // </Container>
    
    <CircleContainer onClick={submit}>
      <img className='social_icon pointer' src={social_google}/>

    </CircleContainer>
  )
}


const CircleContainer = styled.div`
  width: 36px;
  height: 36px;

  .social_icon {
    width: 36px;
    border-radius: 50%;
  }
`