import { pick, isNil } from 'lodash';

export const validateEmail = (value) => {
  const emailRule = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!value) return false;
  if (value.length === 0 || emailRule.test(value)) return true;
  return false;
};

export const validatePhone = (value) => {
  const phoneRule = /^\d{2,3}-?\d{3,4}-?\d{4}$/;
  if (!value) return false;
  if (value.length === 0 || phoneRule.test(value)) return true;
  return false;
};


export const validateDateOfBirth = (dob) => {
  // 정규식을 통해 YYYY.MM.DD 형식과 10글자인지 확인
  const datePattern = /^\d{4}\.\d{2}\.\d{2}$/;

  if (!datePattern.test(dob)) {
    return false; // 형식이 맞지 않음
  }

  // 날짜 문자열을 분리
  const [year, month, day] = dob.split('.').map(Number);

  // 연도 확인 (1900년 ~ 현재 연도)
  const currentYear = new Date().getFullYear();
  if (year < 1900 || year > currentYear) {
    return false; // 유효하지 않은 연도
  }

  // 월 확인 (1 ~ 12)
  if (month < 1 || month > 12) {
    return false; // 유효하지 않은 월
  }

  // 일 확인 (월에 따라 최대 일수 확인)
  const daysInMonth = new Date(year, month, 0).getDate(); // 해당 월의 마지막 일자
  if (day < 1 || day > daysInMonth) {
    return false; // 유효하지 않은 일
  }

  return true; // 유효한 생년월일
}

export const validateNumber = (value) => {
  if(!value) return false;
  console.log(value)

  const numberRule = /^[0-9]+$/;

  if (!value) return false;
  if (value.length === 0 || numberRule.test(value)) return true;
  return false;
};



export const validatePhoneNumber = (value) => {
  const phoneRule = /^010\d{8}$/;
  if (!value) return false;
  if (value.length === 0 || phoneRule.test(value)) return true;
  return false;
};


export const  validatePassword = (password) => {
  // 최소 8자, 최대 20자, 영문 대소문자, 숫자, 특수기호 포함 확인하는 정규식
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,20}$/;
  if(!password) return false;
  if (passwordRegex.test(password)) 
    return true;  // 비밀번호가 유효함
  else
    return false; // 비밀번호가 유효하지 않음
}