import { GridBox } from '@/components';
import AD from '@/components/AD';
import GenreSelector from '@/components/GenreSelector'
import NovelItem from '@/components/NovelItem';
import NovelItemSmall from '@/components/NovelItemSmall';
import { useInfiniteScroll } from '@/hooks';
import { useUser } from '@/stores';
import { useNovelList } from '@/stores/novelList';
import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 134px 0;


  .c-575757 {
    color: #575757;
  }
`




export default function Page () {
  const { novelList, handlePage, page, hasMore, isLoading } = useNovelList();

  const [searchParams, setSearchParams] = useSearchParams();

  const genreParam = searchParams.get('genre');
  const scrollEnd = useRef();

  useInfiniteScroll(() => handlePage(page + 1), scrollEnd);

  const { user } = useUser('rank');


  return (
    <Container>
      <GenreSelector isRankingPage={true}/>

      <div className='mt-20'/>
      <AD/>

      <div className='px-16 font-20 black mt-40 flex align-end'>
        <span className='mr-6'>{genreParam} 웹소설</span>
        <span className='c-primary'>랭킹</span>

        <div className='flex-1'/>

        {/* <div className='font-12 c-575757 exlight'>2024.07.02 12:00 AM 기준</div> */}
      </div>
   
      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          {novelList?.map((item, key) => 
            <NovelItem {...item} key={key} userAdultCheck={user?.adultVerification}/>
          )}
        </GridBox>

        {hasMore && <div ref={scrollEnd} />}
      </div>


    </Container>
  )
}