import React, { useState } from 'react';
import styled from 'styled-components';
import { useUser } from '@/stores';
import { Button, GridBox, LabelInput } from '@/components';
import { main_logo } from '@/assets';
import { useHideLayout, useIOSCheck } from '@/hooks';
import { getTheme } from '@/styles/theme';
import { Link } from 'react-router-dom';
import GoogleLoginButton from '@/components/Login/GoogleLoginButton';
import KakaoLoginButton from '@/components/Login/KakaoLoginButton';
import NaverLoginButton from '@/components/Login/NaverLoginButton';
import AppleLoginButton from '@/components/Login/AppleLoginButton';
import GoogleLoginButtonDump from '@/components/Login/GoogleLoginButtonDump';
import LoadingCover from '@/components/Loading/cover';

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding-top: 94px;

  .inputBox {
    padding: 60px 0;
    input {
      background-color: #fff !important;
    }
  }

  
  .logoBar {
    width: 100%;
    height: 94px;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
  }

  .loginBox {
    border-radius: 20px;
    background-color: #fff;
    padding: 0 32px;
  }

  .c-c3c3c3 {
    color: #c3c3c3;
  }

  .loginBar {
    width: 60vw;
    height: 2px;
    background-color: ${getTheme('primary')};
    margin: 24px auto;
  }
`;

function LoginPage() {
  const { userLocalSignin } = useUser()

  const [state, setState] = useState(true)
  
  const [id, setId] = useState('')
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  async function getLogin() {
    setIsLoading(true);
    
    const result = await userLocalSignin({ id, password });

    if(result) {
      setIsLoading(false);
      window.location.href ='/'
    }
    else {
      setIsLoading(false);
      setState(false)
    }
  }

  useHideLayout('header', true);


  function renderGoogleLogin() {
    const result = localStorage.getItem('device');
    console.log(result)
    if(result) {
      if(result === "ANDROID") return <GoogleLoginButtonDump/>;
      else if(result === "IOS") return <GoogleLoginButtonDump/>;
      else return <GoogleLoginButton/>
    }
    else return <GoogleLoginButton/>;
  }


  const isIOS = useIOSCheck();

  return (
    <Container className='flex-col flex-center'>
      {isLoading && <LoadingCover/>}

      <div className='inputBox w-full'>
        <div className='flex-center logoBar'>
          <img style={{width: '152px'}} className='' src={main_logo}/>
        </div>
        
        <div className='loginBox'>
          <div className='mb-80'>
            <div className='font-30 c-primary100 black'>LOGIN</div>
            <div className='font-24 exbold'>스텔라에 어서오세요!</div>
          </div>


          <LabelInput label={"이메일"} placeholder="이메일을 입력해주세요." value={id} onChange={e => setId(e.target.value)}/>

          <div className='mt-18'/>
          <LabelInput label={"비밀번호"} placeholder="비밀번호를 입력해주세요." value={password} onChange={e => setPassword(e.target.value)} type="password"/>
          {!state && <div className='font-16 bold text-center mt-16' style={{width: '100%', color: '#ff4545'}}>아이디와 비밀번호를 다시 확인해주세요.</div>}
          
          <div className='mt-40'></div>
          <Button background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={getLogin}>로그인</Button>
          

          <div className='text-center mt-24'>
            <div className='font-14 mb-4 medium c-c3c3c3'>스텔라가 처음이시라면?</div>
            <Link to='/signup' className='bold c-primary100 mt-4'>회원가입</Link>
          </div>
          
        </div>
        
        <div className='loginBar'/>


        <div className='loginBox'>
          <div className='c-c3c3c3 bold font-24 text-center mb-24'>SNS계정이 있나요?</div>

          {/* <div className='socialButton flex-center flex-col'>
            {renderGoogleLogin()}
            <div className='mt-16'/>
        
        
            <KakaoLoginButton/>
            <div className='mt-16'/>
            <NaverLoginButton/>
          </div> */}
        
          <div className='flex-center'>
            {renderGoogleLogin()}
            {isIOS && <>
              <div className='mx-10'/>
              <AppleLoginButton/>
            </>}

            <div className='mx-10'/>
            <KakaoLoginButton/>
            <div className='mx-10'/>
            <NaverLoginButton/>
          </div>
        </div>
      </div>
    </Container>
  )
}


export default LoginPage