import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Firebase 설정 객체
export const firebaseConfig = {
  apiKey: "AIzaSyBV55rX1Rxbwacy1345WXAbSl88kcHkvqo",
  authDomain: "stella-71d54.firebaseapp.com",
  projectId: "stella-71d54",
  storageBucket: "stella-71d54.appspot.com",
  messagingSenderId: "95652050903",
  appId: "1:95652050903:web:d03281149fcd3bb65b3d92",
  measurementId: "G-DEP0VENL90"

};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
