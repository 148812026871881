import { useState, useEffect } from "react";

function useScroll() {
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [isTop, setIsTop] = useState(true); // 맨 위 상태 추가
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const scrollY = window.scrollY; // 현재 스크롤 위치
    const windowHeight = window.innerHeight; // 창 높이
    const fullHeight = document.documentElement.scrollHeight; // 문서 전체 높이

    // 스크롤 방향 감지 (스크롤 위쪽)
    if (scrollY < lastScrollY) {
      setIsScrollingUp(true);
    } else {
      setIsScrollingUp(false);
    }

    // 스크롤이 페이지 맨 아래에 있는지 감지
    if (Math.abs(fullHeight - (scrollY + windowHeight)) < 1) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }

    // 스크롤이 페이지 맨 위에 있는지 감지
    if (scrollY === 0) {
      setIsTop(true);
    } else {
      setIsTop(false);
    }

    // 마지막 스크롤 위치 업데이트
    setLastScrollY(scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    // 이벤트 리스너 해제
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return { isScrollingUp, isBottom, isTop };
}

export default useScroll;
