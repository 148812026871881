import styled from 'styled-components'
import { Button, Modal } from '..'
import { ic_odi } from '@/assets'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { getTheme } from '@/styles/theme'
import { fetchPermanentNovel } from '@/api/payment'
import { fetchUseRentTicket } from '@/api/rent'
import { useUser } from '@/stores'


const Container = styled.div`
  .odi_modal_box {
    border: solid 2px #e1e1e1;
    border-radius: 4px;

    .odi_image {
      width: 24px;
    }


    .price_box {
      width: 60px;
      height: 24px;
      border-radius: 4px;
      border-width: 1px;
    }
  }
`


export default function EpisodePaymentModal ({
  isOpen,
  onClose,
  episode_data,
  rentTicketList,
  popRentTicektList,
  openEnoughModal,
  navigatgeItem,
  user
}) {
  const navigate = useNavigate();
  const { refreshUserData } = useUser()
0
  // 소장하기
  async function submitPermanent() {
    const { novel_id } = episode_data;
    // 현재 소지 재화
    const previousQuantity = user?.currentBalance;

    // 가격
    const episode_price_amount = episode_data?.episode_price_amount ?? 1;

    if(previousQuantity < episode_price_amount) {
      // 모달 띄우기
      openEnoughModal();
    }
    
    else {
      try {
        const result = (await fetchPermanentNovel(novel_id, episode_data._id, previousQuantity, episode_price_amount))
        
        await refreshUserData();
    
        navigatgeItem(episode_data.uuid, { state: { read_ticket_type: "PERMANENT", read_permanent: result }});
      } catch {
        await refreshUserData();
      }
  
    }

    onClose();
  };

  // 대여하기
  async function submitRent() {
    console.log({rentTicketList})
    if(rentTicketList.length <= 0) {
      alert('보유한 대여권이 없습니다!');
      onClose();
      return;
    }
    
    // 대여권 사용
    await fetchUseRentTicket(
      rentTicketList[0]._id,
      episode_data._id,
      episode_data.novel_id,
    ).then(async () => {
      await popRentTicektList(rentTicketList[0]._id);
    })


    // 넘기기
    navigatgeItem(episode_data.uuid, { state: { read_ticket_type: "RENT", read_rent: rentTicketList[0]._id }});


    onClose();
  };

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose} height={240}>
        <div className='bold font-24 text-center ellipsis w-full'>{episode_data?.novel}</div>

        <div className='medium font-16 py-16 text-center ellipsis w-full'>{episode_data?.title}</div>

        <div className='odi_modal_box flex align-center p-10'>
          <img src={ic_odi} className='odi_image'/>
          <div className='semibold font-12 medium'>남은 스타</div>
          <div className='ml-4 font-14 semibold'>{user?.currentBalance ?? 0}</div>
          <div className='flex-1'/>
          <div className='price_box font-12 pointer flex-center semibold' onClick={() => window.location.href = '/charge'}>충전하기</div>
        </div>

        <div className='mt-8 text-center font-14 medium'>
          사용 가능 대여권
          <span className='bold c-primary'> {rentTicketList.length}장</span>
        </div>

        <div className='flex flex-center mt-20'>
          <div className='flex-1'>
            <Button background={getTheme('primary100')} style={{width: '100%'}} onClick={submitRent}>
              <div className='flex align-end justify-center'>
                <div className='font-16 bold'>대여</div>
                <div className='font-12'>(3일간)</div>
              </div>
            </Button>
          </div>

          <div className='flex-1 ml-8'>
            <Button background={getTheme('primary100')} style={{width: '100%'}} onClick={submitPermanent}>
              <div className='font-18 bold'>소장</div>
              <div className='font-12 mt-2 regular'>스타 {episode_data?.episode_price_amount ?? 1}개</div>
            </Button>
          </div>
        </div>

      </Modal>
    </Container>
  )
}