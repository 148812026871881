import { ic_navigate_back } from '@/assets';
import AD from '@/components/AD';
import GenreSelector from '@/components/GenreSelector';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useStalierData } from '@/stores/stalierData';
import { formatDateYMD, formatImageUri } from '@/utils/format';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 80px;
  
  .book_cover {
    border-radius: 10px;
    width: 100px;
    height: 150px;
  }

  .white-line {
    width: 100%;
    height: 1px;
    background-color: #e7e6e7;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .episode-button {
    margin-top: 12px;
    border: 1.5px solid #5e5f5e;
    height: 52px;
    border-radius: 4px;
  }

  
  .episode-item {
    width: 100%; 
    border-bottom: 1px solid #e7e6e7;
    padding: 10px 20px;
  }
`


const HeaderContainer = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  top: 0;
  height: 60px;
  z-index: 20;
  background-color: #fff;
  border-bottom: 2px solid #e7e6e7;

  .back_image {
    width: 24px;
    left: 24px;
  }

  .wititle {
    width: calc(100vw - 76px);
    text-align: center;
  }
`


export default function StalierPage () {
  const { uid } = useParams();

  const { stalierData, stalierEpisodeList } = useStalierData(uid);
  const [sort_type, set_sort_type] = useState(true);
  
  const navigate = useNavigate();

  function openEpisode(item) {
    navigate(`/stalier/episode/${item.uuid}`)
  }


  useHideLayout('header', true);

  return (
    <Container className='block-draggable'>
      {/* <GenreSelector/> */}
      <HeaderContainer className='flex-center px-24'>
        <img className='absolute back_image pointer' src={ic_navigate_back} onClick={() => {
          const prev_path = localStorage.getItem('previousPath');

          if(prev_path) navigate(prev_path, { replace: true });
          else navigate(-1, { replace: true })
        }}/>
      </HeaderContainer>

      <div className='px-20 mb-20'>
        <div className='flex'>
          {/* Book Cover Image */}
          {stalierData?.book_cover && <img className='book_cover' src={formatImageUri(stalierData?.book_cover)} alt='표지가 없습니다.' />}

          <div className='ml-12'>
            <div className=''>
              <div className='font-16 bold mr-10'>{stalierData?.title}</div>
            </div>
            <div className='font-14 mt-8'>{stalierData?.writer_user?.nickname}</div>

            {!stalierData?.release_note && <div className='mb-44'/>}
            {stalierData?.release_note && <div className='mt-14 mb-20 font-12 bold'>{stalierData?.release_note}</div>}
            
            <div className='mt-18 font-14'>{stalierData?.genre?.name}</div>
            <div className='mt-2 font-12'>#{stalierData?.keywords?.map(item => item.name).join(', #')}</div>
          </div>
        </div>
      </div>


      <div className='px-20 mb-12'>
        <div className='episode-button flex-center font-20 bold pointer' >첫화 보기</div>
      </div>

      <div className='white-box'/>

      <AD/>
      
      <div className='white-box'/>


      {/* Episode */}
      <div className='flex-center px-20 w-full py-10'>
        <div className='flex-1 font-12 flex align-center'>
          <div className="bold">총 {stalierEpisodeList?.length}화</div>
        </div>
        <div className='font-12 flex-center medium'>
          <div className={!sort_type ? 'c-primary' : 'c-878787'} onClick={() => set_sort_type(false)}>회차순</div>
          <div className='mx-2 c-878787 font-10'>|</div>
          <div className={sort_type ? 'c-primary' : 'c-878787'}  onClick={() => set_sort_type(true)}>등록순</div>
        </div>
      </div>

      <div className='white-box'/>

      <div>
        {
          [...stalierEpisodeList]?.sort((a, b) => {
            if(!sort_type) {
              return b?.episode_index - a?.episode_index;
            } else {
              return a?.episode_index - b?.episode_index;
            }
          })?.map((item, idx) => (
            <div className='episode-item w-full flex align-center' key={`episode-${idx}`} onClick={() => openEpisode(item)} style={{
              backgroundColor: item.hasRead ? '#e8e8e8' : '#fff'
            }}>
              <div className="flex-1">
                <div className='medium mb-8 font-14 ellipsis-1 mb-2'>{item.title}</div>
                <div className='font-10 medium c-929292'>{formatDateYMD(item.updated_date)}</div>
              </div>
            </div>
          ))
        }
      </div>
    </Container>
  )
}