import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickOutside } from '@/hooks';
import { dropdown_arrow } from '@/assets/components';
import { getTheme } from '@/styles/theme';

const Container = styled.div`
  min-width: 137px;
  height: 32px;
  padding: 10px;
  border: 0;
  position: relative;
  background-color: #fff;

  .dropImg {
    width: 12px;
  }

  border-radius: 7px;
  border: 1px solid #b9b9b9;
  .dropdown-main {
  font-weight: normal;
  }

  .dropdown-menu {
    width: 100%;
    padding: 10px 16px;
    background-color: #fff;
    border: 1px solid #b9b9b9;
    border-radius: 7px;
    position: absolute;
    z-index: 999;
    top: calc(100% + 4px);
    left: 0;
    max-height: 300px;
    overflow-y: auto;
  }
  .dropdown-item {
    
  }
  .dropdown-item:hover {
    color: ${getTheme('primary')};
  }
`


function Dropdown({ options, label, style, containerStyle, font = 12, select, setSelect, dkey, readOnly=false, ...rest }) {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef()

  useClickOutside(ref, () => setIsOpen(false))

  return (
    <Container ref={ref} style={{...style, ...containerStyle}}>
      <div className='flex-center pointer dropdown-main h-full' onClick={() => {
        if(!readOnly) setIsOpen(isOpen => !isOpen)
      }} {...rest}>
        <div className={`font-${font}`}>{select?.name ?? label}</div>
        <div className='flex-1'/>
        <img src={dropdown_arrow} className={`dropImg ${isOpen && "flip-v"}`}/>
      </div>

      {(!readOnly && isOpen) &&
        <div className='dropdown-menu' style={style}>
          {options?.map((item, key) => (
            <div className={`dropdown-item my-10 font-${font} bold pointer`} key={`${dkey}-dropdown-item-key-${key}`} onClick={() => {
              setSelect(item)
              setIsOpen(false)
            }}>{item.name}</div>
          ))}
        </div>
      }
    </Container>
  );
}

export default Dropdown;
