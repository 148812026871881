import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { fetchNovelGenreList } from '@/api/novel';
import { fetcExposurehNovelListPromotion0, fetchNovelListPromotion1 } from '@/api/promotion';
import { fetchNovelLastViewList } from '@/api/view';


const novelListState = atom({
  key: 'NovelList',
  default: [],
});



export const useNovelList = () => {
  const [novelList, setNovelList] = useRecoilState(novelListState);
  const [genre, setGenre] = useState();
  const [isLoading, setIsLoading] = useState(false);
  
  // 장르 변경 시 초기화 필요
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
    
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    const genreParam = searchParams.get('genre');
    setGenre(genreParam);

    if(genreParam) {
      // 장르 변경 시
      if(genreParam !== genre) {
        console.log('useEffect 호출 - 장르 변경')
        setNovelList([]);
        setPage(1);
        getNovelGenreInitList(genreParam, 1);
      }
      else { // 장르 변경 안되었을 시
        if (genreParam) {
          if(hasMore) getNovelGenreList(genreParam, page);
        }
      }
    }
  }, [searchParams, page]);



  const goToNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  // 리스트 호출
  const getNovelGenreList = async (genre, page) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      console.log("getNovelGenreList", genre, page);
      const genreList = await fetchNovelGenreList(genre, page);
      setNovelList([...novelList, ...genreList.data]); // Pagination 적용 시, Genre, Page 변환에 따라 초기화 필요
      
      // 추가분이 있는지
      setHasMore(genreList.hasMore);
      
    } catch (error) {
      console.error('Failed to fetch novel genre list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 초기화 + 리스트 호출
  const getNovelGenreInitList = async (genre, page) => {
    if(isLoading) return;
    setIsLoading(true);
    try {
      console.log("getNovelGenreInitList", genre, page);
      const genreList = await fetchNovelGenreList(genre, page);
      setNovelList(genreList.data); // Pagination 적용 시, Genre, Page 변환에 따라 초기화 필요
      
      // 추가분이 있는지
      setHasMore(genreList.hasMore);
      
    } catch (error) {
      console.error('Failed to fetch novel genre list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePage = (pageValue) => {
    setPage(pageValue);
  }
  
  
  return {
    novelList,
    page,
    handlePage,
    hasMore,
    genre,
    isLoading,
    goToNextPage,
  };
};
