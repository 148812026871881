import { fetchStarChargeWithCoupon } from '@/api/coin';
import LoadingCover from '@/components/Loading/cover';
import { useHideLayout } from '@/hooks';
import { useUser } from '@/stores';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`

`


export default function Page () {
  useHideLayout('header', true)
  const { uid: coupon_number } = useParams();
  const { refreshUserData } = useUser();

  useEffect(() => {
    submit();
  }, [coupon_number])

  async function submit() {
    try {
      const userData = (await refreshUserData());
      await fetchStarChargeWithCoupon({
        coupon_number,
        previousQuantity: userData?.currentBalance ?? 0,
      })

    } catch {
      alert('로그인 정보를 불러올 수 없었습니다.');
      return window.location.href = '/'
    }
  }
  

  return (
    <Container>
      <LoadingCover/>
    </Container>
  )
}