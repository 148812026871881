import { useHideLayout } from '@/hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 140px;
`


export default function Page () {

  useHideLayout('header', true);

  const navigate = useNavigate(); // useNavigate hook 사용

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/'); // 3초 후에 루트 경로로 이동
    }, 3000);

    return () => {
      clearTimeout(timer); // 컴포넌트 언마운트 시 타이머 정리
    };
  }, [navigate]); // navigate는 리액트 라우터 훅이므로 의존성 배열에 포함

  return (
    <Container>
      <div className='no-data text-center flex-center flex-col'>
        <div className='bold font-20'>충전에 실패했습니다.</div>
        <div className='font-18 mt-20'>3초 뒤 홈 화면으로 돌아갑니다.</div>
      </div>
    </Container>
  )
}