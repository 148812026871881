import { fetchEventList } from '@/api/event';
import { sample_banner } from '@/assets';
import { GridBox } from '@/components';
import EventItem from '@/components/EventItem';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  padding: 60px 0;

  .no-data {
    width: 100%;
    height: calc(80vh - 60px);
  }
`

export default function Page () {
  useHideLayout('header', true);

  const [searchParams, setSearchParams] = useSearchParams();


  const [page, setPage] = useState(searchParams.get('page') ?? 1);

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  // [완료] NovelItem fetch해오기
  async function getData () {
    const { list, totalPage, totalCount } = await fetchEventList(page);
    console.log({list, totalPage, totalCount});
    //[todo]데이터 받는 구조 확인하기
    setList(list);
    setTotal(totalCount);
    setTotalPage(totalPage);
  }

  // [TODO] infinify scroll 추가
  function handlePage (num) {
    setPage(num);
    setSearchParams({ page: num });
  }
  
  useEffect(() => {
    getData();
  }, [page])

  return (
    <Container>
      <TitleHeader title="이벤트"/>

      <div className='px-16 w-full pt-16'>
        <GridBox col={1} gap={20}>
          {list?.map(( notice , key) => 
            <EventItem {...notice} key={key}/>  //구조분해할당으로 EventItem에 넣음
          )}
        </GridBox>
      </div>

      {list?.length === 0 && 
        <div className='no-data text-center flex-center flex-col'>
          <div className='bold font-20'>진행중인 이벤트가 없습니다.</div>
        </div>
      }
    </Container>
  )
}