import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { fetchStalierDetailData, fetchStalierWriterDetailData } from '@/api/stalier';


export const stalierOwnDataState = atom({
  key: 'stalierOwnData',
  default: {},
});



export const useStalierOwnData = (uid) => {
  const [stalierOwnData, setStalierOwnData] = useRecoilState(stalierOwnDataState);
  
  
  
  useEffect(() => {

    if(uid) {
      getStalierData();
      // 스텔리에 에피소드
    }
  }, [uid]);

  
  const getStalierData = async () => {
    const response = await fetchStalierWriterDetailData(uid)
    setStalierOwnData(response);
  }
  
  
  return {
    stalierOwnData
  }
}