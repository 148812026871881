import styled from 'styled-components'
import { Button, Modal } from '..'
import { getTheme } from '@/styles/theme'
import { fetchRentBenefitNovel } from '@/api/rent'
import { fetchJoinNovelPromotionRent } from '@/api/promotion'


const Container = styled.div`

`


export default function TimeFreeRentModal ({
  isOpen,
  onClose,
  novelData,
  timeFreeRent,
  pushRentTicketList,
}) {

  async function submit() {
    await fetchJoinNovelPromotionRent(novelData._id, timeFreeRent).then(response => {
      alert('대여권이 발급되었습니다.');
      // 대여권 리스트 갱신
      pushRentTicketList(response);
    })
    onClose();
  }

  return (
    <Container>
      <Modal isOpen={isOpen} onClose={onClose} height={208}>
        <div className='bold font-24 text-center ellipsis w-full'>스확시간 프로모션 혜택</div>

        <div className='medium font-16 py-16 text-center ellipsis w-full'>
          <div className='font-18 bold'>"{novelData.title}"</div>
          지금 혜택을 시작하면 매일 오후 6시에<br/>대여권 <span className='c-primary bold'>1장</span>을 드립니다.
        </div>

        <div className='flex flex-center mt-20'>
          <div className='flex-1'>
            <Button background={getTheme('black')} style={{width: '100%'}} onClick={onClose}>
              <div className='flex align-end justify-center'>
                <div className='font-16 bold'>건너뛰기</div>
              </div>
            </Button>
          </div>

          <div className='flex-1 ml-8'>
            <Button background={getTheme('primary100')} style={{width: '100%'}} onClick={submit}>
              <div className='font-18 bold'>시작하기</div>
            </Button>
          </div>
        </div>
      </Modal>
    </Container>
  )
}