import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: {} };
  }

  static getDerivedStateFromError(error) {
    // window.location.href = '/'
    return { hasError: true, error: error };
  }

  render() {
    if (this.state.hasError) {
      console.log(this.state.error.toString())
      
      return <div className='ErrorBoundary'>
        {/* {this.state.error.toString()} */}
      </div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;