export const OPENSOURCE_IOS = [
  {
    "name": "Firebase",
    "license": "Apache-2.0",
    "uses": "iOS 애플리케이션을 위한 Firebase 백엔드 서비스",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "FirebaseMessaging",
    "license": "Apache-2.0",
    "uses": "iOS 애플리케이션을 위한 Firebase 클라우드 메시징 기능 제공",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "GoogleSignIn",
    "license": "Apache-2.0",
    "uses": "iOS 애플리케이션에서 Google 계정으로 로그인 기능 제공",
    "copyright": "Copyright Google LLC"
  },
  {
    "name": "Lottie",
    "license": "Apache-2.0",
    "uses": "iOS에서 JSON 기반 애니메이션을 렌더링하는 라이브러리",
    "copyright": "Copyright Airbnb"
  },
  {
    "name": "WKWebView",
    "license": "iOS 기본",
    "uses": "iOS 애플리케이션에서 웹 콘텐츠를 표시하는 웹 뷰 컴포넌트",
    "copyright": "Copyright Apple"
  },
  {
    "name": "NSURLSession",
    "license": "iOS 기본",
    "uses": "iOS 애플리케이션에서 네트워크 요청을 관리하는 기본 통신 프로토콜",
    "copyright": "Copyright Apple"
  },
  {
    "name": "SwiftUI",
    "license": "iOS 기본",
    "uses": "iOS 애플리케이션을 위한 선언적 UI 프레임워크",
    "copyright": "Copyright Apple"
  }
]
