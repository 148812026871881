import { atom, useRecoilState } from 'recoil';
import { useState } from 'react';

import { useNavigate } from 'react-router-dom';


import axios from '@/lib/axios';
import moment from 'moment';
import { fetchSignupUser, fetchUpdateSignupUser } from '@/api/user';

const signupInfoState = atom({
  key: 'SignupInfo',
  default: {
    name: '', // 이름
    nickname: "",
    password: '', // 비밀번호
    email: (JSON.parse(localStorage.getItem('user_data')))?.email, // 이메일
    gender: 0, // 성별
    phone: '', // 전화번호
    phone_confirm: false,
    birth: null, // 생년월일

    agreedToPrivacyPolicy: {
      agreed: false, // 동의 여부 - 약식 동의
      date: null, // 일자 - 
      agreed_date: '', // 일자 - string
      hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
      hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
  		version: '' // 약관
    },

    agreedToServicePolicy: {
      agreed: false, // 동의 여부 - 약식 동의
      date: null, // 일자 - 
      agreed_date: '', // 일자 - string
      hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
      hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
  		version: '' // 약관
    },
    
  },
});


export const useSignupInfo = () => {
  const [signupData, setSignupData] = useRecoilState(signupInfoState);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate()

  // 회원가입 전송
  const submit = async () => {
    setIsLoading(true);
    console.log({ signupData })
    try {
      if(signupData?.phone_confirm)
        await fetchUpdateSignupUser(signupData);
      else return alert('전화번호 인증이 필요합니다.')
    } catch{
    }
    finally {
      setIsLoading(false);
    }
  }

  // 데이터 변경
  const handleChange = (key, value) => {
    setSignupData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  // 개인정보 약관 동의
  const agreePrivacyPolicy = () => {
		let nowDateD = moment().toDate();
		let nowDate = moment().format('YYYYMMDD-HH:mm:ss');

    if(!signupData.agreedToPrivacyPolicy.agreed) // 동의의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToPrivacyPolicy: {
          agreed: true, // 동의 여부 - 약식 동의
          date: nowDateD, // 일자 - 
          agreed_date: nowDate, // 일자 - string
          hasReadAndAgreedTerms: true, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: nowDateD, // 잘 읽고 동의한건지에 대한 여부
          version: '1.0' // [TODO] 약관 불러오기
        },
      }));
    else // 동의 취소의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToPrivacyPolicy: {
          agreed: false, // 동의 여부 - 약식 동의
          date: null, // 일자 - 
          agreed_date: '', // 일자 - string
          hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
          version: '' // 약관
        },
      }));
  }

  
  // 서비스 이용약관 동의
  const agreeServicePolicy = () => {
		let nowDateD = moment().toDate();
		let nowDate = moment().format('YYYYMMDD-HH:mm:ss');

    if(!signupData.agreedToServicePolicy.agreed) // 동의의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToServicePolicy: {
          agreed: true, // 동의 여부 - 약식 동의
          date: nowDateD, // 일자 - 
          agreed_date: nowDate, // 일자 - string
          hasReadAndAgreedTerms: true, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: nowDateD, // 잘 읽고 동의한건지에 대한 여부
          version: '1.0' // [TODO] 약관 불러오기
        },
      }));
    else // 동의 취소의 경우
      setSignupData((prevData) => ({
        ...prevData,
        agreedToServicePolicy: {
          agreed: false, // 동의 여부 - 약식 동의
          date: null, // 일자 - 
          agreed_date: '', // 일자 - string
          hasReadAndAgreedTerms: false, // 잘 읽고 동의한건지에 대한 여부
          hasReadAndAgreedTermsDate: null, // 잘 읽고 동의한건지에 대한 여부
          version: '' // 약관
        },
      }));
  }



  return {
    submit,
    signupData,
    handleChange,
    isLoading,

    agreePrivacyPolicy,
    agreeServicePolicy
  };
};
