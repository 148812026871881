import styled from 'styled-components'
import { Button, CheckBox, GridBox, LabelInput } from '..'
import { getTheme } from '@/styles/theme'
import { useSignup } from '@/stores/signup'
import { Link } from 'react-router-dom'
import BirthdayPicker from '../Inputs/BirthdateInput'
import { useState } from 'react'
import { fetchEmailDuplicateCheck } from '@/api/user'
import { validateEmail } from '@/utils/validate'


const Container = styled.div`
  .gender-selector {
    width: 100%;
    padding: 12px 0;
    border: 2px solid #ececec;
    border-radius: 8px;
    transition: .2s;
  }

  .active {
    background-color: ${getTheme('primary100')};
    border: 2px solid  ${getTheme('primary100')};
    color: #fff;
  }
`


export default function Step1 () {
  const { incrementStep, signupData, handleChange, agreePrivacyPolicy } = useSignup();


  function handleStep () {
    if(signupData.name === '') return alert('이름을 입력해주세요.');
    else if(signupData.birth === '') return alert('생년월일 입력 필요');
    else if(signupData.email === '') return alert('이메일 입력 필요');
    else if(!validateEmail(signupData.email)) return alert('이메일 형식이 올바르지 않습니다.');
    else if(!signupData.agreedToPrivacyPolicy.agreed) return alert('약관 동의 필요');
    else incrementStep();
  }


  async function emailCheckSubmit() {
    if(signupData.email === '') return alert('이메일을 입력해주세요.');
    else {
      const result = await fetchEmailDuplicateCheck(signupData.email);
      if(!result) alert('사용 불가능한 이메일입니다.');
      else {
        alert('사용 가능한 이메일입니다.');
        handleChange('email_confirm', result);
      }
    }
  }


  return (
    <Container>
      <div className='black font-30 c-primary100'>회원가입</div>
      <div className='bold font-24 mt-4 mb-72'>스텔라가 처음이신가요?</div>

      <LabelInput 
        label={"이름"} 
        placeholder={"이름을 입력해주세요."}
        value={signupData.name}
        onChange={e => handleChange("name", e.target.value)}
      />
      <div className='mt-24'/>
      
      <BirthdayPicker
        label={"생년월일"} 
        placeholder={"20XX.XX.XX"}
        value={signupData.birth}
        onChange={value => { handleChange("birth", value)} }
      />
      <div className='mt-24'/>
      
      <div className='w-full flex'>
        <div className='flex-1'>
          <LabelInput 
            label={"E-mail"} 
            placeholder={"이메일을 입력해주세요."}
            value={signupData.email}
            onChange={e => handleChange("email", e.target.value)}
            readOnly={signupData.email_confirm}
          />
        </div>
        {!signupData?.email_confirm && <div className='pt-28 ml-8'>
          <Button fontSize={14} width={80} onClick={emailCheckSubmit}>중복확인</Button>
        </div>}
      </div>
      <div className='mt-24'/>


      <div className='font-18 mb-6 bold inputLabel'>성별</div>
      <div className='w-full px-8 mt-8'>
        <GridBox col={2} gap={24}>
          <div onClick={() => handleChange('gender', 0)} className={`flex-center gender-selector pointer ${signupData.gender === 0 ? 'active' : ''}`}>남</div>
          <div onClick={() => handleChange('gender', 1)} className={`flex-center gender-selector pointer ${signupData.gender === 1 ? 'active' : ''}`}>여</div>
        </GridBox>
      </div>
      <div className='mt-24'/>

      <div className='flex align-center'>
        <CheckBox selected={signupData.agreedToPrivacyPolicy.agreed} onClick={agreePrivacyPolicy}/>
        <a href='https://stella-71d54.web.app/' target='_blank' className='underline c-primary100 semibold'>개인정보이용약관</a>
        <div className='medium'>에 동의합니다.</div>
      </div>

      <div className='mt-48'/>

      <Button active={signupData.email_confirm} background={getTheme('primary100')} color={'#fff'} fontSize={20} style={{width: '100%'}} onClick={() => {
        if(signupData.email_confirm) handleStep()
        else alert('이메일 중복 확인이 필요합니다.')
      }}>다음</Button>

    </Container>
  )
}