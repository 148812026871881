import { getNotice } from '@/api/notice';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components'


const Container = styled.div`
  height: 100%;
  padding: 60px 0 0 0;
  display: flex;
  flex-direction: column;
`

const NoticeContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const NoticeTitle = styled.div`
padding: 20px;
  font-size: 24px;
  font-weight: bold;
`

const NoticeContent = styled.div`
  padding: 20px;
  font-size: 16px;
  line-height: 1.5;
`

export default function Page () {
  useHideLayout('header', true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [notice, setNotice] = useState();

  useEffect(() => {
    if (!id) return navigate(-1);

    (async () => {
      const noticeData = await getNotice(id);
      setNotice(noticeData);
    })();
  }, []);

  if(notice === null) return null;

  return (
    <Container>
      <TitleHeader title={"공지사항"}/>

      <NoticeContainer>
        <NoticeTitle className='notice-title'>{notice?.title}</NoticeTitle>
        <iframe srcDoc={notice?.content} style={{width: '100%', border: 'none', flex: 1}}/>
      </NoticeContainer>
      
    </Container>
  )
}
