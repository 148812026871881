import styled from 'styled-components'
import { Button, Modal } from '..'
import { getTheme } from '@/styles/theme'
import { useNavigate } from 'react-router-dom'



export default function NeedAuthAdultModal ({
  isOpen,
  onClose,
}) {
  const navigate = useNavigate();

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} height={242}>
        <div className='w-full flex-center pt-10'>
          <div className='adultCircle flex-center font-32 bold c-primary100 mb-16'>
            19
          </div>
        </div>

        <div className='pt-4 bold font-24 text-center ellipsis w-full mb-24'>
          성인인증이 필요합니다 
        </div>
      
        <div className='flex flex-center mt-20'>
          <div className='flex-1'>
            <Button background={getTheme('primary100')} height={42} style={{width: '100%'}} onClick={onClose}>
              취소
            </Button>
          </div>

          <div className='flex-1 ml-8'>
            <Button background={getTheme('primary100')} height={42} style={{width: '100%'}} onClick={() => navigate('/adultverification')}>
              성인인증 하기
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}