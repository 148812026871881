import { atom, useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { fetchStalierDetailData, fetchStalierEpisodeListWithAuthCheck } from '@/api/stalier';


export const stalierDataState = atom({
  key: 'stalierData',
  default: {},
});


export const stalierEpisodeListState = atom({
  key: 'stalierEpisodeList',
  default: [],
});


export const useStalierData = (uid) => {
  const [stalierData, setStalierData] = useRecoilState(stalierDataState);
  const [stalierEpisodeList, setStalierEpisodeList] = useRecoilState(stalierEpisodeListState);
  
  
  
  useEffect(() => {

    if(uid) {
      getStalierData();
      getStalierEpisodeListData();
      // 스텔리에 에피소드
    }
  }, [uid]);

  
  const getStalierData = async () => {
    const response = await fetchStalierDetailData(uid)
    setStalierData(response);
  }
    
  const getStalierEpisodeListData = async () => {
    const response = await fetchStalierEpisodeListWithAuthCheck(uid)
    setStalierEpisodeList(response);
    
    console.log(response)
  }
  
  
  return {
    stalierData,
    stalierEpisodeList
  }
}