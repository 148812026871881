import { sample_banner } from "@/assets";
import styled from "styled-components";


const NoticeCard = styled.div`
  flex: 0 0 auto;
  scroll-snap-align: center;
  border-radius: 10px;
  overflow: hidden; 
  
  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Title = styled.div`
  margin-top: 12px;
  font-size: 18px;
  font-weight: bold;

`
const Period = styled.div`
    font-size: 14px;
    color: #939393;
`

function EventItem({ noticeImg, title, period}) {
  return (
    <div>
        <NoticeCard >
            <img src={noticeImg} alt={title}/>
        </NoticeCard>
        <div className="ml-10">
            <Title>{title}</Title>
            <Period>{period}</Period>
        </div>
    </div>
  );
}

export default EventItem;