import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import styled from 'styled-components'


const Container = styled.div`
  /* padding: 60px 0 0 0; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 900;
`

export default function Page () {
  useHideLayout('header', true);

  return (
    <Container>
      <TitleHeader title="작성 댓글 목록"/>
      <Content>댓글은 아직 지원하지 않습니다.</Content>
    </Container>
  )
}
