import { useHideLayout } from '@/hooks'
import { Navigate } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`

`


export default function SignupCompletePage () {
  return (
    <Navigate to='/login' replace/>
  )
}