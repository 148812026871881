import { social_google } from '@/assets'
import styled from 'styled-components'


const Container = styled.div`
  width: 250px;
  max-width: 80vw;

  border: 1px solid #000;
  border-radius: 80px;
  height: 50px;
  
  .social_icon {
    width: 24px;
    height: 24px;
    left: 16px;
  }
`


export default function GoogleLoginButtonDump () {
  return (
    // <Container className='flex-center relative pointer' onClick={() => window.location.href = '/googlelogin'}>
    //   <img className='social_icon absolute' src={social_google}/>
    //   <div className='pl-24 medium font-18'>구글 계정으로 로그인</div>
    // </Container>
    
    <CircleContainer onClick={() => window.location.href = '/googlelogin'}>
      <img className='social_icon pointer' src={social_google}/>

    </CircleContainer>
  )
}


const CircleContainer = styled.div`
  width: 36px;
  height: 36px;

  .social_icon {
    width: 36px;
    border-radius: 50%;
  }
`