import { axiosAuth } from "@/lib/axios";

// 소장권 구매
export const fetchPermanentNovel = async (novel_id, episode_id, previousQuantity, episode_price_amount) => {
  try {
    const { data } = await axiosAuth.post('/permanent', {
      target_novel: novel_id,
      target_episode: episode_id,
      amount: episode_price_amount, // 소장권 = 스타 1개
      previousQuantity
    });

    if(data?.response?.statusCode === 409) {
      alert('이미 처리된 결제입니다.');
      throw new Error();
    }
    else if(data?.response?.statusCode === 404) {
      alert('잘못된 재화 정보입니다.');
      throw new Error();
    }

    else {
      alert('결제가 처리되었습니다.');
      return data; 
    }
  } catch (e) {
    alert('소장권 구매에 실패했습니다.')
    throw new Error();
  }
}
