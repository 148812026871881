import { fetcExposurehNovelListPromotion0, fetchNovelListPromotion0, fetchNovelListPromotion1 } from "@/api/promotion";
import { fetchNovelLastViewList } from "@/api/view";
import { useEffect } from "react";
import { atom, useRecoilState } from 'recoil';

// 스확시간 리스트
const novelPromotion1ListState = atom({
  key: 'NovelPromotion1List',
  default: [],
});


// 타임딜 리스트
const novelPromotion0ListState = atom({
  key: 'NovelPromotion0List',
  default: [],
});

// 당신을 추천 리스트
const novelExposurePromotion0ListState = atom({
  key: 'NovelExposurePromotion0List',
  default: [],
});


// 최근 본 리스트
const novelLastViewListState = atom({
  key: 'NovelLastViewList',
  default: [],
});


export const useHomeNovelList = () => {
  const [novelPromotion1List, setNovelPromotion1List] = useRecoilState(novelPromotion1ListState);
  const [novelPromotion0List, setNovelPromotion0List] = useRecoilState(novelPromotion0ListState);
  const [novelExposurePromotion0List, setNovelExposurePromotion0List] = useRecoilState(novelExposurePromotion0ListState);
  const [novelLastViewList, setNovelLastViewList] = useRecoilState(novelLastViewListState);


  useEffect(() => {
    // 타임딜, 스확시간 리스트 불러오기
    getNovelListPromotion1();
    getNovelListPromotion0();

    // 당신만을 위한 추천
    getExposurehNovelListPromotion0();

    // 최근 본 소설 조회
    getNovelLastViewList();
  }, [])


  // 스확시간 리스트
  const getNovelListPromotion1 = async () => {
    const result = await fetchNovelListPromotion1();
    setNovelPromotion1List(result);
  }

  // 타임딜 리스트
  const getNovelListPromotion0 = async () => {
    const result = await fetchNovelListPromotion0();
    setNovelPromotion0List(result);
  }

  
  // 당신만을 위한 추천 리스트
  const getExposurehNovelListPromotion0 = async () => {
    const result = await fetcExposurehNovelListPromotion0();
    setNovelExposurePromotion0List(result);
  }


  // 최근 본 소설 조회
  const getNovelLastViewList = async () => {
    const resultView = await fetchNovelLastViewList();
    setNovelLastViewList(resultView);
  }


  return {
    novelLastViewList,
    novelPromotion0List,
    novelPromotion1List,
    novelExposurePromotion0List
  }
}