import { useEffect, useState } from 'react';
import { fetchNoticeList } from '@/api/notice';

const COUNT = 10;

export const useNoticeList = () => {
  const [noticeList, setNoticeList] = useState({
    list: [],
    totalPage: 0,
    totalCount: 0,
  });

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  
  const handlePage = (pageValue) => {
    setPage(pageValue);
  }
  
  useEffect(() => {
    const getNoticeListData = async () => {
      try {
        const { list, totalPage, totalCount } = await fetchNoticeList(page, COUNT);
        setNoticeList({
          list: [...noticeList.list, ...list],
          totalPage,
          totalCount,
        });
      } catch (error) {
        console.log({error})
        console.error('Failed to fetch notice list:', error);
      } finally {
        setIsLoading(false);
      }
    }

    getNoticeListData();

  }, [page])

  return {
    isLoading,
    noticeList,
    page,
    handlePage,
  }
}