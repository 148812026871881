import { Button, Input } from '@/components'
import { useHideLayout } from '@/hooks'
import TitleHeader from '@/layouts/Header/TitleHeader'
import { useState } from 'react'
import styled from 'styled-components'


const Container = styled.div`
  padding: 80px 0;

`


export default function Page () {
  useHideLayout('header', true)

  const [coupon, setCoupon] = useState('');

  return (
    <Container>
      <TitleHeader title="쿠폰 등록"/>
      
      <div className='px-16'>
        <Input placeholder="쿠폰 번호" value={coupon} onChange={e => setCoupon(e.target.value)}/>

        <div className='w-full flex-center mt-24'>
          <Button active={!!coupon} onClick={() => {
            window.location.href = `/coupon/use/${coupon}`
          }}>등록하기</Button>
        </div>
      </div>
    </Container>
  )
}