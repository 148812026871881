import { fetchStalierOwnList } from '@/api/stalier';
import { Button, GridBox } from '@/components';
import NovelItem from '@/components/NovelItem';
import StalierItem from '@/components/StalierItem';
import { useHideLayout } from '@/hooks';
import TitleHeader from '@/layouts/Header/TitleHeader';
import { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components'
import { useInfiniteScroll } from '@/hooks';


const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  .no-data {
    width: 100%;
    height: calc(80vh - 60px);
  }
`


export default function Page () {
  const [page, setPage] = useState(1);

  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);

  const scrollEnd = useRef();
  

  useEffect(() => {
    getData();
  }, [page])
  
  async function getData () {
    const { data, totalPage, totalCount } = await fetchStalierOwnList(page);


    console.log(data)
    setList(li => [...li, ...data]);
    setTotal(totalCount);
    setTotalPage(totalPage);
  }

  // [TODO] infinify scroll 추가
  function handlePage (num) {
    setPage(num);
  }

  useHideLayout('header', true);

  useInfiniteScroll(() => handlePage(page + 1), scrollEnd);

  return (
    <Container>
      <TitleHeader title="나의 스텔리에"/>

      <div className='px-16 w-full pt-16'>
        <GridBox col={3} gap={20}>
          {list?.map((novel, key) => 
            <StalierItem isOwn={true} {...novel} key={key}/>
          )}
        </GridBox>

        {list.length < total && <div ref={scrollEnd} />}

      </div>


      {list?.length === 0 && 
        <div className='no-data text-center flex-center flex-col'>
          <div className='bold font-20'>업로드한 작품이 아직 없습니다.</div>
          <div className='font-18 mt-20 mb-40'>스텔리에에 작품을 올려보세요!</div>

          <Button fontSize={20} to='/stalier/own/add'>작품 연재하기</Button>
        </div>
      }


      {list?.length !== 0 && <div className='w-full px-16 mt-40'>
        <Button width={"100%"} fontSize={20} to='/stalier/own/add'>작품 연재하기</Button>
      </div>}
    </Container>
  )
}