import axios, { axiosAuth } from '@/lib/axios';
import { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom'
import styled from 'styled-components'


const Container = styled.div`
  padding-top: 100px;
`


export default function AosLoginPage () {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getUser();
  }, [])

  async function getUser() {    
    const isAuthenticated = localStorage.getItem('user_access_token');
    const deviceToken = searchParams.get('deviceToken');
    localStorage.setItem('device', "ANDROID");

    // 로그인 되어 있다면
    if(isAuthenticated) {
      try {
        await axiosAuth.post('/user/deviceToken', { deviceToken });
        return window.location.href = '/'
      } catch {
        window.location.href = '/'
      }
    }
    else {
      localStorage.setItem('deviceToken', deviceToken);
      window.location.href = '/'
    }

  }

  return (
    <Container>
    </Container>
  )
}