import axios from "@/lib/axios"

export const fetchNoticeList = async (page, count) => {
  const data = await axios.get(`/notice/list/${page}/${count}`);

  return data.data;
}

export const getNotice = async (id) => {
  const data = await axios.get(`/notice/detail/${id}`);

  return data.data;
}