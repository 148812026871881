import { fetchCreateLike, fetchDeleteLike } from '@/api/like';
import { ic_audio_off, ic_audio_on, ic_comment, ic_like, ic_liked, ic_navigate_back, ic_notification, ic_notification_color, ic_odi, ic_share } from '@/assets';
import { GridBox } from '@/components';
import AD from '@/components/AD';
import { AccordionMenu } from '@/components/AccordionMenu';
import NeedAuthModal from '@/components/Auth/NeedAuthModal';
import { EpisodeList } from '@/components/Episode/EpisodeList';
import EpisodePaymentModal from '@/components/Episode/EpisodePaymentModal';
import InsufficientBalanceModal from '@/components/Episode/InsufficientBalanceModal';
import NovelRentBenefitModal from '@/components/Episode/NovelRentBenefitModal';
import TimeFreeRentModal from '@/components/Episode/TimeFreeRentModal';
import GenreSelector from '@/components/GenreSelector';
import { useNovelData } from '@/stores/novelData';
import { formatDate, formatImageUri } from '@/utils/format';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import moment from 'moment';
import { fetchGetPromotionRentTicket } from '@/api/rent';
import { fetchCreateAlarm, fetchDeleteAlarm, fetchHasCheckAlarm } from '@/api/alarm';
import { useUser } from '@/stores';
import { useHideLayout } from '@/hooks';
import NeedAuthAdultModal from '@/components/Auth/NeedAuthAdultModal';

const Container = styled.div`
  padding-top: 80px;

  .audioModeBtn {
    width: 28px;
    position: absolute;
    right: 0;
  }

  .book_cover {
    border-radius: 10px;
    width: 100px;
    height: 150px;
  }

  .white-line {
    width: 100%;
    height: 1px;
    background-color: #e7e6e7;
  }

  .white-box {
    width: 100%;
    height: 8px;
    background-color: #e7e6e7;
  }

  .episode-button {
    margin-top: 12px;
    border: 1.5px solid #5e5f5e;
    height: 52px;
    border-radius: 4px;
  }
  
  .odi_medium {
    width: 30px;
  }
  .odi_small {
    width: 18px;
  }

  .c-5d5d5d {
    color: #5d5d5d;
  }

  .price_box {
    border: 2px solid #000;
    width: 108px;
    height: 40px;
    border-radius: 8px;
  }

  .price_box_small {
    border: 2px solid #000;
    width: 76px;
    height: 32px;
    border-radius: 8px;
  }

  .grey_bottom_1 {
    border-bottom: 1px solid #e1e1e1;
  }
`


const HeaderContainer = styled.div`
  position: fixed;
  left: 0;
  width: 100vw;
  top: 0;
  height: 60px;
  z-index: 20;
  background-color: #fff;
  border-bottom: 2px solid #e7e6e7;

  .back_image {
    width: 24px;
    left: 24px;
  }

  .wititle {
    width: calc(100vw - 76px);
    text-align: center;
  }
`



export default function Page () {
  const { uid } = useParams()
  const { 
    novelData, 
    episodeList, 
    rentTicketList, 
    benefitsCertificate,
    changeBenefitCertificate,
    hasLiked, 
    timedeal, // 타임딜
    timeFreeRent, // 스확시간
    setHasLiked,
    hasAlarm,
    likeCount,
    setLikeCount, //
    setHasAlarm,
    pushRentTicketList, // 대여권 리스트 추가
    popRentTicektList,
    refreshTimeRentFreeCertificate,
    timeFreeRentCertificate,
  } = useNovelData(uid);

  const { user } = useUser('novel-detail')

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  const navigate = useNavigate();
  
  const [selectedEpisode, setSelectedEpisode] = useState(null);

  // 중간 저장
  const [selected, setSelected] = useState(null);

  // 신규 유저 혜택 모달
  const [isBenefitModalOpen, setIsBenefitModalOpen] = useState(false);
  
  // 스확시간 모달
  const [isTimeFreeRentModalOpen, setIsTimeFreeRentModalOpen] = useState(false);

  // 소지금 부족 모달
  const [isInsufficientBalanceModalOpen, setIsInsufficientBalanceModalOpen] = useState(false);

  // 오디오북 모드
  const [isAudioMode, setIsAudioMode] = useState(false);



  function navigatgeItem(uuid, state) {
    if(isAudioMode) navigate(`/audiobook/${uuid}`, state);
    else navigate(`/episode/${uuid}`, state);
  }


  function openFirstEpisode() {
    if(!user) {
      return setIsLoginModalOpen(true);
    }
    else if(episodeList.length > 0) {
      const zero_item = episodeList?.find((item) => item.episode_index === 0);
      if(zero_item) openEpisode(zero_item)

      const first_item = episodeList?.find((item) => item.episode_index === 1);
      if(first_item) openEpisode(first_item)
    }
  }


  function openEpisode(episode_data) {
    const { salesStatus, hasTimedeal } = episode_data;

    // 유저가 없다면
    if(!user) return setIsLoginModalOpen(true);

    if(salesStatus === "무료") {
      // 무료의 경우 넘기기
      if(hasTimedeal) // 타임딜 체크
        navigatgeItem(episode_data.uuid, { state: { read_ticket_type: "TIMEDEAL" }});
      else // 무료 처리
        navigatgeItem(episode_data.uuid, { state: { read_ticket_type: "FREE" }});
      return;
    }
    // 무료가 아닐 경우
    else {
      // 소장중일 경우, 바로 조회로 넘김.
      if(salesStatus === "소장중") {
        navigatgeItem(episode_data.uuid, { state: { read_ticket_type: "PERMANENT", read_permanent: episode_data.permanent_id }});
        return;
      }
      // 대여중일 경우
      if(salesStatus === "대여중") {
        const now = moment(); // 현재 시간
        const expireAt = moment(episode_data.rentExpire); // 만료 시간
          
        // 대여권 만료인지 계산
        // 만료일 경우
        if(expireAt.isBefore(now)) {} // 아래로 넘겨 결제 처리로
        // 만료가 아닐 경우
        else {
          navigatgeItem(episode_data.uuid, { state: { read_ticket_type: "RENT", read_rent: episode_data.rent_id }});
          return;
        }
      }

      // 무료가 아니며, 소장중이 아니며, 대여중이 아니거나 대여가 만료되었을 경우

      // 신규 독자 rent 베네핏 처리
      if(benefitsCertificate) {
        setIsBenefitModalOpen(true);
        setSelected(episode_data);
        return;
      }

      submitPayment(episode_data);
      return;
    }
  }

  async function submitPayment(episode_data) {
    // 스확시간가 존재하며
    if(timeFreeRent) {
      //  스확시간가 사용중이 아닐때
      if(!timeFreeRentCertificate) {
        setIsTimeFreeRentModalOpen(true);
        setSelected(episode_data);
      }
      // 스확시간 적용중일 때
      else {
        // 대여권을 발급할 수 있을 경우
        if(timeFreeRentCertificate?.timeRentFreeActive) {
          // 대여권 발급
          const ticket = await fetchGetPromotionRentTicket(
            timeFreeRent?.target_novel,
            timeFreeRentCertificate?.target_coin_promotion,
            timeFreeRent._id,
            timeFreeRent?.title,
          );

          pushRentTicketList(ticket);
          refreshTimeRentFreeCertificate();
          setSelectedEpisode(episode_data);
        }
        // 대여권을 발급할 수 없는 경우
        else setSelectedEpisode(episode_data);
      }
    }
  
    // 결제 팝업 ( 대여인지 아닌지 )
    else setSelectedEpisode(episode_data);
  }

  useHideLayout('header', true)


  async function handleLike() {
    if(!user) return setIsLoginModalOpen(true)

    if(hasLiked) {
      // 좋아요 상태면
      await fetchDeleteLike(novelData._id, uid);
      setHasLiked(false);
      setLikeCount(num => num - 1);
    } else {
      // 좋아요 상태가 아니면
      await fetchCreateLike(novelData._id, uid);
      setHasLiked(true);
      setLikeCount(num => num + 1);
    }
  }

  async function handleAlarm() {
    if(!user) return setIsLoginModalOpen(true)

    if(hasAlarm) {
      // 좋아요 상태면
      await fetchDeleteAlarm(novelData._id, uid);
      setHasAlarm(false);
    } else {
      // 좋아요 상태가 아니면
      await fetchCreateAlarm(novelData._id, uid);
      setHasAlarm(true)
    }
  }

  async function handleShare() {
    const shareData = {
      title: novelData.title, // 페이지의 제목
      text: '이 작품을 확인해 보세요!',
      url: `https://stell-a.com/novel/detail/${uid}`, // 현재 페이지의 URL
    }

    if (navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
      }
    } else {
      try {
        Android.doShare(shareData.title, shareData.text, shareData.url);
      } catch {
        alert('이 브라우저는 공유 기능을 지원하지 않습니다.');
      }
    }
  }


  return (
    <Container className='block-draggable'>
      <HeaderContainer className='flex-center px-24'>
        <img className='absolute back_image pointer' src={ic_navigate_back} onClick={() => {
          const prev_path = localStorage.getItem('previousPath');

          if(prev_path) navigate(prev_path, { replace: true });
          else navigate(-1, { replace: true })
        }}/>
        <div className='flex-1'/>
        <div className='flex flex-center mr-12' onClick={handleLike}>
          <img className='pointer' style={{height: '18px'}} src={hasLiked ? ic_liked : ic_like}/>

          {hasLiked ? 
            <div className='font-12 ml-4 c-primary'>{likeCount}</div> :  
            <div className='font-12 ml-4'>{likeCount}</div>
          }
        </div>

        <div className='flex-col flex-center' onClick={handleAlarm}>
          <img className='pointer' style={{height: '20px'}} src={hasAlarm ? ic_notification_color : ic_notification}/>
        </div>

        <div className='flex-col flex-center ml-12' onClick={handleShare}>
          <img className='pointer' style={{height: '18px'}} src={ic_share}/>
        </div>
      </HeaderContainer> 


      <NeedAuthModal 
        isOpen={isLoginModalOpen} 
        onClose={() => setIsLoginModalOpen(false)}
      />


      <EpisodePaymentModal
        isOpen={!!selectedEpisode}
        onClose={() => setSelectedEpisode(null)}
        rentTicketList={rentTicketList}
        episode_data={selectedEpisode}
        user={user}
        popRentTicektList={popRentTicektList}
        navigatgeItem={navigatgeItem}
        // 재화 부족
        openEnoughModal={() => setIsInsufficientBalanceModalOpen(true)}
      />


      <NovelRentBenefitModal
        isOpen={isBenefitModalOpen}
        onClose={() => {
          setIsBenefitModalOpen(false);
          if(selected) submitPayment(selected);
        }}
        changeBenefitCertificate={changeBenefitCertificate}
        novelData={novelData}
      />

      {/* 스확시간 모달 */}
      <TimeFreeRentModal
        isOpen={isTimeFreeRentModalOpen}
        onClose={() => {
          setSelectedEpisode(selected);
          setIsTimeFreeRentModalOpen(false);
        }}
        novelData={novelData}
        timeFreeRent={timeFreeRent}
        pushRentTicketList={pushRentTicketList}
      />


      {/* 잔액부족 모달 */}
      <InsufficientBalanceModal
        isOpen={isInsufficientBalanceModalOpen}
        onClose={() => setIsInsufficientBalanceModalOpen(false)}
      />

      <div className='px-20 mb-20'>
        <div className='flex w-full'>
          {/* Book Cover Image */}
          {novelData?.book_cover && <img className='book_cover' src={formatImageUri(novelData?.book_cover)} alt='표지가 없습니다.' />}

          {/* 소설 정보 */}
          <div className='ml-12 flex-1 relative'>
            {novelData?.isAudioBook && <img className='audioModeBtn pointer' src={isAudioMode ? ic_audio_on : ic_audio_off} onClick={() => setIsAudioMode(!isAudioMode)}/>}

            <div className=''>
              <div className='font-16 bold mr-10'>{novelData?.isBook ? "[단행본] " : ""}{novelData?.title}</div>
            </div>
            <div className='font-14 medium mt-8'>{novelData?.writer_list?.map((item, idx) => (
              <div className='' key={`asdasd-${item?._id}`} onClick={() => {
                navigate(`/search?text=${item.name}&type=WRITER`)
                
              }}>{idx !== 0 && ', '}{item.name}</div>
            ))}</div>

            <div className='font-12 mt-8' onClick={() => {
              navigate(`/search?text=${novelData?.cpLabel?.name}&type=LABEL`)
            }}>{novelData?.cpLabel?.name}</div>

            {!novelData?.release_note && <div className='mb-44'/>}
            {novelData?.release_note && <div className='mt-14 mb-20 font-12 bold'>{novelData?.release_note}</div>}
            
            <div className='mt-18 font-14' onClick={() => {
              navigate(`/rank?genre=${novelData?.genre?.name}`);
            }}>{novelData?.genre?.name}</div>

            <div className='mt-2 font-12'>#{novelData?.keywords?.map(item => item.name).join(', #')}</div>
          </div>
        </div>
      </div>

      <div className='white-line mb-14'/>
    

      <div className='px-20 mb-12'>
        <div className='episode-button flex-center font-20 bold pointer' onClick={openFirstEpisode}>첫화 보기</div>
      </div>

      <div className='white-line'/>

      <AccordionMenu content={novelData?.summary}/>
      <div className='flex-center px-20 py-10'>
        <div className='semibold font-14'>프로모션</div>
        <div className='ml-8 medium font-13 c-5d5d5d'>
          {timeFreeRent ? "스확시간" : ""}
          {(timeFreeRent && timedeal) ? " · " : ""}
          {timedeal ? "타임딜" : ""}

          {(!timeFreeRent && !timedeal) ? "적용 중인 프로모션이 없습니다." : ""}
        </div>
        <div className='flex-1'/>
      </div>

      <div className='white-line'/>

      <div className='flex-center px-20 py-10'>
        <div className='semibold font-14'>사용 가능 대여권</div>
        <div className='ml-8 medium font-13 c-5d5d5d'>{rentTicketList.length}개</div> 
        <div className='flex-1'/>
      </div>

      <div className='white-line'/>

      {timedeal && <div className='flex-center px-20 py-10'>
        <div className='semibold font-14'>타임딜 무료 회차</div>
        <div className='ml-8 medium font-13 c-5d5d5d'>{timedeal.timedeal_episode_count}개</div>
        <div className='mx-6 medium font-13 c-5d5d5d'>~</div>
        <div className='medium font-13 c-5d5d5d'> {formatDate(timedeal.promotion_end)}까지</div> 
        <div className='flex-1'/>
      </div>}


      <div className='white-box'/>
      
      <div className='flex-center px-20 py-10 grey_bottom_1'>
        <img src={ic_odi} className='odi_medium'/>
        <div className='semibold font-16 medium'>남은 스타</div>
        <div className='ml-8 font-20 semibold'>{user?.currentBalance ?? 0}</div>
        <div className='flex-1'/>
        <div className='price_box pointer flex-center semibold' onClick={() => window.location.href = '/charge'}>충전하기</div>
      </div>

      {/* <div className='flex-center pl-26 pr-20 py-10'>
        <img src={ic_odi} className='odi_small'/>
        <div className='ml-6 semibold font-16 medium'> 스타 자동충전</div>
        <div className='flex-1'/>
        <div className='price_box_small font-12 pointer flex-center semibold' onClick={() => navigate('/charge')}>신청하기</div>
      </div> */}

      <div className='white-box'/>

      <AD/>
      
      <div className='white-box'/>
      
      <EpisodeList
        isAudioMode={isAudioMode}
        episodeList={episodeList} 
        novelData={novelData}
        openEpisode={openEpisode}
      />
    </Container>
  )
}