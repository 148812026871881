import { axiosAuth } from "@/lib/axios";

export const fetchOdiChargeWithTOSSPayment = async ({ 
  paymentType, 
  orderId, 
  paymentKey, 
  amount, 
  previousQuantity,
  product_id
}) => {
  try {
    const { data } = await axiosAuth.post('/coin/charge/toss', {
      type: 'CHARGE',
      amount,
      previousQuantity, // 적용 전 수량 - 검증용
      orderId, // 주문 고유 ID
      paymentKey, // 주문 고유 Key
      paymentType,
      product_id
    });

    // [TODO] 이전 히스토리로 이동 하게 변경
    // [TODO] 로그인 데이터 서버에서 다시 가져오기
    // let user_data = JSON.parse(localStorage.getItem('user_data'))
    // user_data.currentBalance = Number(user_data.currentBalance) + Number(amount / 100)
    // localStorage.setItem('user_data', JSON.stringify(user_data))
    
    // return data;

    // 충전 시
    return window.location.href = '/'
  } catch (e) {
    console.log(e);
    // 충전 실패 시
    return window.location.href = '/charge/fail'
  }
}




export const fetchOdiChargeWithIOSPayment = async ({ 
  orderId, 
  receipt,
  previousQuantity,
  product_id
}) => {
  try {
    const { data } = await axiosAuth.post('/coin/charge/ios', {
      type: 'CHARGE',
      previousQuantity, // 적용 전 수량 - 검증용
      orderId, // 주문 고유 ID
      product_id_IOS: product_id,
      receipt,
      isProduction: true // [TODO] SANDBOX
    });

    // [TODO] 이전 히스토리로 이동 하게 변경
    // [TODO] 로그인 데이터 서버에서 다시 가져오기
    // let user_data = JSON.parse(localStorage.getItem('user_data'))
    // user_data.currentBalance = Number(user_data.currentBalance) + Number(amount / 100)
    // localStorage.setItem('user_data', JSON.stringify(user_data))
    
    // return data;

    // 충전 시
    return window.location.href = '/'
  } catch (e) {
    console.log(e);
    // 충전 실패 시
    return window.location.href = '/charge/fail'
  }
}


export const fetchStarChargeWithCoupon = async ({ 
  coupon_number,
  previousQuantity
}) => {
  try {
    const { data } = await axiosAuth.post('/coin/charge/coupon', {
      type: 'CHARGE',
      previousQuantity, // 적용 전 수량 - 검증용
      coupon_number
    });

    if(data?.status === 409) {
      alert('이미 사용한 쿠폰입니다.')
    } 
    else {
      alert('성공적으로 쿠폰을 사용하였습니다.')
    }

    // 충전 시
    return window.location.href = '/'
  } catch (e) {
    // 충전 실패 시
    alert('사용할 수 없거나 유효하지 않은 쿠폰입니다.')
    return window.location.href = '/'
  }
}


// 스타 충전내역
export const fetchOdiBuyHistory = async () => {
  const { data } = await axiosAuth.get('/coin/history/buy');
  return data;
}

// 스타 사용내역
export const fetchOdiUseHistory = async () => {
  const { data } = await axiosAuth.get('/coin/history/use');
  return data;
}